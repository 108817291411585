import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const KiloModal = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div
          className="modal fade"
          id="kiloModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{t("more info")}</h6>
                <FontAwesomeIcon
                  onclick="removeblur()"
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4">
                <div className="w-100 d-flex flex-column mb-2">
                  <h5 className="theme_color fw-bold">
                    {t("What's included regarding price per kilo ?")}
                  </h5>
                  <div className="heading-line_color" />
                </div>
                <div className="pb-2">
                  <p className="theme_color">
                    {t(
                      "Everyday, casual & home items (jeans, t-shirts, sweaters, underwear, sport wear, bed sheets, pillow cover, towels, etc)."
                    )}
                    <br />
                    {t(
                      "If some delicate items (shirts, costume pants, etc) would be found in the kilo bag, we will take care of them but will have to charge them separetaly."
                    )}
                  </p>
                </div>
                <div className="w-100 d-flex flex-column mb-2">
                  <h5 className="theme_color fw-bold">
                    {t("How to estimate weight ?")}
                  </h5>
                  <div className="heading-line_color" />
                </div>
                <div className="row d-flex justify-content-start">
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img
                      src="/assets/imgs/bags.png"
                      width={100}
                      height={80}
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 d-flex justify-content-start">
                    <div className="w-100 d-flex flex-column align-items-center">
                      <div className="w-100">
                        <p className="btn payment_btn theme_color addmethod w-100 p-0 d-flex justify-content-center align-items-center">
                          1/2 bag = +-5kg
                        </p>
                        <p className="btn payment_btn theme_color fw-bold addmethod w-100 p-0 mb-0 d-flex justify-content-center align-items-center">
                          Full bag = +-10kg
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <p className="font_size">
                      *
                      {t(
                        "Trouble estimating weight ? No worry, our expert will do it for you & charge you the right amount."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KiloModal;
