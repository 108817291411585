import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Config from "../../../Config";
import axios from "axios";


const ReportIssue = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="modal fade"
        id="report_issue"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"

        tabIndex={-1}
      >
        <div className="modal-dialog flex-column modal-dialog-centered modal-lg vh-90 ">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center" >
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2" >
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <div className="w-100" id="stripe_form" style={{ borderColor: '#3D4586', borderWidth: 10 }}>
                <form className="Form text-center" style={{ border: '8px solid #3D4586' }}>
                  <div className="w-100 d-flex flex-column mb-3">
                    <h5 className="theme_color fw-bold align-self-center">
                      Report an issue
                    </h5>
                  </div>
            <div>
                we are very sorry to hear you encountered an issue while collecting the order.
            </div>
            <div>
                please contact support so we can assist you.
            </div>


                  <div className="d-flex flex-row justify-content-between mt-4">

                  <a href="mailto: hello@wosh.be" class="btn fill_btn lh-sm ms-auto me-auto d-flex justify-content-center align-items-center py-2 px-5" 
                    // data-bs-dismiss="modal"
                    // aria-hidden="true" 
                    >Contact Support</a>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  paymentId: state?.auth?.paymentId,
  authUser: state?.auth?.user,
  cartItems: state?.cart?.cartItems,
});
export default connect(mapStateToProps)(ReportIssue);
