import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import WalletStepIII from "../Step3";
import "../../../Header/user.css";
import Axios from "axios";
import Config from "../../../../Config";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const WalletStepII = (props) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(0);
  localStorage.setItem("redirect", window.location.pathname);

  return (
    <div>
      <div
        className="modal fade"
        id="payment_modalII"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4 px-5" style={{ border: '8px solid #3D4586' }}>
              <div className="step2">
                  <h5 className="theme_color fw-bold align-self-center text-center fw_semibold">
                    {t("Top up your WOSH Wallet")}
                  </h5>
                <p className="fw-bold m-0 theme_color">
                  {/* {props?.amount ? `${props?.amount} €` : "0 €"}{" "} */}
                </p>
                <p className="m-0 mb-3 small">{/*Avalale money*/}</p>
                <p className="small">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                </p>
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={() => setSelect(20)}
                      type="submit"
                      className="w-100 bg-transparent modal_btn_bordered p-2 rounded-pill theme_color mt-4 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center border-2"
                    >
                      20€
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSelect(50)}
                      type="submit"
                      className="w-100 bg-transparent modal_btn_bordered p-2 rounded-pill theme_color mt-4 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center border-2"
                    >
                      50€
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSelect(100)}
                      type="submit"
                      className="w-100 bg-transparent modal_btn_bordered p-2 rounded-pill theme_color mt-4 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center border-2"
                    >
                      100€
                    </button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-8 d-flex justify-content-start align-items-center">
                    <p className="m-0 small theme_color">{t("Choose another amount")}</p>
                  </div>
                  <div className="col-md-4 mt-2 mb-2">
                    <input
                      //   defaultValue={select}
                      value={select}
                      type="amount"
                      onChange={(event) => setSelect(event.target.value)}
                      name="amount"
                      className="w-100 rounded-pill px-3 py-1 minHeight44px theme_color theme_border theme_input text-center mb-auto border-2"
                      placeholder="_________"
                    />
                  </div>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  data-bs-target="#payment_modalIII"
                  className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                >
                  {t("Proceed")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WalletStepIII amount={select} id={props?.id} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state?.auth?.user,
  };
};

export default connect(mapStateToProps)(WalletStepII);
