import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import "../Header/user.css";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Config from "./../../Config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastsContainer, ToastsStore } from "react-toasts";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import { setAddress, set_OfficeAddress } from "../../../store/Actions/authActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ReactCodeInput from "react-code-input";
import ConfirmConnectModal from "./ConfirmConnectModal";
import SecureConnectModal from "./SecureConnectModal";

const ConnectPoint = (props) => {
  const [prev, setPrev] = React.useState(window.location.pathname);
  const [uid, setUid] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [point, setPoint] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(null);
  const [zero, setZero] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [buildings, setBuildings] = React.useState([]);
  const [searching, setSearching] = React.useState(false);
  const [err, setErr] = React.useState("");
  const [error, setError] = React.useState(false);
  const [building_id, setBuilding_id] = React.useState(null);
  const [company_id, setCompany_id] = React.useState(null);
  const [point_id, setPoint_id] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [code_secure, setCode_secure] = React.useState("");
  const [secure, setSecure] = React.useState([]);
  const [responsive, setResponsive] = React.useState({
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },)

  const [reload,setReload] = useState(false)
  const ref = useRef()

  useEffect(()=>{
    localStorage.setItem("path", JSON.stringify(prev));
    const uid = props?.authUser?.user_uid;
    const client = props?.authUser?.user_client;
    const accessToken = props?.authUser?.user_access_token;
    setUid(uid);
    setClient(client);
    setAccessToken(accessToken);
  },[])

  const toast =()=>{
    if (err === "") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (err === "") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  }

const handleHa = async () => {
setLoading(true);
setReload(!reload)
const company_id = point.filter(
  (item) => item.id === index
);
const point_id = point.filter(
  (item) => item.id === index
);

var data = null;

if (company_id[0]?.secure) {
  data = {
    collection_point: {
      collection_point_id: point_id[0]?.id,
      code: code_secure,
    },
    company_id: company_id[0]?.companies[0]?.id,
  }
} else {
  data = {
    collection_point: {
      collection_point_id: point_id[0]?.id,
    },
    company_id: company_id[0]?.companies[0]?.id,
  }
}

await Axios({
  url: `${Config.apiURL}/customer/collection_points`,
  method: "POST",
  data,
  headers: {
    uid: `${uid}`,
    client: `${client}`,
    "access-token": `${accessToken}`,
    "content-type": "application/json",
  },
})
  .then((response) => {
    setLoading(false);
    setCode_secure("")
    setShowModal(false)
    setErr("");
    setError(false);
    ToastsStore.success("Collection point has been added");
    props?.dispatch(set_OfficeAddress());
    props?.dispatch(setAddress())
    props.history.push("/thank-you", { myLove: props.location.state?.myLove });
  })
  .catch((error) => {
    setShowModal(false)
    setCode_secure("")
    setReload(!reload)
    setLoading(false);
    setCode_secure("")
    setErr("Your code is incorrect! Please try again.");
    setError(true);
    ToastsStore.error("Error adding collection point");
  });
};

const collectionPoints = () => {
  return point.length <= 0
  ? null
  : point.map((item, index) => {
    return (
      <>
        {toast()}
        <div
          style={{ cursor: "pointer" }}
          id="haha"
          onClick={() => {
            setIndex(item?.id);
            setError(true);
            setShowModal(true);
          }}
          data-bs-toggle="modal"
          data-bs-target={item.secure ? "#secureConnect" : "#confirmConnect"}
          className={`item ${point.length < 2
            ? "w-100 mx-auto "
            : point.length === 2
              ? "w-100 mx-auto"
              : ""
            }`}
          key={index}
        >
          <div className="search_option p-3">
            {item?.companies.map((name) => {
              return <h3 className="text-left promotion_heading theme_color m-0">{name?.company_name}</h3>;
            })}

            <br />
            <p className="text-left theme_color m-0" >{item?.address}
              {/* , {item?.number} */}
            </p>
            {/* <p className="text-left theme_color m-0" >{item?.city}, {item?.zip}</p> */}
          </div>
        </div>
      </>
    );
  });
}

const handleChange = (event) => {
  setSearch(event.target.value)
  // event.target.value === ""
  // ? this.setState({ [event.target.name]: null })
  // : this.setState({
  //     [event.target.name]: event.target.value,
  //   });
};

const handleSecureChange = (event) => {
  setCode_secure(event);
}

const show = () => {
  return point?.length > 0 ? (
    <>
      <h3 className="mainheading theme_color m-0 mb-4">
        {point.length === 1
          ? point.length + `${props.t(" result found")}`
          : point.length + `${props.t(" results found")}`}
      </h3>
{
error === true &&
      <p style={{color:"red"}} className="mainheading m-0 mb-4">{err}</p>
}
      <OwlCarousel
        items={point.length <= 2 ? point.length : 3}
        responsive={responsive}
        className="owl-theme carosal_w_75 mx-auto"
        // loop
        margin={10}
        nav
      >
        {collectionPoints()}
      </OwlCarousel>
    </>
  ) : (
    <h3 className="mainheading m-0 mb-4">
      {props.t("No collection point found")}
    </h3>
  );
};


const handleSubmit = async () => {
  setSearching(true);
  await Axios({
    url: `${Config.apiURL}/collection_points`,
    method: "GET",
    params: { q: search },

    headers: {
      uid: `${uid}`,
      client: `${client}`,
      "access-token": `${accessToken}`,
      "content-type": "application/json",
    },
  })
    .then((response) => {
      const temp = response?.data?.collection_points?.filter(
        (point) => point?.companies?.length > 0
      );
      setPoint(temp);
      setSearching(false);
    })
    .catch((error) => {
      alert("some thing want wrong ");
      setSearching(false);
      setZero(true);
    });
};


const focus = useCallback(() => {
  if (showModal) {
      setTimeout(() => {
          // ref.current.textInput[0].focus()

      }, 500)
  }
}, [showModal, code_secure, setCode_secure])

useEffect(() => {
  focus()
}, [focus])

const { t } = props;

return (
  <div>
    <div className="top_right">
      <img src="/assets/imgs/bubble_3.png" />
    </div>
    <div className="bottom_right">
      <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
    </div>
    <div className="middle_left">
      <img src="/assets/imgs/bubble.png" />
    </div>
      <ConfirmConnectModal
      onPressConfirm={()=>handleHa()}
    />

       <SecureConnectModal
      onPressConfirm={()=>handleHa()}
      handleChange={(e)=>handleSecureChange(e)}
      clearCode = {() => setCode_secure("")}
      ref={ref}
      code={code_secure}
      clear={err ? true : false}
      showModal={showModal}
      setShowModal={setShowModal}
    />

    {/* <Header history={props.history} /> */}
    <div className="container mb-5 z_index_one scroll_tab_content_new vh-90 d-flex flex-column justify-content-center">
      {
        props.history.location && props.location.state?.myLove === "Pakistan" ? <ProgressBar step={4} /> : null
      }
      {/* <ProgressBar step={4} /> */}
      <div className="row">
        <div className="col-md-3 col-lg-3 col-xs-12"></div>
        <div className="col-md-6 col-lg-6 col-xs-12">
          <div className="w-85 ms-auto me-auto login_main p-4 flex-column align-items-center">
            <div className="d-flex flex-column main-title mb-5">
              <h3 className="fw-bold">{t("Connect to your collection point")}</h3>
              <div className="w-25 heading-line" />
            </div>
            <div id="login_form" className="mb-3 w-100">
              <div className="position-relative mb-4 form_input_style d-flex flex-row ps-3 align-items-center">
                <label className="position-absolute px-3 white theme_bg lable_style">
                  {t("connect")}
                </label>
                <img
                  src="/assets/imgs/company_img_1.PNG"
                  className="height_16px"
                  alt=""
                />
                <input
                  type="text"
                  className="form-control bg-transparent border-0 theme_color"
                  name="search"
                  placeholder={t(
                    "company, building or residence name"
                  )}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                <button
                  className={`btn fill_btn_search btn_connect ${searching || search === null || search.length < 3
                    ? "disabled"
                    : ""
                    }`}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {searching
                    ? `${t("processing")}`
                    : `${t("search")}`}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-lg-3 col-xs-12" />
        {point ? (
          show()
        ) : (
          <h6 className="theme_color text-center">
            {t("search for your collection point")}
          </h6>
        )}

        <div className="d-flex flex-column footer margin-top-7 pt-5 bg-transparent">
          <h3 className="text-center regular_font theme_color">{t("unable to connect?")}</h3>
          <p className="text-center">
            <a
              className="small theme_color"
              style={{ color: "white" }}
              href={"https://www.wosh.be/contact"}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              {t("contact us")}
            </a>
            <br />
            <Link
              to="/add-colection-point"
              state={props.location.state.myLove}
              className="small theme_color"
              style={{ color: "white" }}
            >
              {t("Add new collection point")}
            </Link>
          </p>
        </div>
      </div>
      <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
        <Link to="/use-wosh" className="text-decoration-none small fw-bold" href>
          <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
        </Link>
      </div>
    </div>
    {/* {index ? (
      <ConnectConfirm
        uid={uid}
        client={client}
        accesstoken={accessToken}
        point_id={
          point_id &&
          point.map((item, index) => {
            if (item?.id === index) {
              return item.id;
            } else {
              return null;
            }
          })
        }
        company_id={
          company_id &&
          point.map((item, index) => {
            if (item?.id === index) {
              return item.companies[0].id;
            } else {
              return null;
            }
          })
        }
      />
    ) : null} */}
  </div>
);
  }
const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(ConnectPoint);
