import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import { withTranslation } from "react-i18next";
import { logout } from "../../../store/Actions/authActions";
import { connect } from "react-redux";
import { compose } from "redux";

class ThankYou extends React.Component {
  handleLogout = () => {
    this.props.logout(this.props);
  };
  componentDidMount() { }
  render() {
    const { t } = this.props
    return (
      <div>
        {/* <Header history={this.props.history} /> */}
        {
          this.props.history.location.state && this.props.history.location.state?.myLove === "Pakistan"
            ?
            <div>
              <div className="bottom_right">
                <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
              </div>
              <div className="middle_left">
                <img src="/assets/imgs/bubble.png" />
              </div>
              <div className="container mt-4 vh-90 d-flex flex-column justify-content-center align-items-start w_50 mx-auto px-5">
                <h2 className="theme_color font_style fw_semibold">
                  {t('Your registration is complete!')}
                </h2>
                <div className="heading-line mb-3" />
                <p className="theme_color fw_regular"> {t("Thank you for choosing Wosh, your trusted on-demand laundry delivery platform. We are committed to providing you with a seamless and convenient experience, taking care of all your laundry needs with the highest quality service and utmost care.")}</p>
                <div className="w-75 mx-auto">
                  <img
                    src="/assets/imgs/order_confirm_pic.png"
                    className="mt-2 img-fluid"
                    width=""
                    height={"200"}
                    alt=""
                  />
                </div>
                <div className="w-100 text-center mt-2">
                  <Link to='/home' className="w-50 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4">
                    {t('Home Page')}
                  </Link>
                </div>
              </div>
            </div>
            :
            this.props.history.location.state && this.props.history.location.state?.add 
              ?
              <div className="container mt-4 vh-90 d-flex flex-column justify-content-center align-items-start w_50 mx-auto px-5">
                <h1 className="theme_color regular_font">
                  {t('Success!')}
                </h1>
                <div className="heading-line mb-3" />
                <p className="theme_color fw-bold">{t("Your submission has been received, and our team will make it a priority to get in touch with you as soon as possible.")}
                </p>
                <div className="w-50 mx-auto">
                  <img
                    src="/assets/imgs/Delivery_men.png"
                    className="mt-2"
                    width="auto"
                    height={350}
                    alt=""
                  />
                </div>
                <div className="w-100 text-center mt-2" onClick={()=> this.handleLogout()}>
                  <Link className="w-75 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4">
                    {t('Back to login')}
                  </Link>
                </div>
              </div>
              :
              <div>
                <div className="bottom_right">
                  <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
                </div>
                <div className="middle_left">
                  <img src="/assets/imgs/bubble.png" />
                </div>
                <div className="container mt-4 vh-90 d-flex flex-column justify-content-center align-items-start w_50 mx-auto px-5">
                  <h2 className="theme_color font_style fw_semibold">
                    {t('Congratulation!')}
                  </h2>
                  <div className="heading-line mb-3" />
                  <p className="theme_color fw_regular"> {t("Your new address has been successfully registered. You can now proceed with your new order with confidence. We are eagerly awaiting the opportunity to serve you and provide an exceptional experience!")}</p>
                  <div className="w-75 mx-auto">
                    <img
                      src="/assets/imgs/order_confirm_pic.png"
                      className="mt-2 img-fluid"
                      width=""
                      height={"200"}
                      alt=""
                    />
                  </div>
                  <div className="w-100 text-center mt-2">
                    <Link to='/home' onclick className="w-50 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4">
                      {t('Back to home')}
                    </Link>
                  </div>
                </div>
              </div>
              
        }
      </div>
    );
  }
}
// export default withTranslation()(ThankYou);
export default compose(withTranslation(), connect(null, { logout }))(ThankYou);
