import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ReactCodeInput from 'react-code-input';


const SecureConnectModal = (props) => {
    const {
        onPressConfirm,
        handleChange,
        code
    } = props
    const { t } = useTranslation();
    const ref = useRef()

    const focus = useCallback(() => {

        if (props.showModal || props.clear) {
            props.clearCode()
            ref.current.textInput.forEach((e) => e.value = "")
            setTimeout(() => {
                ref.current.textInput[0].focus()

            }, 500)
        }
    }, [props.showModal, props?.clear])

    useEffect(() => {
        focus()

    }, [focus])
    return (
        <div
            className="modal fade"
            id="secureConnect"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content border_color mt-2 mb-5">
                    <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="white fs-2 cursor_pointer"
                            data-bs-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => props.setShowModal(false)}
                            style={{ border: '6px solid #3D4586', borderRadius: 50, position: 'absolute', right: -20, top: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
                        />
                    </div>
                    <div className="modal-body rounded_25px p-4">
                        <div className="text-center theme_color padd_content">
                            <h3 className="mb-5"><strong>
                                {t(
                                    "Secure Collection Point"
                                )}
                            </strong>
                            </h3>
                            <p className="mb-5">
                                {t(
                                    "If you are part of this collection Point you should be able to find the 4 digit code in your emails or in dedicated website for your building"
                                )}
                            </p>
                            <div>
                                <ReactCodeInput
                                    ref={ref}
                                    type="number"
                                    className="typecode mb-5"
                                    fields={4}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-column border-0">
                        <button
                            data-bs-dismiss="modal"
                            aria-hidden="true"
                            className={`fill_btn white fw-bold border_color_btn`}
                            onClick={() => {
                                onPressConfirm()
                            }}
                        >
                            {`${t("Confirm")}`}
                        </button>
                        <a
                            href={"https://www.wosh.be/contact"}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                            className={`bg-transparent theme_color fw-bold border_color_btn text-decoration-none text-center`}
                        // onClick={this.handleHa}
                        >
                            {t("Contact support")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        authUser: state?.auth?.user,
    };
};

export default connect(mapStateToProps)(SecureConnectModal);