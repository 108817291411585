import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Config from "./../../Config";
import Axios from "axios";
import { connect } from "react-redux";
import { setAllClear } from "../../../store/Actions/authActions";

function DeleteAccount(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState()

    const DeleteAccount = async () => {
        const uid = props?.authUser?.user_uid;
        const client = props?.authUser?.user_client;
        const accessToken = props?.authUser?.user_access_token;
        await Axios({
            url: `${Config.authURL}/auth`,
            method: "DELETE",
            headers: {
                uid: `${uid}`,
                client: `${client}`,
                "access-token": `${accessToken}`,
            },
        })
            .then((response) => {
                if (response.data && response.data.message && !response.data.success)
                    setMessage(response?.data?.message)
                else
                {
                    window.localStorage.clear();
                    window.location.reload();
                    props?.dispatch(setAllClear());
                    props?.history.push('/sign-in')
                }
            })
            .catch((err) => {
                setMessage(err?.response?.data?.message?.user[0])
            });
    };

    return (
        <div
            className="modal fade"
            id="delete_modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog flex-column modal-dialog-centered vh-90">
                <div className="modal-content bg-transparent mb-5 border-0 screen_center">
                    <div className="modal-body delete_account_modal bg-light p-5 w-100">
                        <div className="mb-4">
                            <h6 className="theme_color text-center fw-bold">
                                {t("DEACTIVATE ACCOUNT?")}
                            </h6>
                            <p className="text-center text-danger">{message}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button
                                className="theme_color px-5 py-1 delete_account_btn"
                                data-bs-dismiss="modal"
                            >
                                {t("No")}
                            </button>
                            <button
                                className="theme_color px-5 py-1 delete_account_btn"
                                onClick={() => DeleteAccount()}
                            >
                                {`${t("Yes")}`}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    authUser: state?.auth?.user,
});
export default connect(mapStateToProps)(DeleteAccount);
