import moment from 'moment';
import React from 'react'

const TransactionHistory = (props) => {
    return (
        <div className='p_desktop_mobile px-sm-5 px-md-3'>
            {props?.walletEntries && props?.walletEntries.map((i, index) => {
                if (index <= props?.seeMore) {
                    return (
                        <div className='d-flex justify-content-between list-unstyled theme_color'>
                        <div className='my-2'>{i.created_at && moment(i?.created_at).format('DD/MM/YYYY')}</div>
                        <div className='my-2 text-center w-50'>{i.description}</div>
                        <div className='my-2 '>{i.entry_amount > 0 ? '+' + i.entry_amount : i.entry_amount}</div>
                    </div>
                    );
                }

            })}

        </div>
    )
}

export default TransactionHistory