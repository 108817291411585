import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

const ChangeAddress = (props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleProceed = () => {
    props.handleSubmit();
    history.push('/')
  }

  return (
    <div id=''>
      <div
        className="modal fade"
        id="changeAddress"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content border_color">
            <div className="d-flex justify-content-end mb-2 cross_addres">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ width: '40px', height: '40px', border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="modal-body rounded_25px p-4">
              <div className="w-100 text-center">
                <h5 className='theme_color fw-bold'>{t("Changing Address ?")}</h5>
                <img src={'/assets/star_153.png'} width={180} />
                <p>{t("Your new address could potentially be associated with another WOSH Laundry Partner. In such a scenario, there is a chance that product prices may vary slightly, and as a result, your current basket will be deleted.")}</p>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between justify-content-sm-center border-0">
              <button
                data-bs-dismiss="modal"
                aria-hidden="true"
                className='bg-transparent theme_color fw-bold border_color_btn'>
                {t("No, back")}
              </button>
              <button data-bs-dismiss="modal" aria-hidden="true" className='fill_btn white fw-bold border_color_btn'
                onClick={handleProceed}>
                {t("Yes, proceed")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeAddress
