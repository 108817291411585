import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
const SelectButton = (props) => {
  const {t} = useTranslation()
  useEffect(() => {}, [props?.homeAddress, props?.address, props?.sAddress]);
  const item = () => {
    return (
      <>
        <div className="row mb-3 align-items-center">
          {/* <div className="col-4">
            <h6 className="mb-1 white">Address</h6>
          </div> */}
          <div className="col-8">
            <p className="mb-0 theme_color">
              {props?.deliveryAddress &&
              props?.address === true &&
              props?.sAddress.length > 0
                ? props?.sAddress[0]?.address
                : props?.address === false && props?.sAddress.length > 0
                ? props?.sAddress[0]?.name
                : "Please Select Your Address"
                
                }
            </p>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center ">
            <button
              onclick="modalblur()"
              data-bs-toggle="modal"
              data-bs-target="#address"
              className="btn btn-custom-onboard rounded-pill px-4"
            >
              {t('change')}
            </button>
          </div>
        </div>
      </>
    );
  };
  // <div className="row mb-5">
  //         <div className="col-4">
  //           <h6 className="mb-1 white">Address</h6>
  //         </div>
  //         <div className="col-8">
  //           <p className="mb-0 text-end white">
  //             <strong>Note - </strong>You need to select delivery address then
  //         you can adjust pick-up & delivery time accordingly.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="d-flex justify-content-center align-item-center ">
  //         <button
  //           onclick="modalblur()"
  //           data-bs-toggle="modal"
  //           data-bs-target="#address"
  //           className="btn border_btn rounded-pill px-5 my-1 "
  //         >
  //           Select
  //         </button>
  //       </div>
  return (
    <>
      {!props?.sAddress ? (
        <div className="bg-light rounded border border-1 border-white p-3 mb-4">
          <h4 className="text-decoration-underline text-center theme_color mb-3">
            Note
          </h4>
          <div className="d-flex flex-column justify-content-around align-item-center">
            <p className="theme_color text-center">
              You need to select delivery address then you can adjust pick-up &
              delivery time accordingly.
            </p>
            <div className="d-flex justify-content-center">
              <button
                onclick="modalblur()"
                data-bs-toggle="modal"
                data-bs-target="#address"
                className="btn btn-custom-onboard rounded-pill px-5 mb-3 w-75"
              >
                {t('Select')}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* {props?.sAddress ? (
        <div className="bg-light rounded border border-1 border-white p-4 mb-4">
          <h4 className="text-decoration-underline theme_color mb-3">
            {t('Address')}
          </h4>
          {item()}
        </div>
      ) : null} */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    deliveryAddress: state?.auth?.homeAddress,
    address: state?.cart?.home,
    sAddress: state?.auth?.selectedAddress,
  };
};
export default connect(mapStateToProps)(SelectButton);
