import React from "react";

class ProgressBar extends React.Component {
  componentDidMount() { }
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5 ">
        <div className="progress_circle progress_circle_active current_step" />

        <div
          className={
            this.props.step >= 2
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        {this?.props?.step === 2 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className="progress_circle progress_circle_active current_step">
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              this.props.step >= 2
                ? "progress_circle progress_circle_active current_step"
                : "progress_circle "
            }
          />
        )}
        <div
          className={
            this.props.step >= 3
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        {this?.props?.step === 3 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className="progress_circle progress_circle_active current_step">
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              this.props.step >= 3
                ? "progress_circle progress_circle_active current_step"
                : "progress_circle "
            }
          />
        )}
        <div
          className={
            this.props.step >= 4
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        {this?.props?.step === 4 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className="progress_circle progress_circle_active current_step">
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              this.props.step >= 4
                ? "progress_circle progress_circle_active current_step"
                : "progress_circle "
            }
          />
        )}
        <div
          className={
            this.props.step >= 5
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        {this?.props?.step === 5 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className="progress_circle progress_circle_active current_step">
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              this.props.step >= 5
                ? "progress_circle progress_circle_active current_step"
                : "progress_circle "
            }
          />
        )}
        {/* <div
          className={
            this.props.step >= 2
              ? "progress_circle progress_circle_active"
              : "progress_circle "
          }
        /> */}
        {/* <div
          className={
            this.props.step >= 3
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        <div
          className={
            this.props.step >= 3
              ? "progress_circle progress_circle_active"
              : "progress_circle "
          }
        /> */}
        {/* <div
          className={
            this.props.step >= 4
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        />
        <div
          className={
            this.props.step >= 4
              ? "progress_circle progress_circle_active"
              : "progress_circle "
          }
        />
        <div
          className={
            this.props.step >= 5
              ? "progress_line progress_line_active"
              : "progress_line "
          }
        /> */}
        {/* <div
          className={
            this.props.step >= 5
              ? "progress_circle progress_circle_active"
              : "progress_circle "
          }
        /> */}
      </div>
    );
  }
}
export default ProgressBar;
