import React from "react"
import Header from "../Header"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./../Header/user.css"
import TabContent from "./TabContent/index"
import YourItemsCheckout from "./YourItemsCheckout"
import Config from "../../Config"
import Axios from "axios"
import CurrentCart from "./CurrentCart"
import { connect } from "react-redux"
import {
  selectAddress,
  fetchCartItem,
  cartApi,
  cartEdit,
  setCartItems,
  getProducts,
} from "../../../store/Actions/cartActions"
import {
  setSelectedAddress,
  setIsActive,
  setEdit,
  set_OfficeAddress,
  setEditOrder,
} from "../../../store/Actions/authActions"
import { compose } from "redux"
import { withTranslation } from "react-i18next"
import cookies from "js-cookie"
import { withRouter } from "react-router-dom"
import Footer from "./Footer/Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router-dom"
import PremiumLockerInfo from "../Modals/PremiumLockerInfo"
import ReviewModal from "../../User/Modals/ReviewModal/Index"
import * as ReactBootStrap from "react-bootstrap"
import { useDispatch } from "react-redux"
import FiveStarRating from "../Modals/FiveStarRating"
import OrderDeletion from "../Modals/OrderDeletion"

// let history = useHistory();
class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: null,
      sliderData: [],
      bags: {},
      lastOrderReview: false,
      lastOrderReviewId: "",
      style: false,
      cartItems: null,
      lockerData: [],
      currentSliderSelected: {},
      reviewModal: false,
      rating: 0,
      isSelectedData: [],
      isSelectedKey: null,
      loading: false,
      review_content: [],
      fullRating: false,
      categoryOrderIndex: [],
      modalOpen: false,
      confirmDelete: false,
      orderToDelete: null,
      error: false,
    }
  }
  // componentDidMount() {
  //   alert('jj')
  // }

  // async componentDidMount() {
  //   // this.updateSlider()
  //   alert('ookokoko')
  //   this.getData()
  // }

  openConfirmation = (id) => {
    this.setState({
      modalOpen: true,
      orderToDelete: id,
    });
  };

  handleDeleteOrder = async () => {
    const { orderToDelete } = this.state;
    const { authUser, dispatch, cartItems, history } = this.props;

    if (orderToDelete) {
      const uid = authUser?.user_uid;
      const client = authUser?.user_client;
      const accessToken = authUser?.user_access_token;

      try {
        const response = await Axios({
          url: `${Config.apiURL}/customer/orders/${orderToDelete}`,
          method: 'DELETE',
          headers: {
            uid: `${uid}`,
            client: `${client}`,
            'access-token': `${accessToken}`,
          },
        });

        if (response.status === 200) {
          console.log('Order deleted successfully.');
          dispatch(setEditOrder(false));
          cartItems?.forEach((p) => {
            dispatch(cartApi(p, 'destroyAllProducts'));
          });
          history.push('/home');
        } else {
          console.error('Failed to delete the order.');
          this.setState({ err: { error: true } });
        }
      } catch (error) {
        console.error('Error deleting order:', error);
        this.setState({ err: { error: true } });
      } finally {
        this.setState({
          modalOpen: false,
          orderToDelete: null,
        });
      }
    }
  };

  cancelDeletion = () => {
    this.setState({
      modalOpen: false,
      orderToDelete: null,
    });
  };

  getData = async () => {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders`,
        method: "GET",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      })

      let lockerData = []
      response.data.past_orders?.forEach((element) => {
        if (element?.premium_cp === true && element?.status === "delivery") {
          lockerData.push({
            ...element,
            key: element?.id,
            title: this.props.t("Your order is waiting for you !"),
            subTitle: this.props.t("Click here for more info"),
            image: "/assets/Group155.png",
          })
        }
      })
      this.setState({ lockerData: lockerData })
    } catch (error) {
    }

    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/bags`,
        method: "GET",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      })
      let aa = response?.data?.bags
      this.setState({ bags: aa })
    } catch (error) { }

    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders`,
        method: "GET",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      })
      this.setState({
        current: response?.data?.current_orders,
        lastOrderReview: response?.data?.past_order_reviewed,
        lastOrderReviewId: response?.data?.past_orders?.[0]?.id,
      })
      this.updateSlider()
      this.props?.dispatch(
        setIsActive(
          response?.data?.current_order ||
            response?.data?.current_order?.length > 0
            ? response?.data?.current_order[0]
            : false
        )
      )
    } catch (error) { }
  }

  setAdd = () => {
    let h = this.props?.hAddress?.filter((item) => {
      return item?.is_default === true
    })
    let o = this.props?.oAddress?.filter((item) => {
      return item?.is_default === true
    })

    if (h?.length > 0) {
      this.props && this.props?.dispatch(setSelectedAddress(h?.[0]?.id, "home"))
      this.props && this.props?.dispatch(selectAddress("home"))
    }

    if (h?.length === 0 && o?.length > 0) {
      this?.props &&
        this.props?.dispatch(setSelectedAddress(o?.[0]?.id, "office"))
      this?.props && this.props?.dispatch(selectAddress("office"))
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.setAdd()
    }, 3000)
    // this.setAdd();
  }

  updateSlider = () => {
    let temp = []
    if (this.state.lockerData?.length > 0) {
      temp.push(...this.state.lockerData)
    }
    if (this.state.current?.length > 0) {
      temp.push({
        id: 2,
        title:
          `${this.state.current?.length}` +
          " " +
          this.props.t("order in progress"),
        subTitle: this.props.t("Click here to see the details of your order"),
        image: "/assets/Hand-Special.png",
      })
    }
    if (this.state.lastOrderReview === false) {
      temp.push({
        id: 5,
        title: this.props.t("Your order has been delivered"),
        subTitle: this.props.t("Rate your experience"),
        image: "/assets/star_153.png",
      })
    }
    if (cookies.get("laundryOnFoldedCloseStatus") === "false") {
      temp.push({
        id: 3,
        title: this.props.t("Get your laundry bag"),
        subTitle: this.props.t("For folded laundry"),
        image: "/assets/folded.png",
      })
    }
    if (
      !this.state.bags?.on_hanger_check &&
      cookies.get("laundryOnHangerCloseStatus") === "false"
    ) {
      temp.push({
        id: 4,
        title: this.props.t("Get your laundry bag"),
        subTitle: this.props.t("For laundry on Hanger"),
        image: "/assets/hanger.png",
      })
    }
    temp.push({
      id: 1,
      title: this.props.t("Earn 15€ off your next order"),
      subTitle: this.props.t("Share this with your friend"),
      image: "/assets/heartOnHands.png",
    })
    this.setState({ sliderData: temp })
    // console.log('ss',this.state.sliderData)
  }

  onPressSlider = (id) => {
    if (id === 1) {
      this.props.history.push("/earn-wosh-credit")
    }
    if (id === 2) {
      this.props.history.push("/order")
    }
    if (id === 3) {
      cookies.set("laundryOnFoldedCloseStatus", "true")
      let temp1 = this.props?.cartItems?.filter((item) => {
        if (item.name === "Folded Bag") {
          return item
        }
      })
      this.props?.dispatch(cartApi(temp1[0], "plus"))
      this.updateSlider()
    }
    if (id === 4) {
      cookies.set("laundryOnHangerCloseStatus", "true")
      let temp2 = this.props?.cartItems?.filter((item) => {
        if (item.name === "On Hanger Bag") {
          return item
        }
      })
      this.props?.dispatch(cartApi(temp2[0], "plus"))
      this.updateSlider()
    }
    if (id === 5) {
      this.setState({ reviewModal: true })
    }
    if (id === 6) {
    }
  }

  // handleDestroy = () => {
  //   this.props?.dispatch(setEditOrder(false))
  //   this.props?.cartItems?.forEach((p) => {
  //     this.props?.dispatch(cartApi(p, "destroyAllProducts"))
  //   })
  // }

  // handleDeleteOrder = async (id) => {
  //   const { orderToDelete } = this.state;
  //   if (this.props?.editOrder && orderToDelete) {
  //     this.setState({ confirmDelete: false });

  //     await new Promise(resolve => setTimeout(resolve, 2000));
  //     try {
  //       const response = await Axios({
  //         url: `${Config.apiURL}/customer/orders/${id}`,
  //         method: "DELETE",
  //         headers: {
  //           uid: this.props?.authUser?.user_uid,
  //           client: this.props?.authUser?.user_client,
  //           "access-token": this.props?.authUser?.user_access_token,
  //         },
  //       });
  //       if (response.status === 200) {
  //         console.log('Order deleted successfully.');
  //         this.props?.dispatch(setEditOrder(false))
  //         this.props?.cartItems?.forEach((p) => {
  //           this.props?.dispatch(cartApi(p, "destroyAllProducts"))
  //         })
  //       } else {
  //         console.error('Failed to delete the order.');
  //         this.setState({ error: true });
  //       }
  //     } catch (error) {
  //       console.error("Error deleting order:", error);
  //       this.setState({ error: true });
  //     }
  //   }
  // }

  postReview = async () => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/reviews`,
        method: "POST",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
        data: {
          stars: this.state.rating,
          review_content: this.state.review_content,
          order_id: this.state.lastOrderReviewId,
        },
      })
      const stars_count = response.data.review.stars
      if (stars_count == 5) {
        this.setState({ fullRating: true })
      }

      if (stars_count !== 5) {
        window.location.reload(false);
      }
    } catch (error) {

    }
  }

  // componentDidMount (){
  //   setTimeout(() => {
  //     const key = Object.keys(this.props.sortedCategories?.[0])?.[0];
  //   this.handleOverCategory('',key)
  //   }, 1000);
  // }

  componentDidMount() {
    this.props?.dispatch(getProducts()).then(() => {
      return this.getData()
    }).then(() => {
      if (
        this.props.sortedCategories &&
        this.props.sortedCategories?.[0] &&
        this.props.sortedCategories.length > 0
      ) {
        const key = Object.keys(this.props.sortedCategories?.[0])?.[0]
        if (key != "" || key != null || key != undefined) {
          this.handleOverCategory("", key)
        }
      }
    })
    // debugger
    // this.getData().then(() => {
    //   setTimeout(() => {
    //     if (
    //       this.props.sortedCategories &&
    //       this.props.sortedCategories?.[0] &&
    //       this.props.sortedCategories.length > 0
    //     ) {
    //       const key = Object.keys(this.props.sortedCategories?.[0])?.[0]
    //       if (key != "" || key != null || key != undefined) {
    //         this.handleOverCategory("", key)
    //       }
    //     }
    //   }, 1500)
    // })
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.sortedCategories !== this.props.sortedCategories) {
      if (
        this.props.sortedCategories &&
        this.props.sortedCategories?.[0] &&
        this.props.sortedCategories.length > 0
      ) {
        const key = Object.keys(this.props.sortedCategories?.[0])?.[0]
        if (key != "" || key != null || key != undefined) {
          this.handleOverCategory("", key)
        }
      }
    }
  }
  handleOverCategory = (item, key) => {


    // Sorting function
    const sortByOverCategoryOrder = (a, b) => {
      const orderA = a[Object.keys(a)[0]][0].category.over_category.order_by;
      const orderB = b[Object.keys(b)[0]][0].category.over_category.order_by;

      return orderA - orderB;
    };

    const sortByCategoryOrder = (a, b) => {
      const orderA = a[Object.keys(a)[0]][0].category.over_category.order_by;
      const orderB = b[Object.keys(b)[0]][0].category.over_category.order_by;

      return orderA - orderB;
    };

    // Sorting the temp array
    const sortedTemp = this.props.sortedCategories.sort(sortByOverCategoryOrder);
    const sortedFinal = sortedTemp.sort(sortByCategoryOrder);


    // // Sort the temp array based on category.order_by
    // temp.sort((a, b) => {
    //   const orderA = a[Object.keys(a)[0]][0]?.category?.order_by || 0;
    //   const orderB = b[Object.keys(b)[0]][0]?.category?.order_by || 0;
    //   return orderA - orderB;
    // });

    // console.log(temp);

    // console.log('sortedTemp',sortedFinal);

    let filtered_key = sortedFinal.filter(
      (item) => Object.keys(item)?.[0] === key
    )

    //   unique_translatedName.sort((a, b) => {
    //     if (a.itemIndex === null && b.itemIndex === null) {
    //         return 0;
    //     } else if (a.itemIndex === null) {
    //         return 1;
    //     } else if (b.itemIndex === null) {
    //         return -1;
    //     } else {
    //         return a.itemIndex - b.itemIndex;
    //     }
    // });

    const categoryOrder = []
    const flattened = filtered_key[0][key].reduce((acc, cur) => {


      const category =
        cookies.get("i18next") === "fn"
          ? cur?.category?.name_en
          : cookies.get("i18next") === "du"
            ? cur?.category?.name_nl
            : cur?.category?.name

      categoryOrder.push(cur.category.order_by)
      // console.log('unique_id', filtered_key)

      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(cur)
      // console.log('unique_id', this.props.sortedCategories)
      return acc
    }, {})

    // const customOrder = ["Top", "Bottom", "Long", "Outdoor", "Kids fr", "Bag", "Home laundry", "Kilo", "Bedding", "Towels", "Table", "Others", "Iron only"];
    // const sortedSubcategories = customOrder.reduce((acc, category) => {
    //   if (flattened[category]) {
    //     acc[category] = flattened[category];
    //   }
    //   return acc;
    // }, {});

    const unique_id = [...new Set(categoryOrder)]
    unique_id.sort((a, b) => a - b);
    this.setState({ isSelectedKey: key, isSelectedData: flattened, categoryOrderIndex: unique_id })
    this.setState({ loading: this.state.loading, loading: true })
  }

  orderRetrieved = async (id) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/orders/retrieve/${id}`,
        method: "PUT",
        headers: {
          "access-token": this.props.authUser?.user_access_token,
          client: this.props.authUser?.user_client,
          uid: this.props.authUser?.user_uid,
        },
        data: {
          order: {
            status: "retrieved_by_user",
          },
        },
      })
      this.getData().then(() => {
        this.updateSlider()
      })
    } catch (err) {
      // return err;
    }
  }

  // openConfirmation = (id) => {
  //   this.setState({ confirmDelete: true, orderToDelete: id });
  // };

  // // Cancel deletion
  // cancelDeletion = () => {
  //   this.setState({ confirmDelete: false, orderToDelete: null });
  // };

  render() {
    const { t } = this.props
    const { modalOpen, confirmDelete } = this.state;
    const { editOrder, currentId, handleDestroy } = this.props;
    return (
      <>
        <div className={`${this.state.reviewModal && "blured_bg"} h-100`}>
          <div className="top_right">
            <img src="/assets/imgs/bubble_3.png" />
          </div>
          <div className="bottom_right">
            <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
          </div>
          <div className="rectangle_top_left">
            <img src="/assets/imgs/Rectangle_top.png" />
          </div>
          {/* <div className="circle_top_right">
          <img src="/assets/imgs/circle_top.png" />
        </div>
        <div className="circle_top_right_dot">
          <img src="/assets/imgs/circle_top.png" />
        </div> */}
          <div className="middle_left">
            <img src="/assets/imgs/bubble.png" />
          </div>
          {/* <Header isNav={true} /> */}
          <div className="w-100" />
          {editOrder && (
            <div className="theme_green_bg py-2">
              <div className="row align-items-center px-5">
                <div className="col-md-4 d-flex justify-content-lg-start justify-content-center">
                  {modalOpen && (
                    <OrderDeletion
                      show={modalOpen}
                      mainText={t("Delete Order")}
                      subText={t("Are you sure you want to delete your order?")}
                      handleDeleteOrder={this.handleDeleteOrder}
                      cancelDeletion={this.cancelDeletion}
                      t={t}
                    />
                  )}
                  <button
                    onClick={() => this.openConfirmation(this.props?.currentId)}
                    className="bg-transparent white border rounded border-white px-2 py-1"
                    aria-label="Delete order"
                  >
                    {t("Delete Order")}
                  </button>
                </div>
                <div className="col-md-4">
                  <p className="text-center white m-0">
                    {confirmDelete
                      ? t('Are you sure you want to delete your order?')
                      : t('You are currently editing an order')}
                  </p>
                </div>
                <div className="col-md-4 d-flex justify-content-lg-end justify-content-center">
                  <p className="white fs-2 m-0 cursor_pointer" onClick={() => {
                    this.props?.dispatch(setEditOrder(false))
                    this.props?.cartItems?.forEach((p) => {
                      this.props?.dispatch(cartApi(p, "destroyAllProducts"))
                    })
                  }}>&times;</p>
                </div>
              </div>
            </div>
          )}
          <div className="h_100">
            <div className="container-fluid padding z_index_one h-100">
              <div className="d-flex flex_row h_100">
                {/* <div className="col-md-12 col-lg-2 col-xs-12 col-sm-12 h_100 hide_in_responsive"> */}

                <div className="w_150px h_100 hide_in_responsive">
                  <div className="margin_right h-100">
                    <ul
                      className="nav nav-tabs first_tabs d-flex flex-column justify-content-evenly my_tab_style nav_new_design"
                      style={{ backgroundColor: "#E6E9FF" }}
                      id="myTab"
                      role="tablist"
                    >
                      {this.props.errorProducts ? (
                        <></>
                      ) : (
                        this.props.sortedCategories &&
                        this.props.sortedCategories.length > 0 &&
                        this.props.sortedCategories.map((item, index) => {
                          const key = Object.keys(item)[0]
                          const translatedObj =
                            this.props?.translatedArr?.[index]
                          return (
                            <div
                              className="w-100 nav-item d-flex justify-content-center align-items-center"
                              onClick={() => this.handleOverCategory(item, key)}
                            >
                              <div
                                className={`w-100 nav-link py-2 d-flex flex-column justify-content-center border_hover align-items-center fw-bold ${this.state.isSelectedKey === key
                                  ? "active"
                                  : null
                                  } `}
                              >
                                <img
                                  src={
                                    Config.apiImage +
                                    item[key][0]?.category?.over_category?.icon
                                  }
                                  style={{ width: 60, height: 60 }}
                                  alt=""
                                />
                                <p className="text-center my-2 cat_font_size">
                                  {cookies.get("i18next") === "en"
                                    ? translatedObj?.en
                                    : cookies.get("i18next") === "fn"
                                      ? translatedObj?.fr
                                      : cookies.get("i18next") === "du"
                                        ? translatedObj?.nl
                                        : translatedObj?.en}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      )}
                    </ul>
                  </div>
                </div>

                <div className="w-100">
                  <div className="d-flex flex-column justify-content-between h_92vh position-relative">
                    <div className="show_in_responsive">
                      <ul
                        className="nav nav-tabs first_tabs d-flex justify-content-around align-items-center my_tab_style my-5"
                        id="myTab"
                        role="tablist"
                      >
                        {this.props.errorProducts ? (
                          <></>
                        ) : (
                          this.props.sortedCategories &&
                          this.props.sortedCategories.length > 0 &&
                          this.props.sortedCategories.map((item, index) => {
                            const key = Object.keys(item)[0]
                            const translatedObj =
                              this.props?.translatedArr?.[index]
                            return (
                              <div
                                className="nav-item d-flex justify-content-center align-items-center"
                                onClick={() =>
                                  this.handleOverCategory(item, key)
                                }
                              >
                                <div
                                  className={`nav-link py-2 padd_in_mobile d-flex flex-column justify-content-center border_hover align-items-center fw-bold ${this.state.isSelectedKey === key
                                    ? "active"
                                    : null
                                    } `}
                                >
                                  <img
                                    src={
                                      Config.apiImage +
                                      item[key][0]?.category?.over_category
                                        ?.icon
                                    }
                                    style={{ width: 60, height: 60 }}
                                    alt=""
                                  />
                                  <p className="text-center my-2 cat_font_size">
                                    {cookies.get("i18next") === "en"
                                      ? translatedObj?.en
                                      : cookies.get("i18next") === "fn"
                                        ? translatedObj?.fr
                                        : cookies.get("i18next") === "du"
                                          ? translatedObj?.nl
                                          : translatedObj?.en}
                                  </p>
                                </div>
                              </div>
                            )
                          })
                        )}
                        {/* <li
                        className="nav-item d-flex justify-content-center align-items-center"
                        role="presentation"
                      >
                        <a
                          className="nav-link py-0 d-flex flex-column justify-content-center align-items-center fw-bold active "
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#hangers"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          <img src="/assets/imgs/hanger.png" alt="" />
                          {t("On hangers")}
                        </a>
                      </li>
                      <li
                        className="nav-item d-flex justify-content-center align-items-center"
                        role="presentation"
                      >
                        <a
                          className="nav-link py-0 d-flex flex-column justify-content-center align-items-center fw-bold"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          href="#kilo"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          <img src="/assets/imgs/kilo.png" alt="" />
                          {t("Kilo")}
                        </a>
                      </li>
                      <li
                        className="nav-item d-flex justify-content-center align-items-center"
                        role="presentation"
                      >
                        <a
                          className="nav-link py-0 d-flex flex-column justify-content-center align-items-center fw-bold "
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#folded"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          <img src="/assets/imgs/folded.png" alt="" />
                          {t("Folded")}
                        </a>
                      </li>
                      <li
                        className="nav-item d-flex justify-content-center align-items-center"
                        role="presentation"
                      >
                        <a
                          className="nav-link py-0 d-flex flex-column justify-content-center align-items-center fw-bold"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          href="#ironing"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          <img src="/assets/imgs/iron.png" alt="" />
                          {t("Ironing")}
                        </a>
                      </li> */}
                        {/* <li
                        className="nav-item d-flex justify-content-center align-items-center"
                        role="presentation"
                      >
                        <a
                          className="nav-link py-0 d-flex flex-column justify-content-center align-items-center fw-bold"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          href="#favorite"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          <img src="/assets/imgs/fav.png" alt="" />
                          favorite
                        </a>
                      </li> */}
                      </ul>
                    </div>
                    {/* <div className="hide_in_responsive pb-5"></div> */}
                    {this.props.errorProducts ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "red",
                          fontSize: 18,
                        }}
                      >
                        {t(
                          "Laundry place not found for the given collection point"
                        )}
                      </div>
                    ) : this.state.loading && !this.props.loading ? (
                      <TabContent
                        editOrder={editOrder}
                        data={this.state.isSelectedData}
                        catgoryOrder={this.state.categoryOrderIndex}
                      />
                    ) : (
                      <ReactBootStrap.Spinner
                        animation="grow"
                        className="bounce"
                      />
                    )}
                    {/* <Footer /> */}
                  </div>
                </div>
                <div className={editOrder ? "maxw_335px mobile_screen padding_in_mobile overflowYScroll height_in_desktop_mobile_edit_order" : "maxw_335px mobile_screen padding_in_mobile overflowYScroll height_in_desktop_mobile"}>
                  <h3 className="theme_color mt-5 d_none_in_mobile">
                    {t("Welcome")} {this.props?.userName?.user_name}
                  </h3>
                  <div className="w-100 d-flex">
                    <div className="heading-line w-25 d_none_in_mobile" />
                  </div>
                  {/*
                {this.state.current && (
                  <CurrentCart current={this.state.current} sliderData={this.state.sliderData} onPressSlider={(e)=>this.onPressSlider(e)} />
                )} */}
                  {/* <YourItemsCheckout current={this.state.current} sliderData={this.state.sliderData} onPressSlider={(e) => this.setState({ currentSliderSelected: e }) || this.onPressSlider(e.id)} /> */}
                  {/* {
                  this.props.editt ?
                    <div className="d-flex flex-row align-items-center justify-content-between mt-5 mx-2">
                      <h4 className="theme_color">
                        {t('Edit Order')}
                      </h4>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="theme_color fs-2 cursor_pointer"
                        aria-hidden="true"
                        onClick={() => this.handleDestroy(this.state.current)}
                      />
                    </div> : <h4 className="mt-5 theme_color">
                      {t('Confirm Order')}
                    </h4>
                } */}

                  <YourItemsCheckout
                    current={this.state.current}
                    sliderData={this.state.sliderData}
                    onPressSlider={(e) =>
                      this.setState({ currentSliderSelected: e }) ||
                      this.onPressSlider(e.id)
                    }
                  />

                  <PremiumLockerInfo
                    currentSliderSelected={this.state.currentSliderSelected}
                    onPressOrderRetrieved={(id) => this.orderRetrieved(id)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flexShrinkZero showInMobile margin_in_mobile">
            <Footer />
          </div>
          {/* <p className="theme_color margin_in_mobile">
            {t(
              "All rights reserved. © WOSH 2023. By visiting this page you agree to our "
            )}
            <a
              style={{ color: "#fff" }}
              href=" https://www.wosh.be/legal/privacy-policy"
              target="_blank"
              className="theme_green_color"
              rel="noreferrer"
            >
              {t("privacy policy")}
            </a>{" "}
            and{" "}
            <a
              style={{ color: "#fff" }}
              href="https://www.wosh.be/legal/terms-conditions"
              target="_blank"
              className="theme_green_color"
              rel="noreferrer"
            >
              {t("terms and conditions")}.
            </a>
          </p> */}
        </div>
        {this.state.reviewModal && (
          <ReviewModal
            rating={this.state.rating}
            review_content={this.state.review_content}
            onClick={() => this.setState({ reviewModal: false })}
            setRating={(rating) => this.setState({ rating: rating })}
            setReviewContent={(content) =>
              this.setState({ review_content: content })
            }
            onPressBtn={() => this.postReview()}
          />
        )}
        {this.state.fullRating && (
          <FiveStarRating
            toggleModal={() =>
              this.setState({ fullRating: !this.state.fullRating })
            }
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
    hAddress: state?.auth?.homeAddress,
    oAddress: state?.auth?.officeAddress,
    cartItems: state?.cart?.mockItems,
    userName: state?.auth?.user,
    editt: state?.auth?.edit,
    editOrder: state?.auth?.editOrder,
    currentId: state?.auth?.currentId,
    sortedCategories: state?.cart?.sortedCategories,
    errorProducts: state.cart.error,
    translatedArr: state.cart.translatedArr,
    loading: state.auth.loading
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(Home)
