import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";


class MasterCard extends React.Component {
  componentDidMount() {}
  render() {
    const {t} = this.props;
    return (
      <div>
        <div
          className="modal fade"
          id="mastercard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{t('Add method')}</h6>
                <FontAwesomeIcon
                  onclick="removeblur()"
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4">
                <h5 className="theme_color fw-bold">Master Card</h5>
                <div className="heading-line_color" />
                <input
                  type="text"
                  className="border-bottom form-control bg-transparent border-0 my-3"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder={`${JSON.parse(
                    localStorage.getItem("user_name")
                  )}`}
                />
                <div className="border-bottom my-3 d-flex flex-row">
                  <div className="bg-dark w-and-h rounded d-flex justify-content-center align-items-center">
                    <img src="../assets/imgs/mc_icon.png" />
                  </div>
                  <input
                    type="text"
                    className="border-0 form-control bg-transparent"
                    id="number"
                    aria-describedby="emailHelp"
                    placeholder="4747 8921 0178 9203"
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="border-bottom form-control bg-transparent border-0"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="12/25"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="border-bottom form-control bg-transparent border-0"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={474}
                    />
                  </div>
                </div>
                <input
                  type="email"
                  className="border-bottom form-control bg-transparent border-0 my-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Belgique"
                />
                <button
                  type="submit"
                  className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                >
                  Save
                </button>
                <button
                  type="submit"
                  className="w-100 bg-transparent border-0 mt-4 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-trash-o" aria-hidden="true" />
                  <span className="ms-2">Delete Card</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(MasterCard);
