import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setWallet,
  get_paymentId,
} from "../../../../store/Actions/authActions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const AddPhoneNum = (props) => {
  useEffect(() => {
    // props?.dispatch(setWallet("wallet"));
  }, []);
  const [termsConditions, setTermsConditions] = useState(false)
  const [error, setError] = useState([])
  const [check, setCheck] = useState(false)
  // const [phone, setPhone] = useState('')

  const { t } = useTranslation();

  const handleCheck = (event) => {
    if (event.target.checked) {
      setTermsConditions(true)
      // setError([])
      setCheck(true)
    } else {
      setError(["Terms and conditions are Declined"])
      setCheck(false)
    }
  };
  // const { t } = this.props;
  return (
    <div>
      <div
        className="modal fade"
        id="add_phone_num"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              {/* <h6 className="white">{t("confirm your order")}</h6> */}
              <h6></h6>
              <FontAwesomeIcon
                onClick={props.onPress}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4">
              <h5 className="theme_color fw-bold">{t("Please Validate your Phone Number")}</h5>
              <div className="heading-line_color" />
              <div className="w-75 text-center mx-auto my-5">
                
              </div>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true
                }}
                defaultCountry="US"
                value={props.phone}
                onChange={(phone) => props.setPhone(phone)}
              />
              <div className="w-75 text-center mx-auto my-5">
                
                </div>
              <div className="mb-3 form-check">
                <input
                  required
                  type="checkbox"
                  className="form-check-input"
                  name="terms"
                  id="termsConditions"
                onChange={(event)=>{handleCheck(event)}}
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.wosh.be/legal/terms-conditions"
                  className="form-check-label check_lable text-decoration-underline"
                  style={{ textDecoration: "none", color: "#000" }}
                  // style={{ textDecoration: "none", color: "#414882" }}
                  htmlFor="exampleCheck1"
                >
                  {t("I have read and agree to the terms and conditions")}
                </a>
              </div>
              {check && props.phone && props.phone.length > 3 &&
              <button
                data-bs-toggle="modal"
                data-bs-target="#confirmOrder"
                aria-hidden="true"
                type="submit"
                className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
              >
                {t("Confirm")}
              </button>
}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  paymentId: state?.auth?.paymentId,
});
export default connect(mapStateToProps)(AddPhoneNum);
