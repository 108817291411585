import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setPaymentMethod,
  setPaymentCard,
} from "../../../../../store/Actions/authActions";
import {withTranslation} from "react-i18next";
import { compose } from "redux";
class SelectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentId: null,
    };
  }
  componentDidMount() {}
  handleChange = (event) => {
    // debugger
    this.setState({
      [event.target.name]: +event.target.value,
    });
    this.props.dispatch(setPaymentCard(+event.target.value));
  };
  render() {
    const {t} = this.props
    return (
      <div>
        <div
          className="modal fade"
          id="mastercard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                <FontAwesomeIcon
                  onclick="removeblur()"
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4" style={{ border: '8px solid #3D4586' }}>
                <h5 className="theme_color fw-bold align-self-center text-center fw_semibold">{t('select a card')}</h5>
                {this.props?.cards && this.props?.cards.length > 0 ? (
                  <select
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    name="paymentId"
                    class="form-select w-75 my-5 mx-auto theme_border"
                    aria-label="Default select example"
                  >
                    <option className="text-center small" selected>{t("Open this select menu")}</option>
                    {this.props?.cards?.map((item, index) => {
                      return (
                          <>
                            {item?.id && <option value={item?.id}>
                              **** **** **** {item?.last4}
                            </option>}
                          </>
                      );
                    })}
                  </select>
                ) : (
                  <p className="theme_color text-center">
                    {t("You don't have a card saved yet, please add to continue.")}
                  </p>
                )}
                {this.state.paymentId && this.state.paymentId !== null ? (
                  <button
                    onClick={() => {
                      this.props.dispatch(setPaymentMethod("Debit Card"));
                    }}
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    type="submit"
                    className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                  >
                    {t('done')}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cards: state?.auth?.paymentId,
});
export default compose(withTranslation(), connect(mapStateToProps))(SelectCard);
