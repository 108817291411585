import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { setPromo } from "../../../../store/Actions/cartActions";
import { connect } from "react-redux";

import { ToastsContainer, ToastsStore } from "react-toasts";
import {useTranslation} from "react-i18next";

const PromoCode = (props) => {
  const {t} = useTranslation();
  const [code, setCode] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("modal")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState("")
  const [message,setMessage] = useState(props.promo?.message)
  const handleChange = (event) => {
    setMessage('');
    setErr('')
    setCode(event.target.value);
  };
  useEffect(()=>{
    setMessage('')
  },[])

  const toast = () => {
    if (err === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (err === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  const handleValidate = async () => {
    setLoading(true)
    let temp = await props?.dispatch(setPromo(code));

    if (temp) {
      setLoading(false)
      ToastsStore.info("Promo code has been successfully applied");
      setErr("okay");
      setShow("d-block");
      setModal("modal")

    } else {
      setLoading(false)
      setErr("error");
      ToastsStore.error("Promo code is invalid");
    }
  };
  return (
    <div id="darkautofill">
      {toast()}
      <div
        className="modal fade"
        id="promocode"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                onClick={props.onPress}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4 text-center" style={{ border: '8px solid #3D4586' }}>
              <h5 className="theme_color fw-bold fw_semibold">{t('Promo code')}</h5>

              <div className="min_height110px d-flex align-items-center">
                <div className="w-100">
                  {err === 'error' || err === ''  && <input
                    type="text"
                    className="form-control bg_f4f4f4 rounded theme_2pxborder my-3"
                    id="text"
                    aria-describedby="emailHelp"
                    placeholder={t("redeem your code")}
                    onChange={handleChange}
                  />}
                  <div className={err === 'error'  ? 'color-red' : 'color_green'}>{err === 'error'  ? t("ERROR ")+ `❌` :err === '' ? '' :  t("Congratulations! ") + `🎉`}</div>
                  <div className={'theme_color'}>{err === 'error'  ? t("This promo code does not exist"): err === '' ? '' : t("Your promo code has been successfully applied!")}</div>
                </div>
              </div>
              {loading ? <button
                  // onClick={handleValidate}

                  className="btn fill_btn mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled px-4 rounded-pill"
              >
                {t('processing')}
              </button>
              : <button
                  onclick="modalblur()"
                  data-bs-dismiss={err === 'okay' && "modal"}
                  onClick={ ()=> err === 'error'   ? setErr('') || setMessage('') :handleValidate()}
                  className="btn fill_btn mt-4 mb-2 ms-auto me-auto d-flex justify-content-center px-4 rounded-pill"
                >
                  {err === 'error' ? t('Try again') : err === 'okay' ? t('Done') :  t('Check')}
                </button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  promo: state.cart.promo,
});

export default connect(mapStateToProps)(PromoCode);
