import React from "react";
import { connect } from "react-redux";
import ItemList from "../ItemList";

export const FavoriteTab = (props) => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="favorite"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <ItemList cat={"fav"} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteTab);
