import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import WalletStepII from "../../../Modals/WoshPayement/Step2";

const Wallet = (props) => {
  const {t} = useTranslation();
  useEffect(() => { }, [props?.wall]);
  return (
    <div className="w-100 d-flex flex-row mb-4">
      <div className="w-100 d-flex flex-sm-row flex-column mb-4 woshwallet_border">
        <h5 className="w_75 btn payment_btn theme_color d-flex justify-content-center flex-row align-items-center px-sm-5 px-md-3 px-5 shadow-none outline-0 me-2 mb-sm-0 mb-2 fw-bold regular_font mb-0">
          {props?.wallet ? `${parseFloat(props?.wallet)?.toFixed(2).replace(".", ",")} €` : "0€"}
        </h5>
        <button
        className="w_50 m-auto btn woshwallet_new_2 white shadow-none py-2"
        // className="w-50 m-auto btn woshwallet_new_2 white shadow-none py-2"
        data-bs-toggle="modal"
        data-bs-target="#payment_modalII"
      >
        <p className="m-0">{t("Top Up wallet")}</p>
      </button>
      </div>
      {/* <div className="w-50 woshwallet_border_new btn theme_color d-flex justify-content-center flex-row align-items-center px-lg-5 px-md-3 px-5 shadow-none outline-0 mb-sm-0 mb-2">
        <h6 className="text_bold mb-0 mt-1">
          {props?.wallet ? `${parseFloat(props?.wallet)?.toFixed(2).replace(".", ",")} €` : "0€"}
        </h6>
      </div> */}
      {/* <div className="w-100 d-flex woshwallet_border align-items-center text-center">
        <h2 className="w-50 payment_amount theme_color fw-bold m-0">
          {props?.wallet ? `${parseFloat(props?.wallet)?.toFixed(2).replace(".", ",")} €` : "0€"}
        </h2> */}
        {/* <button
          className="w-50 m-auto btn woshwallet_new_2 white shadow-none py-2"
          data-bs-toggle="modal"
          data-bs-target="#payment_modalII"
        >
          <p className="m-0">{t("Top Up wallet")}</p>
        </button> */}
        <WalletStepII amount={props?.wallet} />
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wall: state?.auth?.wallet,
  };
};

export default connect(mapStateToProps)(Wallet);
