import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import SingleReviewStar from "../../../User/Order/SingleReviewStar/Index";
import {useTranslation} from "react-i18next";
import StarRating from "../../Order/Star"

const ReviewModal = (props) => {
  const {t} = useTranslation();

  return (
    <>
                   <div style={{position:'absolute',top:'0',width:'100%', height: '100%' , zIndex: 1000, background: 'rgb(0 0 0 / 26%)'}}>
                     <div className="modal-dialog flex-column modal-dialog-centered modal-lg vh-90 ">
                       <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center" >
                         <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                           <FontAwesomeIcon
                             onClick={props.onClick}
                             icon={faTimesCircle}
                             className="white fs-2 cursor_pointer"
                             data-bs-dismiss="modal"
                             aria-hidden="true"
                             style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
                           />
                         </div>
                         <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                           <div className="w-100" id="stripe_form" style={{ borderColor: '#3D4586', borderWidth: 10 }}>
                             <div className="Form text-center" style={{ border: '8px solid #3D4586' }}>
                               <div className="w-100 d-flex flex-column mb-3">
                                 <h5 className="theme_color fw-bold align-self-center fw_semibold">
                                   {t("Rate your last order")}
                                 </h5>
                               </div>
                               <img src={'/assets/star_153.png'} alt="" className="align-self-center mb-4" style={{ width: 130, height: 130 }} />
             
                              <div style={{color:'grey',fontSize:14}}>{t("Did you enjoy the wosh experience?")}</div>
                              <div style={{color:'grey',fontSize:14}}>{t("Let us know how your last order went !")}</div>
             
                               <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                 {/* {props.currentSliderSelected?.lockers?.length > 0 && collectionPoints()} */}
                               </div>

                                 <div className="mt-4">
                                  <SingleReviewStar
                                    rating={props.rating}
                                    reviewed={false}
                                    setRating={(rating) => props.setRating(rating)}
                                  />
                                  <textarea
                                  type="text"
                                  name="message"
                                  placeholder={t("tell us about your experience.")}
                                  className="w_40 mx-auto form-control reviewInput height_100px theme_color"
                                  onChange={(event)=> props.setReviewContent(event.target.value)}
                                />
                                  </div>
             
                               <div className="d-flex flex-row justify-content-between mt-4">
                               <button class="btn fill_btn lh-sm ms-auto me-auto d-flex justify-content-center align-items-center py-2 px-5 mt-4" 
                                 onClick={()=>{
                                  props.onPressBtn()
                                 props.onClick()}}>{t("Save")}</button>
                               </div>
             
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </>
  );
};

const mapStateToProps = (state) => ({
  promo: state.cart.promo,
});

export default connect(mapStateToProps)(ReviewModal);
