import React, { useState } from "react";
import Header from "../Header";
import Config from "../../Config";
import { useTranslation, withTranslation } from "react-i18next";
import Axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { compose } from "redux";
import { connect } from "react-redux";
import { setPaymentMethod } from "../../../store/Actions/authActions";

const ToppedUp = (props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const BTNAME =
    localStorage.getItem("redirect") === "/profile"
      ? "Back to profile"
      : "Back to checkout";
  const walletUpdate = (paymentIntent) => {
    Axios({
      url: `${Config.apiURL}/customer/wallet`,
      method: "PUT",
      data: {
        wallet: {
          amount: paymentIntent.amount / 100,
        },
      },
      headers: {
        uid: props.newUser.user_uid,
        client: props.newUser.user_client,
        "access-token": props.newUser.user_access_token,
      },
    })
      .then((res) => {
        setLoading(false);
        setText(t("Your wallet has been topped up!"));
        return res;
      })
      .catch((err) => {
        setLoading(false);

        setText(t("Your wallet Couldn't be topped-up"));
        return err;
      });
  };
  const click = async () => {
    setLoading(true);
    const url = new URL(window.location);
    const clientSecret = url.searchParams.get("payment_intent_client_secret");
    const { paymentIntent, error } = await stripe.retrievePaymentIntent(
      clientSecret
    );
    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        return paymentIntent;
      } else {
        return paymentIntent;
      }
      // props.history.push("/profile");
    }
    return error;
  };

  return (
    <div>
      {/* <Header history={props.history} /> */}
      <div className="container mt-2 vh-90 d-flex flex-column justify-content-center align-items-start w-50 mx-auto px-5">
        <h1 className="theme_color regular_font">
          {t('Your wallet has been topped up!')}
        </h1>
        <div className="heading-line mb-3" />
        {/* </div> */}
        <p className="theme_color fw-bold">{t("Congratulations! Your WOSH wallet has been topped up and is ready to make laundry day effortless! Enjoy the convenience of on-demand laundry delivery and say goodbye to laundry worries. Let WOSH take care of your clean clothes, giving you more time for what matters most. Say hello to convenience with WOSH!")}</p>
        <div className="w-50 mx-auto">
          <img
            src="/assets/imgs/Delivery_men.png"
            className="mt-2 img-fluid"
            width="auto"
            height={350}
            alt=""
          />
        </div>
        <div className="w-100 text-center mt-2">
          <button
            // to={localStorage.getItem("redirect")}
            className="w-75 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4"
            onClick={() => {
              props.history.entries = [];

              props.history.index = -1;
              props.history.replace(
                localStorage.getItem("redirect"),
                "urlHistory"
              );
              localStorage.removeItem("redirect");
            }}
          >
            {t("Back to checkout")}
          </button>
        </div>
      </div>

      {/* <div className="container mt-4 d-flex flex-column justify-content-center align-items-center">
        <div className="w_min_content">
          <h1 className="text-white text-center">
            {t("Your wallet has been topped up!")}
          </h1>
          <div className="heading-line w-25" />
        </div>

        <img
          src="/assets/imgs/toppedup.png"
          className="mt-5"
          width="auto"
          height={200}
          alt=""
        />
        <div className="w-100 mt-5 text-center">
          <button
            // to={localStorage.getItem("redirect")}
            className="w-75 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4"
            onClick={() => {
              props.history.entries = [];

              props.history.index = -1;
              props.history.replace(
                localStorage.getItem("redirect"),
                "urlHistory"
              );
              localStorage.removeItem("redirect");
            }}
          >
            {t("Back to checkout")}
          </button>
        </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    newUser: state.auth.user,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(ToppedUp);
