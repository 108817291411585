import React from "react";
import { connect } from "react-redux";
import ItemList from "../ItemList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import IronModal from "../../../Modals/IroningModal/index";
import { useTranslation } from "react-i18next";

export const IroningTab = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="tab-pane fade reponsive_set pb-4"
        id="ironing"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <ul className="nav nav-tabs second_tabs mb-4" id="myTab" role="tablist">
          <li className="nav-item kilo_tab_shadow" role="presentation">
            <a
              className="nav-link p-3 h-100 active kilo_tab_shadow"
              id="home-tab"
              data-bs-toggle="tab"
              href="#per_kilo"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <div className="d-flex flex-row align-items-center">
                <img src="/assets/imgs/iron.png" width={40} alt="" />
                <div className="ms-3">
                  <h3 className="fs-5 fw-bold mb-0">{t('Ironing')}</h3>
                  <p className="small mb-0">{t('Ironed only')}</p>
                </div>

              </div>
            </a>
          </li>
          {/* <li class="nav-item" role="presentation">
                  <a class="nav-link p-3 h-100" id="profile-tab" data-bs-toggle="tab" href="#home-laundry" role="tab" aria-controls="profile" aria-selected="false">
                    <div class="d-flex flex-row align-items-center mb-1">
                      <img src="../assets/imgs/home_laundry.png" width="30">
                      <h3 class="fs-5 mb-0">Home laundry</h3>
                    </div>
                    <p class="small mb-0">Returned wash, dry, iron & fold.</p>
                  </a>
                </li> */}
        </ul>
        <p className="theme_text theme_color">
          {t("Professional ironing skills applied to your home-washed items.")}.
        </p>
        <p className="theme_text">
          <div className="info_bg rounded me-2">
            <FontAwesomeIcon
              icon={faInfo}
              className="small"
              aria-hidden="true"
            />
          </div>
          <a
            data-bs-toggle="modal"
            data-bs-target="#IronModal"
            className="text-decoration-underline cursor_pointer theme_color"
          >
            {t("How to estimate my laundry weight?")}
          </a>
        </p>
        <ItemList cat={"iron"} />
        <IronModal />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IroningTab);
