import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AllDone from "../../AllDone";
import Config from "../../../../Config";
import Axios from "axios";
import React, { useState } from "react";
import "../../../Header/user.css";
import { connect } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

const WalletStepIII = (props) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    if (!name) {
      setError(t("Fill in your name to continue"));
      return;
    }
    setError(null);
    setLoading(true);
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/secret`,
        method: "GET",
        headers: {
          uid: props?.authUser?.user_uid,
          client: props?.authUser?.user_client,
          "access-token": props?.authUser?.user_access_token,
        },
        params: { amount: props?.amount },
      });

      const secret = await response?.data?.client_secret;
      handleClick(secret);
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.errors
          ? err?.response?.data?.errors[0]
          : "Server is not responding at the moment, please try later"
      );
    }
  };

  const handleClick = async (secret) => {
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const { error } = await stripe.confirmBancontactPayment(secret, {
      payment_method: {
        billing_details: {
          name: name,
        },
      },
      return_url: process.env.REACT_APP_WOSH_WALLET_REDIRECT,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
    }
  };

  return (
    <div id="darkautofull">
      <div
        className="modal fade"
        id="payment_modalIII"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div
              className="modal-body rounded_25px bg-light p-4" style={{ border: '8px solid #3D4586' }}
              id="darkautofull"
            >
              <div className="step3">
                <h5 className="theme_color fw-bold align-self-center text-center fw_semibold">
                  {t("Top up your WOSH Wallet")}
                </h5>
                {error ? <p className="color-red">{error}</p> : null}
                <p className="fw-bold m-0 theme_color my-5 mx-3 text-center">
                  {t("Add")}{" "}
                  <span className="theme_color fw_semibold">{props?.amount}€</span>{" "}
                  {t("to your wallet")}
                </p>
                <div>
                  <div
                    id="darkautofill"
                    className="position-relative mb-4 form_input_style_dark d-flex flex-row px-3 py-1 align-items-center"
                  >
                    <label class="position-absolute px-3 theme_color_bg white lable_style">
                      {t("Name")}
                    </label>
                    <input
                      id="light_placeholder"
                      className="form-control bg-transparent border-0 outline_none shadow-none"
                      onChange={handleChange}
                      name="accountholder-name"
                      placeholder="Barry White"
                      required
                    />
                  </div>
                  <button
                    className={
                      loading
                        ? "btn rounded-pill d-flex align-items-center justify-content-center w-85 m-auto border-2 disabled"
                        : "btn rounded-pill d-flex align-items-center justify-content-center w-85 m-auto border-2"
                    }
                    style={{ border: "1px solid #004E91", color: "#004E91" }}
                    onClick={handleSubmit}
                  >
                    <img src="/assets/imgs/Bancontact_new.png" width={40} alt="" />
                    <a className="px-2" disabled={!stripe} >
                      <strong>
                        {loading
                          ? `${t("Processing")}`
                          : `${t("Pay by Bancontact")}`}
                      </strong>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AllDone />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pid: state.auth.paymentId,
    authUser: state?.auth?.user,
  };
};

export default connect(mapStateToProps)(WalletStepIII);
