const {
  REACT_APP_WOSH_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_WOSH_IMAGE,
  REACT_APP_WOSH_SOCIAL_CALLBACK,
  REACT_APP_WOSH_GOOGLE_CLIENT_ID,
  REACT_APP_APPLE_LOGIN,
} = process.env;
export default {
  frontEndUrl: "",
  apiURL: REACT_APP_WOSH_URL,
  authURL: REACT_APP_AUTH_URL,
  apiImage: REACT_APP_WOSH_IMAGE,
  socialLogin: REACT_APP_WOSH_SOCIAL_CALLBACK,
  oAuthId: REACT_APP_WOSH_GOOGLE_CLIENT_ID,
  appleLogin:REACT_APP_APPLE_LOGIN,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};
// export default {
//   frontEndUrl: "",
//   apiURL: "https://woshbackend.herokuapp.com",
//   headers: {
//     "Content-Type": "application/json;charset=UTF-8",
//     "Access-Control-Allow-Origin": "*",
//   },
// };

//  https://woshbackend.herokuapp.com
// http://localhost:3001
