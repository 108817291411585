import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { ToastsContainer, ToastsStore } from "react-toasts";


const ConfirmConnectModal = (props ) => {
    const {
        onPressConfirm
    } = props
    const { t } = useTranslation();

    return (
        
        <div
        className="modal fade"
        id="confirmConnect"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content border_color py-5">
                    <div className="modal-body rounded_25px p-4">
                        <div className="text-center theme_color">
                            <h3><strong>
                                {t(
                                    "Are you sure you want to connect with this collection point ?"
                                )}
                            </strong>
                            </h3>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between border-0">
                        <button
                            data-bs-dismiss="modal"
                            aria-hidden="true"
                            className={`bg-transparent theme_color fw-bold border_color_btn`}
                           
                        >
                            {t("No")}
                        </button>
                        <button
                            data-bs-dismiss="modal"
                            aria-hidden="true"
                            className={`fill_btn white fw-bold border_color_btn`}
                            onClick={() => onPressConfirm()}
                        >
                            {
                                `${t("Yes")}`}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        authUser: state?.auth?.user,
    };
};

export default connect(mapStateToProps)(ConfirmConnectModal);