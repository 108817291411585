import React from "react";
import $ from "jquery";
import Header from "../../Header/index";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next"

class OnSuccess extends React.Component {
  componentDidMount() {
    $("#app-container").addClass("body");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {/* <Header history={this.props.history} /> */}
        <div className="modal-content bg-transparent mb-5 border-0 w-50 mx-auto mt-5">
          <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
            <h6 className="theme_color fw-bold ms-3">{t("Success")}</h6>
            {/* <FontAwesomeIcon
              // onClick={props.onPress}
              icon={faTimesCircle}
              className="white fs-2 cursor_pointer"
              data-bs-dismiss="modal"
              aria-hidden="true"
            /> */}
          </div>
          <div className="modal-body rounded_25px bg-light p-4">
            <h5 className="theme_color fw-bold">{t("Check your email")}</h5>
            <div className="heading-line_color" />
            <div className="mt-5 pt-5">
              <p>
                {t("We’ve sent an email on your adress")} `
                {this.props.history.location.pathname.split("mail-check/")[1]}`{". "}
                {t("Go check if you have already received the email. It might take a few minutes.")}
              </p>
              <p>{t("Didn’t receive the link?")}</p>
              <Link to="/forgot-password">{t("Try another email address")}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(OnSuccess);
