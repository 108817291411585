import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { CartIncDec, setPastCart } from "../../../../store/Actions/cartActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PastOrderModal = ({ ...props }) => {
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();
  const orderAgain = () => {
    let array = [];
    if (props?.current) {
      let array = props?.current?.products?.map((item, index) => {
        const result = props?.items?.find((product) => {
          if (product.id === item.product_id && product?.is_active) {
            return product;
          }
        });
        if (result?.is_active) {
          return {
            ...result,
            quantity: item?.quantity,
            price: +result?.price * item?.quantity,
          };
        }
      });
      let final = array?.filter((item) => item !== undefined);
      setProducts(final);
    }
  };
  useEffect(() => {
    // console.log({ products });
    orderAgain();
  }, [props?.current]);
  return (
    <div>
      <div
        className="modal fade"
        id="pastOrder"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal_main h-100 w-100 position-absolute"></div>
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <h6 className="white">{t("Order again")}</h6>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4">
              <div className="w-100 d-flex">
                <div>
                  {!products?.length ? (
                    <p>{t("error")}</p>
                  ) : (
                    <div>
                      <div className="d-flex flex-column mb-4">
                        <h1 className="theme_color fs-3 fw-bold">
                          {t("past order")}
                        </h1>
                        <div class="heading-line line_dark w-25 mb-3"></div>
                      </div>

                      <div className="d-flex flex-row justify-content-between mb-2">
                        <h3 className="theme_color fs-5 fw-bold mb-2">
                          {t("items")}:
                        </h3>
                        <h3 className="theme_color fs-5 fw-bold mb-2 text-end">
                          {t("quantity")}
                        </h3>
                      </div>
                      {/* {console.log("logging the state array", products)} */}
                      {products.map((item) => {
                        return (
                          <>
                            <div className="d-flex flex-row justify-content-between">
                              <h3 className="fs-5 m-0">{t(item?.name)}</h3>
                              <p className="fw-bold text-end">
                                {item?.quantity}
                              </p>
                            </div>
                          </>
                        );
                      })}
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <h3 className="theme_color fs-5 fw-bold mb-2">
                          {t("Promo code")}:
                        </h3>
                        <p className="theme_color fs-5 mb-2 text-end">
                          {props?.current?.promo_code
                            ? `${props?.current?.promo_code}`
                            : t("None")}
                        </p>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <h3 className="theme_color fs-5 fw-bold mb-2">
                          {t("pick-up")}:
                        </h3>
                        <h3 className="theme_color fs-5 fw-bold mb-2 text-end">
                          {t("delivery")}:
                        </h3>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-5 m-0">
                          {props?.current?.pickup_date}
                        </p>
                        <p className=" text-end">
                          {props?.current?.delivery_date}
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          props?.dispatch(setPastCart(products));
                          props?.history?.push("/checkout");
                        }}
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                        className="btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                      >
                        {t("continue")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state?.cart?.mockItems,
    current: state?.auth?.current,
  };
};
export default connect(mapStateToProps)(PastOrderModal);
