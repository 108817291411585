import React, { useEffect, useState } from "react";
import "../../Header/user.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setPTime } from "../../../../store/Actions/authActions";
function DayDateSlot({
  this_date,
  next_date,
  day,
  slot,
  third_date,
  id,
  onClick,
  active,
  click,
  ...props
}) {
  useEffect(() => {}, [props?.sAddress, props?.address]);
  const { t } = useTranslation();
  const [calender1, setCalender1] = useState("");
  const [calender2, setCalender2] = useState("");

  const handleActive = (type) => {
    if (type === "calender1") {
      setCalender1(calender1 === "" ? "clender_active" : "");
      setCalender2("");
    } else {
      setCalender2(calender2 === "" ? "clender_active" : "");
      setCalender1("");
    }
  };
  return (
    <>
      <li
        style={{ cursor: "pointer", zIndex: 1, }}
        id={id}
        className={`${active ? "clender_active" : ""}`}
        onClick={(e) => {
          onClick({
            day: day,
            date: this_date ? this_date : next_date ? next_date : third_date,
            slot: slot,
            id: id,
          });
        }}
      >
        <span id="span1">{`${t(day?.substring(0, 3))}` ?? "no day"}</span>
        <span>
          <strong id="strong1">
            {this_date
              ? this_date?.split("-")[2] + "/" + this_date?.split("-")[1]
              : next_date
              ? next_date?.split("-")[2] + "/" + next_date?.split("-")[1]
              : third_date?.split("-")[2] + "/" + third_date?.split("-")[1]}
          </strong>{" "}
        </span>
      </li>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  sAddress: state?.auth?.selectedAddress,
});
export default connect(mapStateToProps)(DayDateSlot);
