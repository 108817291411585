import { connect } from "react-redux";
import ItemCard from "../ItemCard";
import Config from "../../../../Config";
import Cookies from "js-cookie";
const ItemList = (props) => {
  let quant;
  let elem;
  return (
    <div>
      <div className="d-flex flex-row flex-wrap">
        {props?.data && props?.data?.length < 0
          ? "No Items yet..."
          : props?.data.map((item, index) => {
              props?.cart &&
                props?.cart.forEach((element) => {
                  if (element?.id === item?.id) {
                    quant = element?.quantity;
                    elem = element;
                  }
                });
              return (
                <ItemCard
                  elem={elem?.id}
                  key={item?.id}
                  item={item}
                  id={item?.id}
                  category={item.category.name}
                  title={Cookies.get("i18next") === 'en' ? item?.name : Cookies.get("i18next") === 'fn' ? item?.name_fr : Cookies.get("i18next") === 'du' ? item?.name_nl : item?.name} 
                  src={Config.apiImage + item?.icon}
                  price={+item?.price}
                  quantity={elem?.id === item?.id ? quant : item?.quantity}
                />
              );
            })}
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  if (ownProps.cat === "folded" && ownProps.sub === "bodylaundry") {
    return {
      cartItems: state?.cart?.foldedItems.filter(
        (sub) => sub.category?.name?.toLowerCase() === "body laundry"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "folded" && ownProps.sub === "homelaundry") {
    return {
      cartItems: state?.cart?.foldedItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "home laundry"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "hanger" && ownProps.sub === "top") {
    return {
      cartItems: state?.cart?.onHangerItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "top"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "hanger" && ownProps.sub === "accessory") {
    return {
      cartItems: state?.cart?.onHangerItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "accessory"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "hanger" && ownProps.sub === "outside") {
    return {
      cartItems: state?.cart?.onHangerItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "outside"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "hanger" && ownProps.sub === "bottoms") {
    return {
      cartItems: state?.cart?.onHangerItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "bottoms"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "hanger" && ownProps.sub === "long") {
    return {
      cartItems: state?.cart?.onHangerItems.filter(
        (sub) => sub?.category?.name?.toLowerCase() === "long"
      ),
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "kilo") {
    // return { cartItems: state?.cart?.kiloItems, cart: state?.cart?.cartItems };
  } else if (ownProps.cat === "iron") {
    return {
      cartItems: state?.cart?.ironingItems,
      cart: state?.cart?.cartItems,
    };
  } else if (ownProps.cat === "fav") {
    return {
      cartItems: state?.cart?.favoriteItems,
      cart: state?.cart?.cartItems,
    };
  } else {
    return { cartItems: state?.cart?.mockItems, cart: state?.cart?.cartItems };
  }
};

export default connect(mapStateToProps)(ItemList);
