import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../Header/user.css";
import { Link,useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CurrentCart = (props) => {
  const [cmnt, setCmnt] = useState("");
  const [userName, setUserName] = useState(null);
  const [index, setIndex] = useState(0);
  const [current, setCurrent] = useState(props.current[0]);
  const { t } = useTranslation();
  useEffect(() => {
    // console.log({ current: props.current });
    setUserName(props?.userName?.user_name);
  }, []);

  const dis = (msg) => {
    return (
      <div className="bg-light-color d-flex flex-row justify-content-between p-4 w-100 h-100">
        <span>{t("Comments")} :</span>
        <span className="text_color fw-bold">{msg}</span>
      </div>
    );
  };
  const comment = (code, current) => {
    if (current?.collection_point_id) {
      let cmt = props?.oAddress?.filter(
        (item) => item?.id === current?.collection_point_id
      );
      return (
        <>
          <div className="row py-3">
            <div className="col-6 ps-4 pe-1">{t("collection point")} :</div>
            <div className="col-6 pe-3 text_color fw-bold">
              {cmt[0]?.comment}
            </div>
          </div>
          <div className="row py-3">
            <div className="col-6 ps-4 pe-1">{t("code")} :</div>
            <div className="col-6 pe-3 text_color fw-bold">{code}</div>
          </div>
        </>
      );
    } else {
      let cmt = props?.hAddress?.filter(
        (item) => item?.id === current?.delivery_address_id
      );
      return (
        <div className="row py-3">
          <div className="col-6 ps-4 pe-1">{t("Comments")} :</div>
          <div className="col-6 pe-3 text_color fw-bold">{cmt[0]?.comment}</div>
        </div>
      );
    }
  };

  // paginat
  let items = [];
  let leftSide = index - 3;
  if (leftSide <= 0) leftSide = 1;
  let rightSide = index + 3;
  if (rightSide > props.current.length) rightSide = props.current.length;
  for (let number = index + 1; number <= rightSide; number++) {
    items.push(
      <div
        style={{ cursor: "pointer" }}
        key={number}
        className={
          number === index + 1 ? "round-effect active" : "round-effect"
        }
        onClick={() => {
          setCurrent(props.current[number - 1]);
        }}
      >
        {number}
      </div>
    );
  }

  const nextPage = () => {
    if (index < props.current.length - 1) {
      setCurrent(props.current[index + 1]);
      setIndex(index + 1);
    } else {
      return;
    }
  };

  const prevPage = () => {
    if (index >= 1) {
      setCurrent(props.current[index - 1]);
      setIndex(index - 1);
    } else {
      return;
    }
  };
  // paginat

  return (
    <>
      <div className="row">
        <div className="pb-sm-5">
          <h3 className="white mt-5">
            {t("Welcome")} {userName}
          </h3>
          <div className="heading-line w-25" />
        </div>
        <div
          key={props.index}
          className="col-md-4 box-shadow bg-white w-100 h-100 p-2 mt-5 rounded"
        >
          <ul
            className="nav nav-pills d-flex flex-nowrap mb-3 flex-grow-1"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item bg-light-color w-100" role="presentation">
              <button
                className="nav-link text_color active w-100"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <div className="d-flex flex-column justify-content-center align-items-start m-0 p-0 lh-sm">
                  <p>
                    {t("pick-up")} - <b>{current?.pickup_date}</b>
                  </p>
                  <p className="text-start">
                    <b>
                      {current?.collection_point_id
                        ? current?.collection_point_address
                        : current?.delivery_address}
                    </b>
                  </p>
                  <p>
                    <b>{current?.pickup_slot}</b>
                  </p>
                </div>
              </button>
            </li>
            <li
              className="nav-item bg-light-color d-flex align-items-center"
              role="presentation"
            >
              <button
                className="nav-link text_color h-100"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <div className="d-flex flex-column justify-content-center align-items-start fw-bold lh_1 mb-0">
                  <p>{t("delivery")}</p>
                  <p>Info</p>
                  <p style={{ fontSize: "10px" }}>
                    <b>{current?.delivery_slot}</b>
                  </p>
                </div>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="px-4">
                <h4>{t("Your items")}</h4>
                <ul className="p-0">
                  {current?.products?.map((item, index) => {
                    const result = props?.items?.find((product) => {
                      if (product.id === item.product_id) {
                        return product;
                      }
                    });
                    return (
                      <li className="d-flex flex-row justify-content-between align-items-center">
                        <span>{t(result?.name)}</span>
                        <span className="text_color fw-bold">
                          {item?.quantity < 2 ? item?.quantity : item?.quantity}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <div className="heading-line-color w-100" />
                <div className="d-flex flex-row justify-content-between align-items-center w-100 py-2">
                  <h5>{t("total price")}</h5>
                  <h5 className="text_color fw-bold">
                    {parseFloat(current?.subtotal)?.toFixed(2)?.replace(".", ",")} €
                  </h5>
                </div>
              </div>
              <div className="bg-light-color d-flex flex-row justify-content-between p-4 w-100 h-100">
                <span>{t("Order ID")}:</span>
                <span className="text_color fw-bold">
                  #{current?.wosh_order_id}
                </span>
              </div>
              {comment(current?.collection_point_access_code_pick_up, current)}
              <div className="d-flex justify-content-center">
                <Link
                  to="/order"
                  className="btn w-75 h-100 bg_btn_add rounded-pill text-white my-4"
                >
                  {t("Check the details")}
                </Link>
              </div>
            </div>
            <div
              className="tab-pane fade p-4 h-100 w-100"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-flex flex-row justify-content-between align-items-center w-100 py-2 px-4">
                <h5 className="fw-bold">{t("Delivery Date")} :</h5>
                <h5 className="text_color">{current?.delivery_date}</h5>
              </div>
              <p>
                {t("Your order will be delivered on the above mentioned date")}
              </p>
              {comment(current?.collection_point_access_code_delivery)}
            </div>
          </div>
        </div>
      </div>

      <div className="paginate-ctn d-flex flex-row justify-content-center align-items-center mt-4">
        <div
          className="round-effect"
          onClick={prevPage}
          style={{ cursor: "pointer" }}
        >
          {" "}
          &lsaquo;{" "}
        </div>
        {items ? items : null}
        <div
          className="round-effect"
          onClick={nextPage}
          style={{ cursor: "pointer" }}
        >
          {" "}
          &rsaquo;{" "}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state?.cart?.mockItems,
    userName: state?.auth?.user,
    index: state?.auth?.index,
    oAddress: state?.auth?.officeAddress,
    hAddress: state?.auth?.homeAddress,
  };
};
export default connect(mapStateToProps)(CurrentCart);
