import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Config from "./../../../Config";
import Axios from "axios";
import { forceRender, setAddress, set_OfficeAddress } from "../../../../store/Actions/authActions";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class AddAddress extends React.Component {
  constructor() {
    super();
    this.state = {
      uid: null,
      client: null,
      id: null,
      success: false,
      accessToken: null,
      street: null,
      nr: null,
      address: "",
      loading: false,
      city: null,
      err: "",
      country: null,
      zipCode: null,
    };
  }
  componentDidMount() {
    const uid = this.props?.authUser?.user_uid;
    const id = this.props?.authUser?.user_id;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    // const uid = JSON.parse(localStorage.getItem("user_uid"));
    // const id = JSON.parse(localStorage.getItem("user_id"));
    // const client = JSON.parse(localStorage.getItem("user_client"));
    // const accessToken = JSON.parse(localStorage.getItem("user_access-token"));

    this.setState({
      uid,
      client,
      accessToken,
      id,
    });
  }
  toast = () => {
    if (this.state.err === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (this.state.err === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  handleValidate = async () => {
    if (this.state.err === "okay") {
      ToastsStore.info("Address has been added");
    } else {
      ToastsStore.error("You might have entered wrong zip code");
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleNewSubmit = (e) => {
    // debugger
    this.props?.dispatch(setAddress(e))
    this.props?.dispatch(set_OfficeAddress(e))
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/customer/delivery_addresses`,
      method: "POST",
      data: {
        delivery_address: {
          street: this.state.street,
          nr: this.state.nr,
          city: this.state.city,
          country: this.state.country,
          zip_code: this.state.zipCode,
        },
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        // console.log("response", response);
        this.setState({ loading: false, err: "okay", success: true });
        // this.props?.dispatch(set_OfficeAddress());
        this.handleValidate();
      })
      .catch((error) => {
        // console.log("error response upon saving address", error.response.data);

        this.setState({ loading: false, err: "error" });
        this.handleValidate();
      });
  };

  render() {
    const { t } = this.props
    const mystyle = {
      backgroundColor: "transparent",
      borderBottom: '1px solid #ced4da',
      border: '0',
      margin: '20px 0'
    };
    return (
      <div id="darkautofill">
        {this.toast()}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal_main h-100 w-100 position-absolute"></div>
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{t('New address')}</h6>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4">
                <div className="w-100 d-flex">
                  <ul
                    className="nav nav-tabs profile_tabs border-bottom-0"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link border-0 bg-transparent p-0 me-4 h-100 active theme_color"
                        id="home-address"
                        data-bs-toggle="tab"
                        href="#home-address"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {t('Home')}
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link border-0 bg-transparent p-0 me-4 h-100 theme_color"
                        id="office-address"
                        data-bs-toggle="tab"
                        href="#office-address"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Office
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="mx-auto tab-content pt-4" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-address"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      // defaultValue={this.state.city}
                      placeholder={t('city')}
                      name="city"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                    />
                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      // defaultValue={this.state.street}
                      placeholder={t("Street")}
                      name="street"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder={`${t('Nr')}*`}
                          // defaultValue={this.state.nr}
                          name="nr"
                          onKeyUp={(event) => {
                            this.handleChange(event);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          // defaultValue={this.state.zipCode}
                          placeholder="1050"
                          name="zipCode"
                          onKeyUp={(event) => {
                            this.handleChange(event);
                          }}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      // defaultValue={this.state.country}
                      placeholder={t("country")}
                      name="country"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                    />

                    {this?.state?.success ? (
                      <button
                        onClick={(e) => {this.handleNewSubmit(e);
                          this.setState((prev) => {
                            return {
                              ...prev,
                              success: false,
                              street: null,
                              nr: null,
                              loading: false,
                              city: null,
                              err: "",
                              country: null,
                              zipCode: null,
                            };
                          });
                        }}
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                        className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                      >
                        Close
                      </button>
                    ) : this.state?.loading && !this.state?.success ? (
                      <button className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled">
                        {t('processing')}
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          this.handleSubmit(e);
                        }}
                        className={
                          this.state.city &&
                            this.state.street &&
                            this.state.nr &&
                            this.state.zipCode &&
                            this.state.country
                            ? "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                            : "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                        }
                      >
                        {t('Confirm')}
                      </button>
                    )}
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="office-address"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <input
                      type="email"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={t("city")}
                    />
                    <input
                      type="email"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={t("street")}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="email"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder={`${t("Nr")}*`}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="additional*"
                        />
                      </div>
                    </div>
                    <input
                      type="email"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Belgique"
                    />
                    <button
                      type="submit"
                      className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                    >
                      {t('Confirm')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    renderCheck: state?.auth?.render,
    authUser: state?.auth?.user,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(AddAddress);
