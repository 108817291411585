import * as actions from "../Actions/cartActions"
import * as actionss from "../Actions/authActions"
import Axios from "axios"
import Config from "../../components/Config"
import { store } from "../.."
import cookies from "js-cookie"
export const intialState = {
  totalAmount: 0,
  home: null,
  sortedCategories: [],
  cartItems: [],
  foldedItems: [],
  onHangerItems: [],
  kiloItems: [],
  ironingItems: [],
  favoriteItems: [],
  mockItems: [],
  time: null,
  pickup: null,
  promo: null,
  error: null,
  translatedArr: []
}

let updatedTotalAmount,
  existingItemIndex,
  existingItem,
  updatedItem,
  updatedTotalPrice

export default function cartReducer(state = intialState, action) {
  switch (action.type) {
    case actions.SET_QUANTITY:
      if (state.cartItems?.length === 0) {
        if (action.sign === "plus") {
          const temp = {
            ...action.payload,
            quantity: 1,
            product_id: action?.payload?.id,
          }
          const totalAmount = +temp?.price

          return {
            ...state,
            totalAmount: totalAmount,
            cartItems: [temp],
          }
        }
      } else {
        const temp = state?.cartItems?.filter(
          (item) => item?.id === action?.payload?.id
        )
        const originalProduct = state?.mockItems?.filter(
          (item) => item?.id === action?.payload?.id
        )
        if (temp?.length > 0) {
          if (action.sign === "plus") {
            const updatedItem = state?.cartItems?.map((item) => {
              if (item?.id === action?.payload?.id) {
                return {
                  ...item,
                  quantity: item?.quantity + 1,
                  price: +item?.price + +originalProduct[0]?.price,
                  product_id: item?.id,
                }
              } else {
                return {
                  ...item,
                }
              }
            })

            const updatedTotal = state?.totalAmount + +originalProduct[0]?.price
            return {
              ...state,
              totalAmount: updatedTotal,
              cartItems: updatedItem,
            }
          } else {
            if (temp[0]?.quantity === 1) {
              const updatedItem = state?.cartItems?.filter(
                (item) => item?.id !== originalProduct[0]?.id
              )
              const updatedTotal =
                updatedItem?.length > 0
                  ? state?.totalAmount - +originalProduct[0]?.price
                  : 0
              return {
                ...state,
                totalAmount: updatedTotal,
                cartItems: updatedItem,
              }
            } else {
              const updatedItem = state?.cartItems?.map((item) => {
                if (item?.id === action?.payload?.id) {
                  return {
                    ...item,
                    quantity: item?.quantity - 1,
                    price: +item?.price - +originalProduct[0]?.price,
                    product_id: originalProduct[0]?.id,
                  }
                } else {
                  return {
                    ...item,
                  }
                }
              })
              const updatedTotal =
                state?.totalAmount - +originalProduct[0]?.price
              return {
                ...state,
                totalAmount: updatedTotal,
                cartItems: updatedItem,
              }
            }
          }
        } else {
          if (action.sign === "plus") {
            const updatedItem = {
              ...action?.payload,
              quantity: 1,
              product_id: action?.payload?.id,
            }
            const updatedTotal = state?.totalAmount + +originalProduct[0]?.price
            return {
              ...state,
              totalAmount: updatedTotal,
              cartItems: [...state.cartItems, updatedItem],
            }
          }
        }
      }
      break

    case actions.SET_CART_ITEMS:
      let array = []
      action.payload?.cart_products?.map((item) => {
        state?.mockItems?.filter((i) => {
          if (i?.id === item?.product_id) {
            array.push({
              ...i,
              quantity: item?.quantity,
              product_id: item?.product_id,
              cart_id: item?.id,
              price: item?.total,
            })
          }
        })
      })
      return {
        ...state,
        cartItems: array,
        totalAmount: action?.payload?.subtotal,
      }
    // case actions.SET_CART_QUANTITY:
    //   existingItemIndex = state.mockItems.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   existingItem = state.mockItems[existingItemIndex];
    //   let existingCartItemIndex = state.cartItems.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   let existingCartItem = state.cartItems[existingCartItemIndex];
    //   const cartItemPrice = +existingItem.price;
    //   if (action.sign === "plus") {
    //     updatedTotalAmount = state.totalAmount + cartItemPrice;
    //   } else {
    //     updatedTotalAmount = state.totalAmount - cartItemPrice;
    //   }
    //   if (updatedTotalAmount <= 0) {
    //     updatedTotalAmount = 0;
    //     localStorage.removeItem("cart");
    //     localStorage.removeItem("totalAmount");
    //     return {
    //       ...state,
    //       totalAmount: 0,
    //       cartItems: [],
    //     };
    //   }
    //   if (existingCartItem) {
    //     if (action.sign === "plus") {
    //       updatedItem = {
    //         ...existingCartItem,
    //         price: +existingCartItem.price + cartItemPrice,
    //         quantity: existingCartItem.quantity + 1,
    //       };
    //     } else {
    //       updatedItem = {
    //         ...existingCartItem,
    //         price: +existingCartItem.price - cartItemPrice,
    //         quantity: existingCartItem.quantity - 1,
    //       };
    //       if (updatedItem.price <= 0) {
    //         const updatedCartItems = state.cartItems.filter(
    //           (item) => item.id !== action.payload.id
    //         );
    //         localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    //         localStorage.setItem(
    //           "totalAmount",
    //           JSON.stringify(updatedTotalAmount)
    //         );
    //         return {
    //           ...state,
    //           totalAmount: updatedTotalAmount,
    //           cartItems: updatedCartItems,
    //         };
    //       }
    //     }
    //   } else {
    //     let item = {
    //       ...action.payload,
    //       quantity: 1,
    //     };
    //     localStorage.setItem("cart", JSON.stringify([item]));
    //     // localStorage.setItem("totalAmount", JSON.stringify(updatedTotalAmount));
    //     return {
    //       ...state,
    //       totalAmount: updatedTotalAmount,
    //       cartItems: state.cartItems.concat(item),
    //     };
    //   }
    //   let updatedCartItems = [...state.cartItems];
    //   updatedCartItems[existingCartItemIndex] = updatedItem;
    //   localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    //   // localStorage.setItem("totalAmount", JSON.stringify(updatedTotalAmount));
    //   return {
    //     ...state,
    //     totalAmount: updatedTotalAmount,
    //     cartItems: updatedCartItems,
    //     // mockItems: updatedItems,
    //   };

    case actions.GET_PRODUCTS:
      let overCatName = []
      let translatedOverCat = []
      if (action && action.payload) {
        action?.payload?.forEach((element) => {
          // const translateName =
          //   Cookies.get("i18next") === "en"
          //     ? element.category.over_category.name
          //     : Cookies.get("i18next") === "fn"
          //     ? element.category.over_category.name_en
          //     : Cookies.get("i18next") === "du"
          //     ? element.category.over_category.name_nl
          //     : element.category.over_category.name
          if (element?.category?.over_category) {
            overCatName.push(element?.category?.over_category?.name)
            translatedOverCat.push({
              en: element?.category?.over_category?.name,
              fr: element?.category?.over_category?.name_en,
              nl: element?.category?.over_category?.name_nl,
              itemIndex: element?.category?.over_category.order_by
            })
          }
        })
        
        // const customOrder = [
        //   "Delicate on hanger",
        //   "Delicate folded",
        //   "Everyday",
        //   "Homeware",
        //   "Ironing Only"
        // ];

        // // Sort the categories based on the custom order
        // overCatName.sort((a, b) => {
        //   return customOrder.indexOf(a) - customOrder.indexOf(b);
        // });
        // // Sort the translated categories based on the custom order
        // translatedOverCat.sort((a, b) => {
        //   return customOrder.indexOf(a['en']) - customOrder.indexOf(b['en']);
        // });


        
        const unique_names = [...new Set(overCatName)]
        const unique_translatedName = [...new Map(translatedOverCat.map(item => [item['en'], item])).values()]
        unique_translatedName.sort((a, b) => {
          if (a.itemIndex === null && b.itemIndex === null) {
              return 0;
          } else if (a.itemIndex === null) {
              return 1;
          } else if (b.itemIndex === null) {
              return -1;
          } else {
              return a.itemIndex - b.itemIndex;
          }
      });

        let finalCat = []
        unique_names?.forEach((element, index) => {
          let filtered = action.payload.filter((item) => {
            return item?.category?.over_category?.name === element
            // Cookies.get("i18next") === "en"
            //   ? item.category.over_category.name === element
            //   : Cookies.get("i18next") === "fn"
            //   ? item.category.over_category.name_en === element
            //   : item.category.over_category.name_nl === element
          })
          const updateItem = []
          filtered.forEach((_) => {
            const translateName =
              cookies.get("i18next") === "en"
                ? _.category?.over_category?.description
                : cookies.get("i18next") === "fn"
                  ? _.category?.over_category.description_fr
                  : _.category?.over_category?.description_nl
            // const translateName = _.category.over_category.description_nl
            updateItem.push({
              ..._,
              category: {
                ..._.category,
                over_category: {
                  ..._.category?.over_category,
                  description: translateName,
                },
              },
            })
          })
          finalCat.push({ [element]: updateItem })
        })
        let folded = action?.payload?.filter(
          (item) =>
            item?.category?.over_category?.name?.toLowerCase() === "folded" &&
            item?.is_active
        )
        let onHanger = action?.payload?.filter(
          (item) =>
            item?.category?.over_category?.name?.toLowerCase() ===
            "on hanger" && item?.is_active
        )
        let kilo = action?.payload?.filter(
          (item) =>
            item?.category?.over_category?.name?.toLowerCase() === "per kilo" &&
            item?.is_active
        )
        let ironing = action?.payload?.filter(
          (item) =>
            item?.category?.over_category?.name?.toLowerCase() === "ironing" &&
            item?.is_active
        )
        let fav = action?.payload?.filter(
          (item) =>
            item?.category?.over_category?.name?.toLowerCase() ===
            "favourite" && item?.is_active
        )

        let mock = action?.payload?.filter((item) => item?.is_active)

        return {
          ...state,
          mockItems: mock,
          foldedItems: folded,
          onHangerItems: onHanger,
          kiloItems: kilo,
          ironingItems: ironing,
          favoriteItems: fav,
          translatedArr: unique_translatedName,
          sortedCategories: finalCat || null,
        }
      }
    case actions.SELECT_ADDRESS:
      if (action.temp === "office") {
        return {
          ...state,
          time: null,
          pickup: null,
          home: false,
        }
      } else {
        return {
          ...state,
          time: null,
          pickup: null,
          home: true,
        }
      }
    case actionss.SET_CLEAR:
      return {
        totalAmount: 0,
        home: null,
        cartItems: [],
        // totalAmount: localStorage.getItem("totalAmount")
        //   ? JSON.parse(localStorage.getItem("totalAmount"))
        //   : 0,
        // home: null,
        // cartItems: localStorage.getItem("cart")
        //   ? JSON.parse(localStorage.getItem("cart"))
        //   : [],
        foldedItems: [],
        onHangerItems: [],
        kiloItems: [],
        ironingItems: [],
        favoriteItems: [],
        mockItems: [],
        time: null,
        pickup: null,
        promo: null,
        error: null,
      }
    case actions.CLEAR_CART:
      localStorage.removeItem("totalAmount")
      localStorage.removeItem("cart")
      return {
        ...state,
        cartItems: [],
        totalAmount: 0,
      }
    case actions.SET_CLEAR_CART_STATE:
      return {
        ...state,
        totalAmount: 0,
        home: null,
        time: null,
        pickup: null,
        promo: null,
        error: null,
      }

    case actions.SET_PAST_CART:
      let updatedAmount = state?.totalAmount
      for (let index = 0; index < action?.payload?.length; index++) {
        updatedAmount += action.payload[index].price
      }
      localStorage.setItem("cart", JSON.stringify(action.payload))
      // localStorage.setItem("totalAmount", JSON.stringify(updatedAmount));
      return {
        ...state,
        totalAmount: updatedAmount,
        cartItems: action.payload,
      }
    case actions.SET_BAGS:
      let uupdatedAmount = state?.totalAmount
      for (let index = 0; index < action?.payload?.length; index++) {
        uupdatedAmount += +action.payload[index].price
      }
      let temp = action.payload.map((item, index) => {
        return {
          ...item,
          quantity: 1,
        }
      })
      localStorage.setItem("cart", JSON.stringify(temp))
      // localStorage.setItem("totalAmount", JSON.stringify(uupdatedAmount));
      return {
        ...state,
        totalAmount: uupdatedAmount,
        cartItems: temp,
      }

    // existingItemIndex = state.cartItems.findIndex(
    //   (item) => item.id === action.payload.id
    // );
    // existingItem = state.cartItems[existingItemIndex];
    // const pricee = +action.payload.price;
    // updatedTotalAmount = state.totalAmount + pricee;
    // if (updatedTotalAmount <= 0) {
    //   updatedTotalAmount = 0;
    //   return {
    //     ...state,
    //     totalAmount: 0,
    //     cartItems: [],
    //   };
    // }
    // if (existingItem) {
    //   updatedItem = {
    //     ...existingItem,
    //     price: +existingItem.price + pricee,
    //     quantity: existingItem.quantity + action.payload.quantity,
    //   };
    // } else {
    //   let item = {
    //     ...action.payload,
    //     quantity: action.payload.quantity,
    //     price: pricee,
    //   };
    //   return {
    //     ...state,
    //     totalAmount: updatedTotalAmount,
    //     cartItems: state.cartItems.concat(item),
    //   };
    // }

    case actions.SET_TIME:
      return {
        ...state,
        time: action.payload,
      }
    case actions.SET_TIME_PICKUP:
      return {
        ...state,
        pickup: action.payload,
      }
    case actions.SET_PROMO:
      return {
        ...state,
        promo: action.payload,
      }

    case actions.ERROR_PRODUCTS:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
