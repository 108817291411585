import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageEN from "./locale/en/en.translate.json";
import languageFN from "./locale/fn/fn.translate.json";
import languageDU from "./locale/du/du.translate.json";
import cookies from "js-cookie";

const lang =
  cookies.get("i18next") === "du"
    ? "du"
    : cookies.get("i18next") === "fn"
    ? "fn"
    : "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: languageEN },
      fn: { translation: languageFN },
      du: { translation: languageDU },
    },
    lng: lang,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
  });

export default i18n;
