import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Config from "./../../Config";
import $ from "jquery";
import { connect } from "react-redux";
import { setAddress, set_OfficeAddress } from "../../../store/Actions/authActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { async } from "q";
import EditOrder from "../Modals/EditOrder";
import ChangeAddress from "./changeAddressModal";
// import GoogleAutoComplete from "../Modals/NewAddress/AutoComplete";

class DeliveryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      client: null,
      accessToken: null,
      street: null,
      zip_code: null,
      loading: false,
      success: false,
      country: "Belgium",
      nr: null,
      comment: null,
      errMsg: null,
      city: null,
      error: false,
      errorMessage: null,
      loading: false,
      key: this.props.id
    };
  }
  componentDidUpdate() {
    // console.log(this.props?.sAddress, 'delivery')
  }
  componentDidMount() {
    // console.log('My LOve', this.props)
    // console.log({prev: this.props.history})
  }
  componentDidMount() {
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    this.setState({
      uid,
      client,
      accessToken,
    });
    //   if(this.props.location && this.props.location.state.edit)
    //   { 
    //        this.setState({
    //     street: this.props?.selectedAddress[0]?.street,
    //     nr: this.props?.selectedAddress[0]?.nr,
    //     zip_code: this.props?.selectedAddress[0]?.zip_code,
    //     country: this.props?.selectedAddress[0]?.country,
    //     city: this.props?.selectedAddress[0]?.city,
    //   })
    // }
    // console.log(this.props?.location.state.editable, 'editable') 
  }

  componentDidUpdate(prevProps) {
    if (this.props.location && this.props.location.state.edit) {
      if (this.props !== prevProps)
        this.setState({
          street: this.props?.location.state.editable?.street,
          nr: this.props?.location.state.editable?.nr,
          zip_code: this.props?.location.state.editable?.zip_code,
          country: this.props?.location.state.editable?.country,
          city: this.props?.location.state.editable?.city,
        })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEdit = async (id) => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/customer/delivery_addresses/${id}`,
      method: "PUT",
      data: {
        delivery_address: {
          street: this.state.street,
          zip_code: this.state.zip_code,
          country: this.state.country,
          nr: this.state.nr,
          comment: this.state.comment,
          city: this.state.city,
        },
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        this.props?.dispatch(setAddress());
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            success: true,
          };
        });
        this.props?.history.push('/home')
        // this.props.history.push(this.props.history.location && this.props.location.state?.myLove === "Pakistan" ? "/get-bags" : "/home", { myLove: this.props.location.state?.myLove });
        this?.props?.dispatch(set_OfficeAddress());
        this.props?.dispatch(setAddress())
      })
      .catch((err) => {
        this.setState((prev) => {
          return {
            ...prev,
            error: true,
            loading: false,
          };
        });
      });
  }

  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/customer/delivery_addresses`,
      method: "POST",
      data: {
        delivery_address: {
          street: this.state.street,
          zip_code: this.state.zip_code,
          country: this.state.country,
          nr: this.state.nr,
          comment: this.state.comment,
          city: this.state.city,
        },
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        this.props?.dispatch(setAddress());
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            success: true,
          };
        });
        this.props.history.push("/thank-you", {myLove : this.props.location.state?.myLove });
        // this.props.history.push(this.props.history.location && this.props.location.state?.myLove === "Pakistan" ? "/get-bags" : "/home", { myLove: this.props.location.state?.myLove });
        this?.props?.dispatch(set_OfficeAddress());
        this.props?.dispatch(setAddress())
      })
      .catch((err) => {
        this.setState((prev) => {
          return {
            ...prev,
            error: true,
            loading: false,
          };
        });
      });
  };

  handleDelete = async (id) => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      await Axios({
        method: "DELETE",
        url: `${Config.apiURL}/customer/delivery_addresses/${id}`,
        headers: {
          uid: `${this.state.uid}`,
          client: `${this.state.client}`,
          "access-token": `${this.state.accessToken}`,
        },
      });
      let current = this.props?.homeadd.filter((e) => e.id === id)
      if (current[0]?.is_default === true) {
        let remaining = this.props?.homeadd.filter((e) => e.id !== id)
        if (remaining?.length > 0) {
          this.handleDefaultAddress("DeliveryAddress", remaining[0]?.id)
          this.props?.dispatch(setAddress())
          this.props?.dispatch(set_OfficeAddress())
        }
        else {
          if (this.props?.offadd.length > 0) {
            this.handleDefaultAddress("CollectionPoint", this.props?.offadd[0]?.id)
            this.props?.dispatch(set_OfficeAddress())
            this.props?.dispatch(setAddress())
          }
        }
      }
      try {
        this.props?.dispatch(setAddress());
        this.props?.dispatch(set_OfficeAddress())
      } catch (error) {
      }
      this.props.history.push("/home")
      this.setState({
        loading: false
      })
    } catch (err) {
      this.setState({
        errorMessage: err.response.data.message.delivery_address[0],
        loading: false
      })
    }
  };

  handleDefaultAddress = async (type, e) => {
    let response = await Axios({
      url: `${Config.apiURL}/customer/default_addresses`,
      method: 'POST',
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
      data: {
        id: e,
        address_type: type
      },
    });
  };

  render() {
    const { t } = this.props
    const { edit } = this.props.location.state || {};
    const countryDisabled = true;
    const mystyle = {
      // backgroundColor: "transparent",
      // border: '0',
      // // border: 0,
      // margin: '20px 0'
    };
    return (
      <div>
        <div
          class={`modal ${this.state.error === true ? " d-block " : ""}`}
          id="deliverydetails"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog flex-column modal-dialog-centered  vh-90">
            <div class="modal-content bg-transparent mb-5 border-0 pb-5 screen_center ">
              <div class="modal-body rounded bg-light p-5">
                <p class="modal_style pb-5 mb-5">
                  Woops... It seems Wosh isn’t in your area yet...
                  <br />
                  <br />
                  Allow us to send you an email when we’re in your region
                </p>
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-5  text-center">
                    <button
                      onClick={() => {
                        $("#deliverydetails").removeClass("d-block");
                        this.setState((prev) => {
                          return {
                            ...prev,
                            error: false,
                          };
                        });
                      }}
                      class="rounded px-5 py-1 modal_btn_bordered"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                  <div class="col-md-5 text-center">
                    <button
                      class="rounded px-5 py-1 modal_btn_filled"
                      onClick={() => {
                        $("#deliverydetails").removeClass("d-block");
                        this.setState((prev) => {
                          return {
                            ...prev,
                            error: false,
                          };
                        });
                      }}
                    >
                      Yes
                    </button>
                  </div>
                  <div class="col-md-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Header history={this.props.history} /> */}
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        <div className="w-100" />
        <div className="container mb-5 z_index_one scroll_tab_content_new vh-90 d-flex flex-column justify-content-center">
          {
            this.props?.history?.location && this.props?.location?.state?.myLove === "Pakistan" ? <ProgressBar step={4} /> : null
          }
          {/* <ProgressBar step={4} /> */}
          <div className="row mt-5">
            <div className="second col-md-3 col-lg-3 col-xs-12 d-flex align-items-end"></div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="w-75 ms-auto me-auto login_main p-4">
                {this.state.errorMessage && <p style={{ color: "red" }}>
                  {this.state.errorMessage}
                </p>}
                <div className="d-flex flex-column main-title mb-5">
                  <h3 className="font_style">{t('Delivery Details')}</h3>
                  <div className="heading-line w-25" />
                </div>
                <div id="login_form">
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('Street')}
                    </label>
                    <img
                      src="/assets/imgs/locationBlue.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="street"
                      name="street"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.street}
                      aria-describedby="emailHelp"
                      placeholder="Rue de Loxum"
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('Nr')}
                    </label>
                    <img
                      src="/assets/imgs/nrBlue.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="number"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="nr"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.nr}
                      aria-describedby="emailHelp"
                      placeholder={35}
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('Zip code')}
                    </label>
                    <img
                      src="/assets/imgs/locationBlue1.png"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="zip_code"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.zip_code}
                      aria-describedby="emailHelp"
                      placeholder={1000}
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('city')}
                    </label>
                    <img
                      src="/assets/imgs/cityBlue.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="city"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.city}
                      aria-describedby="emailHelp"
                      placeholder="Brussels"
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('country')}
                    </label>
                    <img
                      src="/assets/imgs/cityBlue.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputPassword1"
                      name="country"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.country}
                      aria-describedby="emailHelp"
                      placeholder="Belgium"
                    // disabled={true}
                    />
                  </div>

                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t('comment on delivery')}
                    </label>
                    <img
                      src="/assets/imgs/commentBlue.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputPassword1"
                      name="comment"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder={t('Add instructions')}
                    />
                  </div>
                  {
                    // this.props?.history?.location && this.props?.location?.state?.edit === "editAddress" ?
                    this.props.location && this.props.location.state.edit ?
                      <button
                        onClick={() => {
                          this.handleDelete(this.props.location.state.editable?.id);
                        }}
                        id="btn-try-to-login"
                        className={`${!this.state.street ||
                          !this.state.city ||
                          !this.state.zip_code ||
                          !this.state.nr ||
                          !this.state.country
                          ? "w-100 btn fill_btn_del mt-5 ms-auto me-auto d-flex justify-content-center disabled"
                          : "w-100 btn fill_btn_del mt-5 ms-auto me-auto d-flex justify-content-center"
                          }`}
                      >
                        Delete Address
                      </button>
                      : null
                  }
                  {
                    this.props.location && this.props.location.state.edit ? 
                    (!this.state.success && !this.state.loading ? (
                      <button
                        onClick={() => {
                          this.props.location && this.props.location.state.edit ? this.handleEdit(this.props.location.state.editable?.id) :
                            this.handleSubmit()
                        }}
                        id="btn-try-to-login"
                        className={`${!this.state.street ||
                          !this.state.city ||
                          !this.state.zip_code ||
                          !this.state.nr ||
                          !this.state.country
                          ? "w-100 btn fill_btn mt-3 ms-auto me-auto d-flex justify-content-center disabled"
                          : "w-100 btn fill_btn mt-3 ms-auto me-auto d-flex justify-content-center"
                          }`}
                      >
                        {t('Continue')}
                      </button>
                    ) : this.state.loading && !this.state.success ? (
                      <button
                        onClick={() => {
                          this.handleSubmit();
                        }}
                        id="btn-try-to-login"
                        className="w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center disabled"
                      >
                        {t('processing')}
                      </button>
                    ) : null) : <button
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    id="btn-try-to-login"
                    className="w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center"
                  >
                    {t('Continue')}
                  </button>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xs-12" />
          </div>
          <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
            <Link to= {this.props.location && this.props.location.state.edit ? "/home" : "/use-wosh"}  className="text-decoration-none small">
              <FontAwesomeIcon icon={faArrowLeft} /> {t('Back')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    homeadd: state?.auth?.homeAddress,
    offadd: state?.auth?.officeAddress,
    err: state?.auth?.error,
    authUser: state?.auth?.user,
    selectedAddress: state?.auth?.selectedAddress
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(DeliveryDetail);