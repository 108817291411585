import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Header";
import { clearCart } from "../../../../store/Actions/cartActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
const PaymentSuccess = (props) => {
  useEffect(() => {
    props?.dispatch(clearCart());
  }, []);
  const { t } = useTranslation()
  return (
    <div>
      <div className="bottom_right">
        <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
      </div>
      <div className="middle_left">
        <img src="/assets/imgs/bubble.png" />
      </div>
      {/* <Header history={props.history} /> */}
      <div className="container mt-4 d-flex flex-column justify-content-center align-items-start w_50 vh-90 mx-auto px-5">
        <h2 className="theme_color font_style">
          {t('Thank you! Your order was successful!')}
        </h2>
        <div className="heading-line mb-3" />
        {/* </div> */}
        <p className="theme_color"> {t("Thank you for choosing Wosh, your trusted on-demand laundry delivery platform! We want to express our heartfelt appreciation for your decision to rely on us for your laundry needs.")}</p>
        <div className="w-75 mx-auto">
          <img
            src="/assets/imgs/order_confirm_pic.png"
            className="mt-2 img-fluid"
            width="auto"
            height={300}
            alt=""
          />
        </div>
        <div className="w-100 text-center mt-2">
          <Link to="/order" className="w-50 ms-auto me-auto d-flex justify-content-center regular_font btn fill_btn mt-4">
            {t('track your order')}
          </Link>
        </div>
      </div>

      {/* <div className="container mt-4 d-flex flex-column justify-content-center align-items-center">
        <div className="w_min_content">
          <h1 className="text-white text-center">
            {t('Thank you, Your order has been successfully completed')}
          </h1>
          <div className="heading-line w-25" />
        </div>
        <img
          src="/assets/imgs/registration-wosh-img.png"
          className="mt-5"
          width="auto"
          height={150}
          alt=""
        />
        <div className="w-100 mt-5 text-center">
          <Link to="/order" className="btn btn-custom-register">
            {t('track your order')}
          </Link>
        </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  totalAmount: state.cart.totalAmount,
  address: state.cart.home,
  deliveryAddress: state.auth.homeAddress,
});
export default connect(mapStateToProps)(PaymentSuccess);
