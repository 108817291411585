import React from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../Header/user.css";
import { withTranslation } from "react-i18next";
import StripeModal from "../StripeModal/index.js";
import WalletStepII from "../WoshPayement/Step2/index.js";
class ChooseMethod extends React.Component {
  constructor() {
    super();
    this.state = {
      flow: "",
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div id="darkautofill">
        <div
          className="modal fade"
          id="chooseMethod"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                <FontAwesomeIcon
                  // onClick={props.onPress}
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
                />
              </div>

              <div className="modal-body rounded_25px bg-light p-4" style={{ border: '8px solid #3D4586' }}>
                  <h5 className="theme_color fw-bold align-self-center text-center fw_semibold">
                    {t("Select your preferred option")}
                  </h5>
                <div className="row">
                  <div className="col-md-5 d-flex flex-column align-items-center mt-4">
                    <h4 className="theme_color fw-bold">{t("Credit card")}</h4>
                    <button
                      onClick={() => {
                        this.setState({ flow: "card" });
                      }}
                      // data-bs-toggle="modal"
                      className={
                        this.state.flow && this.state.flow === "card"
                          ? "wallet_credit_card w-100 btn rounded p-3 mt-4 max_height outline-0 shadow_green"
                          : "wallet_credit_card w-100 btn rounded p-3 mt-4 max_height shadow-none"
                      }
                    >
                      <img
                        src="/assets/imgs/debit-cards.png"
                        className="h-100 img-fluid"
                      />
                    </button>
                    <small
                      className="text-center text-small mt-3"
                      style={{ color: "#3d4586" }}
                    >
                      Mastercard or Visa
                    </small>
                  </div>
                  <div className="col-md-2 px-2 d-flex flex-column align-items-center justify-content-end">
                    <div className="vl vl_2"></div>
                  </div>
                  <div className="col-md-5 d-flex flex-column align-items-center mt-4">
                    <h4 className="theme_color fw-bold">{t("Debit card")}</h4>
                    <button
                      onClick={() => {
                        this.setState({ flow: "wallet" });
                      }}
                      // data-bs-toggle="modal"
                      className={
                        this.state.flow && this.state.flow === "wallet"
                          ? "wallet w-100 btn text-white rounded p-4 mt-4  max_height outline-0 shadow_green border-0"
                          : "wallet w-100 btn text-white rounded p-4 mt-4  max_height shadow-none border-0"
                      }
                    >
                      <span>WoshWallet</span>
                    </button>
                    <small
                      className="text-center text-small mt-3"
                      style={{ color: "#3d4586" }}
                    >
                      Bancontact
                    </small>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <button
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    data-bs-toggle="modal"
                    data-bs-target={
                      this.state.flow === "wallet"
                        ? "#payment_modalII"
                        : "#stripeModal"
                    }
                    className="
                  btn
                  bg-btn-add-color
                  rounded-pill
                  theme_bg
                  text-white
                  w-50
                  h-100
                  fs-6
                "
                  >
                    {t("Add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WalletStepII amount={this.props?.wallet} />
        <StripeModal />
      </div>
    );
  }
}

export default withTranslation()(ChooseMethod);
