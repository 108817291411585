import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Config from "./../../../Config";
import Axios from "axios";
import ChangePassword from "../EditProfile/ChangePass";
import { connect } from "react-redux";
import {
  forceRender,
  setCurrentUser,
} from "../../../../store/Actions/authActions";

import { ToastsContainer, ToastsStore } from "react-toasts";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
class ChangePass extends React.Component {
  constructor() {
    super();
    this.state = {
      uid: null,
      client: null,
      accessToken: null,
      firstName: null,
      msg: "",
      lastName: null,
      loading: null,
      success: null,
      phoneNumber: null,
    };
  }
  componentDidMount() {
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    // const uid = JSON.parse(localStorage.getItem("user_uid"));
    // const client = JSON.parse(localStorage.getItem("user_client"));
    // const accessToken = JSON.parse(localStorage.getItem("user_access-token"));

    this.setState({
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phoneNumber: this.props.phoneNumber,
      uid,
      client,
      accessToken,
    });
  }
  handleChange = (event) => {
    this.setState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  toast = () => {
    if (this.state.msg === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (this.state.msg === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  handleValidate = async () => {
    if (this.state.msg === "okay") {
      ToastsStore.info("Profile has been successfully updated");
    } else {
      ToastsStore.error(
        "Profile could not be updated, try again with proper details"
      );
    }
  };
  handleSubmit = async () => {
   
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.authURL}/auth`,
      method: "PUT",
      data: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone_number: this.state.phoneNumber,
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        // console.log("response from profile change", response);
        const user = {
          user_uid: response.headers.uid,
          user_access_token: response.headers["access-token"],
          user_client: response.headers.client,
          user_id: response.data.data.id,
          user_name: response.data.data.first_name,
        };
        // console.log({ response });
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            success: true,
            msg: "okay",
          };
        });
        this.handleValidate();
        // this.props?.dispatch(setCurrentUser(user));
        this.props?.dispatch(
          forceRender(
            this.props?.renderCheck !== 0 ? this?.props?.renderCheck + 1 : 1
          )
        );
      })
      .catch((error) => {
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            msg: "error",
          };
        });
        this.handleValidate();
      });
  };
  render() {
    const { t } = this.props;
    return (
      <div id="darkautofill">
        {this.toast()}
        <div
          className="modal fade"
          id="changepass"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">
                  {t("edit")} {t("profile")}
                </h6>
                <FontAwesomeIcon
                  // onClick={props.onPress}
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>

              <div className="modal-body rounded_25px bg-light p-4">
                <div className="w-100 d-flex flex-column">
                  <h5 className="theme_color fw-bold">
                    {" "}
                    {this.props.firstName && this.props.firstName}{" "}
                    {this.props.lastName && this.props.lastName}{" "}
                  </h5>
                  <div className="heading-line_color"></div>
                </div>
                <div id="login_form" className="w-100 d-flex flex-column mt-5">
                  <div className="position-relative mb-4 d-flex flex-row py-1 align-items-center modal_btn_bordered px15_radius px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {t("First Name")}
                    </label>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                      <input
                        type="text"
                        className="form-control bg-transparent border-0"
                        id="firstName"
                        name="firstName"
                        onKeyUp={(event) => {
                          this.handleChange(event);
                        }}
                        aria-describedby="emailHelp"
                        placeholder=""
                        defaultValue={this.state.firstName}
                      />
                  </div>
                  <div className="position-relative mb-4 d-flex flex-row py-1 align-items-center modal_btn_bordered px15_radius px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {t("Last Name")}
                    </label>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0"
                      id="lastName"
                      name="lastName"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder=""
                      defaultValue={this.state.lastName}
                    />
                  </div>
                  <div className="mb-1 position-relative modal_btn_bordered px15_radius d-flex flex-row py-1 align-items-center px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {t("phone")}
                    </label>
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0"
                      id="exampleInputPassword1"
                      name="phoneNumber"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      placeholder=""
                      defaultValue={this.state.phoneNumber}
                    />
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target={"#changepassword"}
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    // type="submit"
                    class="w-100 bg-transparent modal_btn_bordered p-2 rounded-pill theme_color mt-4 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center"
                  >
                    {t("change")} {t("Password")}
                  </button>
                  {!this.state.success && !this.state.loading ? (
                    <button
                      onClick={() => {
                        this.handleSubmit();
                      }}
                      className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                    >
                      {t("Confirm")}
                    </button>
                  ) : this.state.loading && !this.state.success ? (
                    <button className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled">
                      {t("processing")}
                    </button>
                  ) : (
                    <button
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                      className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center "
                    >
                      {t("done")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePassword />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    renderCheck: state?.auth?.render,
    authUser: state?.auth?.user,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(ChangePass);
