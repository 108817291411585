import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WalletStepII from "../WoshPayement/Step2";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setPaymentMethod } from "../../../../store/Actions/authActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class WoshWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: null,
    };
  }
  componentDidMount() {
    this.setState({ total: this.props?.total });
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <div
          className="modal fade"
          id="wosh_wallet"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                <FontAwesomeIcon
                  onclick="removeblur()"
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4 d-flex flex-column align-items-center" style={{ border: '8px solid #3D4586' }}>
                <h4 className="theme_color fw-bold align-self-center text-center fw_semibold mb-4">Wosh Wallet</h4>
                <p className="fw-bold m-0 theme_color theme_green_bg white rounded-pill px-4 py-2 w-auto">
                  {this.props.amount?.replace(".", ",")} €
                </p>
                <p className="m-0 mb-3 small fw_semibold theme_color">{t("Available funds")}</p>
                {this.props?.total > this.props?.amount ? (
                  <p className="small text-center">
                    {t(
                      "Sorry you have insufficient credit in your wallet, please add credit to continue or choose another payment method."
                    )}
                  </p>
                ) : (
                  <p className="small text-center">
                    {t(
                      "To proceed with your order, kindly proceed by selecting the 'Select Wallet' option. FurtherMore, you have the option to add extra credits to your account for added convenience"
                    )}
                  </p>
                )}
                <button
                  // onclick="modalblur()"
                  data-bs-toggle="modal"
                  data-bs-target="#payment_modalII"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  type="submit"
                  className="w-100 fw_medium btn theme_2pxborder theme_color bg-transparent rounded-pill px-2 mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                >
                  {t("Add money")}
                </button>
                {this.props?.amount &&
                this.props?.total < this.props?.amount && (
                  <button
                    onClick={() => {
                      this.props.dispatch(setPaymentMethod("Wallet"));
                    }}
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    className="w-100 theme_bg white modal_btn_bordered p-2 rounded-pill mt-2 mb-2 ms-auto me-auto d-flex justify-content-center align-items-center"
                  >
                    {t("Select Wallet")}
                  </button>
                ) }
              </div>
            </div>
          </div>
        </div>
        <WalletStepII />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
export default compose(withTranslation(), connect(mapStateToProps))(WoshWallet);
