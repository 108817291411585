import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../Config";
import Axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class ChangePassword extends React.Component {
  componentDidMount() {}
  constructor() {
    super();
    this.state = {
      uid: this.props?.authUser?.user_uid,
      client: this.props?.authUser?.user_client,
      accessToken: this.props?.authUser?.user_access_token,
      // uid: JSON.parse(localStorage.getItem("user_uid")),
      // client: JSON.parse(localStorage.getItem("user_client")),
      // accessToken: JSON.parse(localStorage.getItem("user_access-token")),
      oldPassword: null,
      passwordShown: false,
      newPassword: null,
      confirmPassword: null,
      msg: "",
      success: false,
      loading: false,
      text: "",
      error: null,
    };
  }

  togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };

  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const headers = {
      uid: this.props?.authUser?.user_uid,
      client: this.props?.authUser?.user_client,
      "access-token": this.props?.authUser?.user_access_token,
    };
    // console.log({ headers });
    var postData = {
      current_password: this.state.oldPassword,
      password_confirmation: this.state.confirmPassword,
      password: this.state.newPassword,
    };
    let response;
    try {
      response = await Axios({
        url: `${Config.authURL}/auth`,
        method: "put",
        headers: headers,
        data: postData,
      });
      // console.log(response);
      this.setState((prev) => {
        return {
          ...prev,
          loading: false,
          success: true,
          msg: "okay",
        };
      });
      this.handleValidate();
      // this.props.history.push("/profile");
    } catch (error) {
      // console.log(error.response);

      this.setState((prev) => {
        return {
          ...prev,
          loading: false,
          msg: "error",
          text: error.response.data.errors.full_messages,
        };
      });
      this.handleValidate();
    }
  };
  toast = () => {
    if (this.state.msg === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (this.state.msg === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  handleValidate = async () => {
    if (this.state.msg === "okay") {
      ToastsStore.info("Password has been successfully updated");
    } else {
      ToastsStore.error(this.state.text);
    }
  };
  handleChange = (event) => {
    if (event.target.name === "confirmPassword") {
      if (event.target.value === this.state.newPassword) {
        this.setState((prev) => {
          return {
            ...prev,
            error: null,
            [event.target.name]: event.target.value,
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            error: "Password must match",
          };
        });
      }
    } else {
      this.setState((prev) => {
        return {
          ...prev,
          error: null,
          [event.target.name]: event.target.value,
        };
      });
    }
  };
  render() {
    return (
      <div>
        {this.toast()}
        <div
          className="modal fade"
          id="changepassword"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{this.props.t('change')} {this.props.t('Password')}</h6>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>

              <div className="modal-body rounded_25px bg-light p-4">
                <div className="w-100 d-flex flex-column">
                  <h5 className="theme_color fw-bold">{this.props.t('Create new password')}</h5>
                  <div className="heading-line_color"></div>
                </div>
                <div id="login_form" className="w-100 d-flex flex-column mt-5">
                  {this.state.error !== null ? (
                    <p className="color-red"> {this.state.error} </p>
                  ) : null}
                  <div className="position-relative mb-4 d-flex flex-row py-1 align-items-center modal_btn_bordered px15_radius px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {this.props.t('old')} {this.props.t('Password')}
                    </label>
                    <FontAwesomeIcon
                      icon={faKey}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      className="form-control bg-transparent border-0"
                      name="oldPassword"
                      id="newpass"
                      // value={this.state.oldPassword}
                      onChange={this.handleChange}
                      aria-describedby="emailHelp"
                      placeholder=".........................."
                    />
                    <div className="hide_show_btn">
                      <img
                        id="eye_img"
                        src="/assets/imgs/eye-solid.svg"
                        width={20}
                        alt="Show/Hide Password"
                        onClick={this.togglePassword}
                      />
                    </div>
                  </div>
                  <div className="position-relative mb-4 d-flex flex-row py-1 align-items-center modal_btn_bordered px15_radius px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {this.props.t('new')} {this.props.t('Password')}
                    </label>
                    <FontAwesomeIcon
                      icon={faKey}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      className="form-control bg-transparent border-0"
                      // value={this.state.newPassword}
                      onChange={this.handleChange}
                      id="newpass"
                      aria-describedby="emailHelp"
                      name="newPassword"
                      placeholder=".........................."
                    />
                    <div className="hide_show_btn">
                      <img
                        id="eye_img"
                        src="/assets/imgs/eye-solid.svg"
                        width={20}
                        alt="Show/Hide Password"
                        onClick={this.togglePassword}
                      />
                    </div>
                  </div>
                  <div className="mb-1 position-relative modal_btn_bordered px15_radius d-flex flex-row py-1 align-items-center px-3">
                    <label className="position-absolute ms-2 px-3 bg-light theme_color lable_style">
                      {this.props.t('Confirm')} {this.props.t('Password')}
                    </label>
                    <FontAwesomeIcon
                      icon={faKey}
                      className="theme_color fs-6 cursor_pointer"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    />
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      // value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      className="form-control bg-transparent border-0"
                      name="confirmPassword"
                      id="confirmpass"
                      placeholder=".........................."
                    />
                    <div className="hide_show_btn">
                      <img
                        id="eye_img"
                        src="/assets/imgs/eye-solid.svg"
                        width={20}
                        alt="Show/Hide Password"
                        onClick={this.togglePassword}
                      />
                    </div>
                  </div>
                  {!this.state.success &&
                  !this.state.loading &&
                  !this.state.error ? (
                    <button
                      onClick={() => this.handleSubmit()}
                      className={
                        this.state.oldPassword === null ||
                        this.state.newPassword === null ||
                        this.state.confirmPassword === null ||
                        this.state.error
                          ? "w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                          : "w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                      }
                    >
                      {this.props.t('Confirm')}
                    </button>
                  ) : this.state.loading && !this.state.success ? (
                    <button className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled">
                      {this.props.t('processing')}
                    </button>
                  ) : (
                    <button
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                      className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(ChangePassword);
