import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { applyMiddleware, createStore } from "redux"
import { persistStore } from "redux-persist"
import { composeWithDevTools } from "redux-devtools-extension"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import rootReducer from "./store/Reducers/index"
import { Elements } from "@stripe/react-stripe-js"
import { PersistGate } from "redux-persist/integration/react"
import { loadStripe } from "@stripe/stripe-js"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import { GoogleOAuthProvider } from "@react-oauth/google"

const key = process.env.REACT_APP_WOSH_STRIPE_PK && process.env.REACT_APP_WOSH_STRIPE_PK
// const key = process.env.REACT_APP_WOSH_STRIPE_PK
//   ? process.env.REACT_APP_WOSH_STRIPE_PK
//   : "pk_test_51MRsC1Am2xVgd2kpxBN0bWju25i6KOBHssFvB4S2q2evt613e1pqN9x3A8zJphFp6xYJVvVFmFyye8EpNn1vd0CB00TXnF3ENW"
const stripePromise = loadStripe(key)

export const store =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
    : createStore(rootReducer, applyMiddleware(thunk))

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <React.StrictMode>
        <PersistGate persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_WOSH_GOOGLE_CLIENT_ID}
            >
              <App />
            </GoogleOAuthProvider>
          </I18nextProvider>
        </PersistGate>
      </React.StrictMode>
    </Elements>
  </Provider>,
  document.getElementById("root")
)
