import React from 'react'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation()
    return (
        <div className="bg-transparent z_index">
            <div className="row py-3 bg-transparent">
                <div className="col-md-6">
                    <h5 className="theme_color">{t("Download our mobile app")}</h5>
                    <div className="d-flex">
                        <a target="_blank" href="https://apps.apple.com/be/app/wosh/id1612458076?l=en">
                            <img src="../assets/imgs/appStore.png" alt="" style={{ width: "100px" }} className="me-2" />
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.woshuser">
                            <img src="../assets/imgs/googlePlay.png" alt="" style={{ width: "100px" }} />
                        </a>
                    </div>
                </div>
                <div className="col-md-6">
                    <h5 className="theme_color">{t("Follow us")}</h5>
                    <div className="d-flex">
                        <a target="_blank" href="https://www.instagram.com/wosh.be/">
                            <img src="../assets/imgs/instagram (2) 2.png" alt="" style={{ width: "30px", height: '30px' }} className="me-3" />
                        </a>
                        <a target="_blank" href="https://www.facebook.com/wosh.be">
                            <img src="../assets/imgs/facebook (2) 2.png" alt="" style={{ width: "30px", height: '30px' }} className="me-3" />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/wosh-be">
                            <img src="../assets/imgs/linkedin (2) 2.png" alt="" style={{ width: "30px", height: '30px' }} />
                        </a>
                    </div>
                </div>
                <div className="col-md-12"><hr className="my-4" /></div>
                <p className="theme_color">
                    {t("All rights reserved. © WOSH 2023. By visiting this page you agree to our ")} <a target="_blank" className='theme_green_color' href="https://www.wosh.be/legal/privacy-policy">
                        {t("privacy policy")}
                    </a> {t("and")} <a target="_blank" className='theme_green_color' href="https://www.wosh.be/legal/terms-conditions">
                        {t("terms and conditions")}.
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Footer