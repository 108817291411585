import React from "react";
import { connect } from "react-redux";
import InnerTab from "../../InnerTab/index";
import ItemList from "../ItemList/index";
import { useTranslation } from "react-i18next";

export const FoldedTab = (props) => {
  const {t} = useTranslation();
  return (
    <>
      <div
        className="tab-pane fade show "
        id="folded"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <InnerTab />
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="body-laundry"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <p className="theme_text theme_color mt-4">
              {t('Ideal for specific body & home washables. Professionally ironed and neatly folded.')}{" "}
            </p>
            <ItemList cat={"folded"} sub={"bodylaundry"} />
          </div>
          <div
            className="tab-pane fade"
            id="home-laundry"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <p className="theme_text theme_color mt-4">
              {t('Ideal for specific body & home washables. Professionally ironed and neatly folded.')}{" "}
            </p>
            <ItemList cat={"folded"} sub={"homelaundry"} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);
