import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Axios from "axios";
import Config from "../../../Config";
import { ToastsContainer, ToastsStore } from "react-toasts";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import {
  forceRender,
  setAddress,
  set_OfficeAddress,
    setSelectedAddress

} from "../../../../store/Actions/authActions";
import {
  selectAddress,
} from "../../../../store/Actions/cartActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class EditAddressModal extends React.Component {
  constructor() {
    super();
    this.state = {
      uid: null,
      client: null,
      search: null,
      id:null,
      searchBool: false,
      point: [],
      city: null,
      street: null,
      country: null,
      zipCode: null,
      nr: null,
      comments: null,
      accessToken: null,
      user_id: null,
      companies: [],
      buildings: [],
      err: "",
      building_id: null,
      company_id: [],
      point_id: [],
      error: false,
      success: false,
      index: null,
      loading: false,
      post: false,
    };
  }
  componentDidMount() {
    // const uid = JSON.parse(localStorage.getItem("user_uid"));
    // const client = JSON.parse(localStorage.getItem("user_client"));
    // const accessToken = JSON.parse(localStorage.getItem("user_access-token"));
    // const user_id = JSON.parse(localStorage.getItem("user_id"));
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    const user_id = this.props?.authUser?.user_id;
    this.setState({
      uid,
      client,
      accessToken,
      user_id,
    });
  }
  toast = () => {
    if (this.state.err === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (this.state.err === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  handleChange = (event) => {
    event.target.value === ""
      ? this.setState({ [event.target.name]: null })
      : this.setState({
          [event.target.name]: event.target.value,
        });
  };
  handleAdd = async () => {
    this.setState({ post: true });
    await Axios({
      url: `${Config.apiURL}/customer/delivery_addresses`,
      method: "POST",
      data: {
        delivery_address: {
          user_id: this.state.user_id,
          street: this.state.street,
          zip_code: this.state.zipCode,
          country: this.state.country,
          nr: this.state.nr,
          comment: this.state.comments,
          city: this.state.city,
        },
      },

      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {

        this.setState({
          err: "okay",
          error: false,
          post: false,
          success: true,
          id:response?.data?.id,
        });

        ToastsStore.success("Address has been added");
        // console.log("address response", response.data);
      })
      .catch((error) => {
        this.setState({
          err: "error",
          error: false,
          post: false,
        });
        ToastsStore.error("You might have entered wrong zip code");
        // console.log({ error });
      });
  };
  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        searchBool: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/collection_points`,
      method: "GET",
      params: { q: this.state.search },

      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        // this.props.history.push("/get-bags")
        this.setState((prev) => {
          return {
            ...prev,
            searchBool: false,
            point: response.data.collection_points,
          };
        });
        // ToastsStore.success("Address has been added");

        // console.log("collection response", this.state.point);
      })
      .catch((error) => {
        this.setState((prev) => {
          return {
            ...prev,
            searchBool: false,
          };
        });
      });
  };
  handleHa = async () => {
    this.setState({ loading: true });
    const company_id = this.state.point.filter(
      (item) => item.id === this.state.index
    );
    const point_id = this.state.point.filter(
      (item) => item.id === this.state.index
    );

    // console.log("cid", company_id[0]?.companies[0]?.id);
    // console.log("pid", point_id[0]?.id);
    await Axios({
      url: `${Config.apiURL}/customer/collection_points`,
      method: "POST",
      data: {
        collection_point: {
          collection_point_id: point_id[0]?.id,
        },
        company_id: company_id[0]?.companies[0]?.id,
      },
      headers: {
        uid: `${this.state?.uid}`,
        client: `${this.state?.client}`,
        "access-token": `${this.state?.accessToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        this.setState({ loading: false, err: "okay", error: false });
        this.props?.dispatch(set_OfficeAddress());
        this.props?.dispatch(setAddress())
        ToastsStore.success("Collection point has been added");

        // console.log("success response", response);
      })
      .catch((error) => {
        this.setState({ loading: false, err: "error" });
        ToastsStore.error("Error adding collection point");
      });
  };
  collectionPoints = () => {
    return this.state.point.length <= 0
      ? null
      : this.state.point.map((item, index) => {
          return (
            <>
              <div
                id="haha"
                onClick={() => {
                  this.setState({ index: item?.id, error: true });
                }}
                className="item"
                data-bs-dismiss="modal"
                aria-hidden="true"
                key={index}
              >
                <div className="search_option p-2 mb-3">
                  <h3 className="text-left">
                    {item?.companies.map((name) => name.company_name + "")}
                  </h3>
                  <p className="text-left m-0">{item?.address}</p>
                </div>
              </div>
            </>
          );
        });
  };
  render() {
    const { t } = this.props;
    return (
      <div id="darkautofill">
        {this.toast()}
        <div
          className={`modal ${this.state.error === true ? " d-block " : ""}`}
          id="confirmConnect"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered  vh-90 ">
            <div className="modal-content bg-transparent mb-5 border-0 pb-5 screen_center">
              <div className="modal-body rounded bg-light p-5">
                <p className="modal_style pb-5 mb-5">
                  {t(
                    "Are you sure you want to connect with this collection point ?"
                  )}
                </p>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5  text-center">
                    <button
                      className="rounded px-5 py-1 modal_btn_bordered"
                      onClick={() => this.setState({ error: false })}
                    >
                      {t("no")}
                    </button>
                  </div>
                  <div className="col-md-5 text-center">
                    <button
                      className={`rounded px-5 py-1 modal_btn_filled ${
                        this.state.loading ? "disabled" : ""
                      }`}
                      onClick={this.handleHa}
                    >
                      {this.state.loading
                        ? `${t("processing")}`
                        : `${t("yes")}`}
                    </button>
                  </div>
                  {/* <div className="col-md-5 text-center">
                      <button
                        className="rounded px-5 py-1 modal_btn_filled"
                        onClick={() => this.setState({ error: false })}
                      >
                        Close
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{t("New address")}</h6>
                <FontAwesomeIcon
                  onclick="removeblur()"
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4">
                <div className="w-100 d-flex">
                  <ul
                    className="nav nav-tabs profile_tabs border-bottom-0"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link border-0 bg-transparent p-0 me-4 h-100 active theme_color"
                        data-bs-toggle="tab"
                        href="#home-address"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {t("Home")}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link border-0 bg-transparent p-0 me-4 h-100 theme_color"
                        data-bs-toggle="tab"
                        href="#office-address"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Office
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mx-auto tab-content pt-4" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-address"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={t("city")}
                      name="city"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />

                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={t("country")}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      name="country"
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder={t("Street")}
                          name="street"
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="border-bottom form-control bg-transparent border-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder={`${t("Nr")}*`}
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                          name="nr"
                        />
                      </div>
                    </div>
                    <input
                      type="number"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder={t("Zip code")}
                      name="zipCode"
                    />
                    <input
                      type="text"
                      className="border-bottom form-control bg-transparent border-0 my-3"
                      id="exampleInputEmail1"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Comments"
                      name="comments"
                    />

                    {!this.state.post && !this.state.success ? (
                      <button
                        type="submit"
                        className={
                          this.state.city === null ||
                          this.state.country === null ||
                          this.state.street === null ||
                          this.state.nr === null ||
                          this.state.zipCode === null
                            ? "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                            : "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                        }
                        onClick={this.handleAdd}
                      >
                        {t("Confirm")}
                      </button>
                    ) : !this.state.success && this.state.post ? (
                      <button
                        type="submit"
                        className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                      >
                        {t("processing")}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.props?.dispatch(setAddress()).then(() => {
                            this.props.dispatch(selectAddress("home"))
                            this.props.dispatch(setSelectedAddress(this.state.id, "home"))
                          }).catch()


                        }}
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                        className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center "
                      >
                        {t("done")}
                      </button>
                    )}
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="office-address"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="my-3 w-100">
                      <div className="d-flex flex-row">
                        <input
                          type="email"
                          className="border-bottom form-control bg-transparent border-0 w-75"
                          id="search"
                          name="search"
                          aria-describedby="emailHelp"
                          placeholder="Name company / building / residence"
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                        />
                        <button
                          class="px-3 btn fill_btn"
                          type="submit"
                          className={`px-3 btn fill_btn ${
                            this.state.search === null || this.state.searchBool
                              ? "disabled"
                              : ""
                          }`}
                          onClick={this.handleSubmit}
                        >
                          {!this.state.searchBool
                            ? `${t("search")}`
                            : `${t("processing")}`}
                        </button>
                      </div>
                      <div className="w-100 text-end small pe-3 mt-2">
                        {`${
                          this.state.point.length > 0 &&
                          this.state.point.length < 1
                            ? this.state.point.length + `${t("result")}`
                            : ""
                        }`}
                      </div>
                    </div>
                    <Link className="fw-bold theme_color textdecoration_none">
                      {t("unable to connect?")}
                    </Link>
                    <div className="w-100 my-5">
                      <Link
                        className="theme_color"
                        data-bs-dismiss="modal"
                        // aria-hidden="true"
                      >
                        {t("Add new collection point")}
                      </Link>
                    </div>
                    {this.state.point && this.state.point.length > 0 ? (
                      <OwlCarousel
                        items={1}
                        id="address_result"
                        className="owl-theme mx-auto"
                        loop
                        margin={10}
                        nav
                      >
                        {this.collectionPoints()}
                        {/* <div class="item">
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                        <div className="search_option p-2 mb-3">
                          <h3 className="text-left">Wosh</h3>
                          <p className="text-left m-0">
                            Chaussée de la hulpe 150
                          </p>
                        </div>
                      </div> */}
                      </OwlCarousel>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    renderCheck: state?.auth?.render,
    authUser: state?.auth?.user,
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(EditAddressModal);
