import React, { useState } from 'react'
import Header from '../Header'
import axios from "axios";
import Config from "../../Config";
import { connect } from "react-redux";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const Index = (props) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(props?.referralCode?.referral_code);
    const [copied, setCopied] = useState(false);
    const [code, setCode] = useState('')
    const [res, setRes] = useState(null)
    const [success, setSuccess] = useState(false)
    const getFriendsCode = async () => {
        const uid = props?.authUser?.user_uid;
        const client = props?.authUser?.user_client;
        const accessToken = props?.authUser?.user_access_token;
        try {
            let response = await axios({
                url: `${Config.apiURL}/customer/referrals`,
                method: "POST",
                headers: {
                    uid: `${uid}`,
                    client: `${client}`,
                    "access-token": `${accessToken}`,
                },
                data: {
                    referral_code: code,
                }
            })
            setSuccess(true)
            
        } catch (error) {
            setRes(error.response.data.message)
        }

    }
    const onClick = (({ target: { value } }) => {
        return value
        // console.log(`Clicked on "${value}"!`);
    })
    const onCopy = (() => {
        setCopied(true);
        setValue(value)
    })

    setTimeout(() => {
        setCopied(false)
        // setRes(false)
    }, 2000)

    return (
        <div /*className={this.state.blur ? "modal_main" : null}*/>
            <div className="top_right">
                <img src="/assets/imgs/bubble_3.png" />
            </div>
            <div className="rectangle_top_left">
                <img src="/assets/imgs/Rectangle_top.png" />
            </div>
            <div className="bottom_right">
                <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
            </div>
            <div className="middle_left_top_content_white">
                <img src="/assets/imgs/bubble_white.png" />
            </div>
            <div className="circle_right_middle_dot">
                <img src="/assets/imgs/circle_top.png" />
            </div>
            {/* <Header isNav={true} /> */}
            <div className='scroll_tab_content_new'>
                <div className='py-4 w_50 mx-auto responsive_mobile_earn'>
                    <h4 className='text-center my-4 theme_color font_style'>{t("Earn Wosh Credits")}</h4>
                    <div className='border_earn_credit rounded mt-5 w-75 mx-auto position-relative'>
                        <h5 className='text-center theme_color fw-bold regular_font'>{t("Win")} 15€</h5>
                        <p className="theme_color my-4 text-center regular_font">{t("You can add credit to your wosh wallet using bancontact, apple pay or by asking nicely to your mam or manager.")}</p>
                        <h6 className='theme_color fw-bold regular_font my-3'>{t("By sharing your code!")}</h6>
                        <div className='text-center theme_color position_style position-absolute translate-middle'>{copied ? 'Copied' : null}</div>
                        <div className="w-100 d-flex flex-sm-row flex-column mb-4 woshwallet_border">
                            <h5 className="w_75 btn payment_btn theme_color d-flex justify-content-center flex-row align-items-center px-lg-5 px-md-3 px-5 shadow-none outline-0 me-2 mb-sm-0 mb-2 fw-bold regular_font mb-0">
                                {value}
                            </h5>
                            <CopyToClipboard
                                onCopy={onCopy}
                                onClick={onClick}
                                text={value}
                            >
                                <button
                                    className={
                                        "w_25 btn border-0 woshwallet_new_2 d-flex justify-content-center align-items-center py-2"
                                    }
                                >
                                    <img src="./assets/imgs/copy_ico.png" width={26} height={26} />
                                </button>
                            </CopyToClipboard>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center my-4">
                            <div className="heading-line w-100" />
                            <p className="m-0 mx-3 theme_color text-uppercase regular_font">or</p>
                            <div className="heading-line w-100" />
                        </div>
                        <h6 className='theme_color fw-bold regular_font my-3'>{t("By entering a friend's code")}</h6>
                        <div className="w-100 d-flex flex-sm-row flex-column woshwallet_border">
                            <input type="text" className='w_75 btn payment_btn theme_color d-flex justify-content-center flex-row align-items-center px-lg-5 px-md-3 px-5 shadow-none outline-0 me-2 mb-sm-0 mb-2 fw-bold mb-0 theme_color on_focus' placeholder={t("Enter your friend's code")} onChange={(e) => setCode(e.target.value) || setRes(null)} />
                            <button
                                onClick={() => getFriendsCode()}
                                className={
                                    "w_25 btn border-0 woshwallet_new_2 d-flex justify-content-center align-items-center py-2 white"
                                }
                            >
                                {t("check")}
                            </button>
                        </div>
                        <div className='mt-2 '>{success ? <p className='text-info '>{t("Congrats! Both you and your friend got 15$ of additional credit in your WOSH wallet !")}</p>
                         : res === 'Credited' ? <p className='text-success small'>{res} Successfully!</p>
                          : res === res ? <p className='text-danger small'>{res}</p> 
                          : ''}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    authUser: state?.auth?.user,
    referralCode: state?.auth?.user,
});
export default connect(mapStateToProps)(Index);