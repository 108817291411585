import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "../../Checkout/StripeForm/style.css";
import Config from "../../../Config";
import React, { useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import moment from "moment";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { useTranslation } from "react-i18next";
import {
  get_paymentId,
  setPaymentCard,
  setPaymentMethod,
} from "../../../../store/Actions/authActions";
import * as methods from "../../../../store/Actions/authActions";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#3D4586",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#FCE883",
      },
      "::placeholder": {
        color: "#00000080",
      },
    },
    invalid: {
      iconColor: "red",
      color: "#red",
    },
  },
};
const CardField = ({ onChange }) => (
  <div className="FormRow border-bottom border-top-0">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);
const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  maxLength,
  pattern,
}) => (
  <div className="FormRow border-bottom border-top-0">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput StripeFormInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      pattern={pattern}
    />
  </div>
);
const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton shadow-none w-50 mastercard_bg btn theme_bg white rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center ${
      error ? "SubmitButton--error checkout_btn" : ""
    }`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);
const ErrorMessage = ({ children }) => (
  <div
    className="ErrorMessage w-100 d-flex"
    style={{ color: "#000", left: "0" }}
    role="alert"
  >
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772E5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);
const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);
const StripeModal = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [err, setErr] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [success, setSuccess] = useState(false);
  const [responseMsg, setResponseMsg] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const toast = () => {
    if (err) {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  const handleValidate = (msg, bool) => {
    if (bool) {
      ToastsStore.error(msg);
    } else {
      ToastsStore.info("Your Card has been saved successfully");
    }
  };
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (error) {
      elements.getElement("card").focus();
      return;
    }
    if (cardComplete) {
      setProcessing(true);
    }
    const now = new Date();
    let temp = {
      name: billingDetails?.name,
      // address_country: "Pakistan",
      // phone: "03403028867",
    };
    const card = elements.getElement(CardElement);
    const payload = await stripe.createToken(card, temp);
    if (payload.error) {
      setError(payload.error);
    } else {
      const user = {
        uid: props?.authUser?.user_uid,
        client: props?.authUser?.user_client,
        access_token: props?.authUser?.user_access_token,
      };
      try {
        let response = await Axios({
          url: `${Config.apiURL}/customer/payment_methods`,
          method: "POST",
          data: { 
            // token: "tok_visa"
            token: payload.token.id },
          headers: {
            uid: props?.authUser?.user_uid,
            client: props?.authUser?.user_client,
            "access-token": props?.authUser?.user_access_token,
          },
        });
        if (props?.cartItems?.length > 0) {
          props.dispatch(setPaymentCard(response?.data?.payment_method?.id));
          props.dispatch(methods.setPaymentMethod("Debit Card"));
        }
        setErr(true);
        setSuccess(true);
        handleValidate("success", false);
      } catch (err) {
        setErr(true);
        handleValidate(
          err.response?.data?.message?.payment_method
            ? err.response?.data?.message?.payment_method
            : err.response?.data?.errors
            ? err.response?.data?.errors
            : "Somewthing went wrong, please try again later.",
          true
        );
        // console.log(err);
        // alert("Error : ", `${err.response.message}`);
      }
    }
    // pass the token to your backend API
    setProcessing(false);
    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
  };
  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    });
  };
  return (
    <>
      {toast()}
      <div
        className="modal fade"
        id="stripeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                //   onClick={props.onPress}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <div className="w-100" id="stripe_form">
                <form className="Form rounded_25px" onSubmit={handleSubmit} style={{ border: '8px solid #3D4586' }}>
                  <h5 className="theme_color fw-bold align-self-center text-center fw_semibold">
                    {t("Fill the details")}
                  </h5>
                  <fieldset className="FormGroup mx-0 shadow-none">
                    <Field
                      label={t("name")}
                      id="name"
                      type="text"
                      placeholder="Barry White"
                      required
                      autoComplete="name"
                      value={billingDetails.name}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                    {/* <Field
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="janedoe@gmail.com"
                        required
                        autoComplete="email"
                        value={billingDetails.email}
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            email: e.target.value,
                          });
                        }}
                      /> */}
                    <Field
                      label={t("Phone")}
                      id="phone"
                      type="tel"
                      placeholder="02 430 25 96"
                      maxLength={11}
                      pattern="[+-]?\d+(?:[.,]\d+)?"
                      autoComplete="tel"
                      required
                      value={billingDetails.phone}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          phone: e.target.value,
                        });
                      }}
                    />
                  </fieldset>
                  <fieldset className="FormGroup mx-0 shadow-none">
                    <CardField
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </fieldset>
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  {!success ? (
                    <SubmitButton
                      processing={processing}
                      error={error}
                      disabled={!stripe}
                    >
                      {t("Add card")}
                    </SubmitButton>
                  ) : (
                    <button
                      className="SubmitButton shadow-none w-50 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                      onClick={() => {
                        props.dispatch(get_paymentId());
                        elements.getElement("card").clear();
                        setErr(null);
                        setError(null);
                        setCardComplete(false);
                        setProcessing(false);
                        setPaymentMethod(null);
                        setSuccess(false);
                        setResponseMsg(null);
                        setBillingDetails({ email: "", phone: "", name: "" });
                      }}
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                    >
                      {t("Continue")}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  paymentId: state?.auth?.paymentId,
  authUser: state?.auth?.user,
  cartItems: state?.cart?.cartItems,
});
export default connect(mapStateToProps)(StripeModal);
