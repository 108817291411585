import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import "../Header/user.css";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Config from "../../Config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastsContainer, ToastsStore } from "react-toasts";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { set_OfficeAddress } from "../../../store/Actions/authActions";

class ProfilePoint extends React.Component {
  constructor() {
    super();
    this.state = {
      prev: window.location.pathname,
      uid: null,
      client: null,
      search: null,
      point: null,
      accessToken: null,
      zero: false,
      companies: [],
      buildings: [],
      searching: false,
      hover: false,
      err: "",
      building_id: null,
      company_id: [],
      point_id: [],
      error: false,
      index: null,
      loading: false,
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    };
  }
  async componentDidMount() {
    localStorage.setItem("path", JSON.stringify(this.state.prev));
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    this.setState({
      uid,
      client,
      accessToken,
    });
  }

  toast = () => {
    if (this.state.err === "okay") {
      return <ToastsContainer store={ToastsStore} />;
    } else if (this.state.err === "error") {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };

  handleAlert = (
    alert,
    alertType,
    alertMainHeading,
    alertHeading,
    alertMessage
  ) => {
    this.setState({
      alert,
      alertType,
      alertMainHeading,
      alertHeading,
      alertMessage,
    });
    setTimeout(() => {
      this.setState({
        alert: false,
        alertMainHeading: null,
        alertHeading: null,
        alertMessage: null,
      });
    }, 2000);
  };

  handleHa = async () => {
    this.setState({ loading: true });
    const company_id = this.state.point.filter(
      (item) => item.id === this.state.index
    );
    const point_id = this.state.point.filter(
      (item) => item.id === this.state.index
    );

    await Axios({
      url: `${Config.apiURL}/customer/collection_points`,
      method: "POST",
      data: {
        collection_point: {
          collection_point_id: point_id[0]?.id,
        },
        company_id: company_id[0]?.companies[0]?.id,
      },
      headers: {
        uid: `${this.state?.uid}`,
        client: `${this.state?.client}`,
        "access-token": `${this.state?.accessToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        this.props?.dispatch(set_OfficeAddress());
        this.setState({ loading: false, err: "okay", error: false });
        ToastsStore.success("Collection point has been added");

        this.props?.history?.push("/profile");
      })
      .catch((error) => {
        this.setState({ loading: false, err: "error" });
        ToastsStore.error("Error adding collection point");
      });
  };

  hoverTrue = () => {
    this.setState({
      hover: true
    }
    )
  }
  hoverFalse = () => {
    this.setState({
      hover: false
    }
    )
  }

  collectionPoints = () => {
    return this.state.point.length <= 0
      ? null
      : this.state.point.map((item, index) => {
        return (
          <>
            {this.toast()}
            <div id="haha"
              onClick={() => {
                this.setState({ index: item?.id, error: true });
              }}
              className={`item ${this.state.point.length < 2
                  ? "w-100 mx-auto"
                  : this.state.point.length === 2
                    ? "w-75 mx-auto"
                    : ""
                }`}
              key={index}
            >
              <div className="search_option p-2">

                {item?.companies.map((name) => {
                  return <h3 className="text-left" >{name?.company_name}</h3>;
                })}
                <br />
                <p className="text-left m-0" >{item?.street}</p>
                <p className="text-left m-0" >{item?.city}, {item?.zip}</p>
              </div>
            </div>
          </>
        );
      });
  };
  handleChange = (event) => {
    event.target.value === ""
      ? this.setState({ [event.target.name]: null })
      : this.setState({
        [event.target.name]: event.target.value,
      });
  };

  show = () => {
    return this.state?.point?.length > 0 ? (
      <>
        <h3 className="mainheading m-0 mb-4">
          {this.state.point.length === 1
            ? this.state.point.length + ` ${this.props.t("result found")}`
            : this.state.point.length + ` ${this.props.t("result found")}`}
        </h3>
        <OwlCarousel
          items={this.state.point.length <= 2 ? this.state.point.length : 3}
          responsive={this.state.responsive}
          className="owl-theme w-75 mx-auto"
          // loop
          margin={10}
          nav
        >
          {this.collectionPoints()}
        </OwlCarousel>
      </>
    ) : (
      <h3 className="mainheading m-0 mb-4">
        {this.props.t("No collection point found")}
      </h3>
    );
  };
  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        searching: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/collection_points`,
      method: "GET",
      params: { q: this.state.search },

      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        const temp = response?.data?.collection_points?.filter(
          (point) => point?.companies?.length > 0
        );
        this.setState({
          point: temp,
          searching: false,
        });
      })
      .catch((error) => {
        this.setState({
          searching: false,
          zero: true,
        });
      });
  };

  render() {
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        <div>
          <div
            className={`modal ${this.state.error === true ? " d-block " : ""}`}
            id="confirmConnect"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog flex-column modal-dialog-centered  vh-90 ">
              <div className="modal-content bg-transparent mb-5 border-0 pb-5 screen_center">
                <div className="modal-body rounded bg-light p-5">
                  <p className="modal_style pb-5 mb-5">
                    {this.props.t(
                      "Are you sure you want to connect with this collection point ?"
                    )}
                  </p>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5  text-center">
                      <button
                        className="rounded px-5 py-1 modal_btn_bordered"
                        onClick={() => this.setState({ error: false })}
                      >
                        {this.props.t("no")}
                      </button>
                    </div>
                    <div className="col-md-5 text-center">
                      <button
                        className={`rounded px-5 py-1 modal_btn_filled ${this.state.loading ? "disabled" : ""
                          }`}
                        onClick={this.handleHa}
                      >
                        {this.state.loading
                          ? `${this.props.t("processing")}`
                          : `${this.props.t("yes")}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Header history={this.props.history} /> */}
        <div className="container mb-5 z_index_one">
          <ProgressBar step={4} />
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xs-12"></div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="w-85 ms-auto me-auto login_main p-4 flex-column align-items-center">
                <div className="d-flex flex-column main-title mb-5">
                  <h3>{this.props.t("connect to collection point")}</h3>
                  <div className="w-25 heading-line" />
                </div>
                <div id="login_form" className="mb-3 w-100">
                  <div className="position-relative mb-4 form_input_style d-flex flex-row ps-3 align-items-center">
                    <label className="position-absolute px-3 bg-light theme_color lable_style">
                      {this.props.t("connect")}
                    </label>
                    <img
                      src="/assets/imgs/company_name.PNG"
                      className="height_16px"
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 white"
                      name="search"
                      placeholder="company, building, or residence name here"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                    <button
                      className={`btn fill_btn btn_connect ${this.state.searching || this.state.search === null
                          ? "disabled"
                          : ""
                        }`}
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      {this.state.searching
                        ? `${this.props.t("processing")}`
                        : `${this.props.t("search")}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xs-12" />
            {this?.state?.point ? (
              this.show()
            ) : (
              <h5 className="white text-center">
                {this.props.t("search for collection point")}
              </h5>
            )}

            <div className="d-flex flex-column footer mt-5 pt-5">
              <h3 className="text-center">
                {this.props.t("unable to connect?")}
              </h3>
              <p className="text-center">
                <a
                  style={{ color: "white" }}
                  href={"https://www.wosh.be/contact"}
                  target={"_blank"}
                  rel={"noreferrer noopener"}
                >
                  {this.props.t("contact us")}
                </a>
                <br />
                <Link
                  to="/add-colection-point"
                  className="text-decoration-none small"
                  style={{ color: "white" }}
                >
                  {this.props.t("Add new collection point")}
                </Link>
              </p>
            </div>
          </div>
          <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
            <Link to="/profile" className="text-decoration-none small" href>
              <FontAwesomeIcon icon={faArrowLeft} /> {this.props.t("Back")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(ProfilePoint);
