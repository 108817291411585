import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import $ from "jquery"
import { useTranslation } from "react-i18next"
import cookies from "js-cookie"
const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
const Unsubscribe = () => {
  const history = useHistory()
  const [reason, setReason] = useState(null)
  const query = useQuery()
  const { t } = useTranslation()
  useEffect(() => {
    $("#app-container").addClass("body")
  }, [])
  return (
    <div className="unsub">
      <div className="top_right">
        <img src="/assets/imgs/bubble_3.png" />
      </div>
      <div className="bottom_right">
        <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
      </div>
      <div className="middle_left">
        <img src="/assets/imgs/bubble.png" />
      </div>
      <div className="p-4 wosh_img">
        <img src={"/assets/imgs/wosh-logo-colorfull.PNG"} />
      </div>
      <div className="container m_t">
        <div className="d-flex align-items-center flex-column">
          <div className="unsub_content text-center">
            <h1>{t("unsubscribe")}</h1>
            <p>
              {t("sorry to see you unsub")}
              {t("tell us more")}
            </p>
          </div>
          <div className="list_content">
            <form>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={"I'm receiving too many emails"}
                    className="me-3"
                    name="text"
                    onChange={(e) => {
                      setReason(e.target.value)
                    }}
                  />
                  {t("option1")}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={"The information is not interesting"}
                    className="me-3"
                    name="text"
                    onChange={(e) => {
                      setReason(e.target.value)
                    }}
                  />
                  {t("option2")}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={"I don't want to use your service any longer"}
                    className="me-3"
                    name="text"
                    onChange={(e) => {
                      setReason(e.target.value)
                    }}
                  />
                  {t("option3")}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={"Other"}
                    className="me-3"
                    name="text"
                    onChange={(e) => {
                      setReason(e.target.value)
                    }}
                  />
                  {t("option4")}
                </label>
              </div>
            </form>
            <button
              className="btn btn_unsub"
              onClick={(e) => {
                e.preventDefault()
                axios({
                  url: `${process.env.REACT_APP_AUTH_URL}/api/v1/customer/unsubscriptions`,
                  method: "POST",
                  data: {
                    unsubscription_token: query?.get("tok"),
                    unsubscription_reason: reason,
                  },
                })
                  .then(() => {
                    history.push("/")
                  })
                  .catch(() => {
                    history.push("/")
                  })
              }}
            >
              {t("unsubscribe")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Unsubscribe
