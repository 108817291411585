import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./error.css";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center h-100 vh-90">
          <img
            alt=""
            src="../assets/imgs/logo.png"
            width={200}
            className="login_logo mb-4"
          />
          <div className="box_404 w-40 text-center p-5">
            <h2 className="white">404</h2>
            <p className="white">Page not found</p>
            <p className="white">
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <Link to="/" className="theme_bg_btn btn rounded-pill px-4 py-2">
              Go back to homepage.
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default NotFound;
