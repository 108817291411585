import React from "react";
import { connect } from "react-redux";
import ItemList from "../ItemList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import KiloModal from "../../../Modals/KiloModal";
import { useTranslation } from "react-i18next";

export const KiloTab = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="tab-pane fade reponsive_set pb-4"
        id="kilo"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <ul className="nav nav-tabs second_tabs mb-4" id="myTab" role="tablist">
          <li className="nav-item kilo_tab_shadow" role="presentation">
            <a
              className="nav-link p-3 h-100 active kilo_tab_shadow"
              id="home-tab"
              data-bs-toggle="tab"
              href="#per_kilo"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <div className="d-flex flex-row align-items-center">
                <img src="/assets/imgs/Per-Kilo-laundry-icon.png" width={40} alt="" />
                <div className="ms-3">
                  <h3 className="fs-5 fw-bold mb-0">{t('Per kilo laundry')}</h3>
                  <p className="small mb-0">{t('Washed, iron & folded')}</p>
                </div>

              </div>
            </a>
          </li>
          {/* <li class="nav-item" role="presentation">
                  <a class="nav-link p-3 h-100" id="profile-tab" data-bs-toggle="tab" href="#home-laundry" role="tab" aria-controls="profile" aria-selected="false">
                    <div class="d-flex flex-row align-items-center mb-1">
                      <img src="../assets/imgs/home_laundry.png" width="30">
                      <h3 class="fs-5 mb-0">Home laundry</h3>
                    </div>
                    <p class="small mb-0">Returned wash, dry, iron & fold.</p>
                  </a>
                </li> */}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="per_kilo"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <p className="theme_text theme_color">
              {t("A fitting package for all your washables. Professionally ironed and neatly folded.")}
            </p>
            {/* <p className="theme_text">
              <div className="info_bg rounded me-2">
                <FontAwesomeIcon
                  icon={faInfo}
                  className="small"
                  aria-hidden="true"
                />
              </div>
              <a
                data-bs-toggle="modal"
                data-bs-target="#kiloModal"
                className="text-decoration-underline cursor_pointer theme_color"
              >
                {t("What's included & how to estimate my laundry weight ?")}
              </a>
            </p> */}
            <div className="d-flex justify-content-between align-items-start">
              <ItemList cat={"kilo"} />
              <div className="card_description kilo_tab_shadow mt-3">
                <ul class="nav nav-pills mb-3 justify-content-around" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active color_text" id="whats_included-tab" data-bs-toggle="pill" data-bs-target="#whats_included" type="button" role="tab" aria-controls="whats_included" aria-selected="true">{t("What's included?")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link color_text" id="how-to-estimate-tab" data-bs-toggle="pill" data-bs-target="#how-to-estimate" type="button" role="tab" aria-controls="how-to-estimate" aria-selected="false">{t("How to estimate?")}</button>
                  </li>
                </ul>
                <div class="tab-content p-4 fw-bold" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="whats_included" role="tabpanel" aria-labelledby="whats_included-tab">{t("Our service accepts everyday casual and home items such as jeans, t-shirts, sweaters, underwear, sportswear, bed sheets, pillow covers, towels, and more. If we find delicate items like shirts or dresses in your kilo bag, we will handle them with extra care. However, please note that we will charge separately for these items.")}</div>
                  <div class="tab-pane fade" id="how-to-estimate" role="tabpanel" aria-labelledby="how-to-estimate-tab">{t("* Unsure about weight? We've got covered! Trust our Knowledgeable experts to determine the weight for you and change the appropriate fee")}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="tab-pane fade"
            id="home-laundry"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <ItemList cat={"kilo"} />
            .2..
          </div> */}
        </div>
      </div>
      <KiloModal />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KiloTab);
