import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";

  import React, { useEffect } from 'react'
  import { AuthedRoute, PrivateRoute } from "../utils";
  import Header from "./../components/User/Header"
import Home from "../components/User/Home";
import Profile from "../components/User/Profile";
import PaymentMethod from "../components/User/PaymentMethod";
import ToppedUp from "../components/User/ToppedUp";
import Order from "../components/User/Order";
import EarnWoshCredits from "../components/User/EarnWoshCredits";
import Checkout from "../components/User/Checkout";
import NotFound from "../components/Error404Screen";
import { connect } from "react-redux";
import SignIn from "../components/User/SignIn";
import LaundryPartner from "../components/User/LaundryPartner";

   const WhiteListRoutes = (props) => {
    const user_uid = props?.isAuthenticated ? true : false;
    useEffect(()=>{
  const {hAddress, oAddress} = props
    },[])
    return (
      <>
        {<Header isNav={true} />}

        <PrivateRoute exact path="/" component={Home} authed={user_uid} />
        <PrivateRoute authed={user_uid} path="/profile" component={Profile} />
        <PrivateRoute
          authed={user_uid}
          path="/paymentMethod"
          component={PaymentMethod}
        />
        <PrivateRoute authed={user_uid} path="/order" component={Order} />
        <PrivateRoute authed={user_uid} path="/home" exact component={Home} />
        <PrivateRoute
          authed={user_uid}
          path="/earn-wosh-credit"
          exact
          component={EarnWoshCredits}
        />
        <PrivateRoute authed={user_uid} path="/laundry_partner" component={LaundryPartner} />
        <PrivateRoute authed={user_uid} path="/checkout" component={Checkout} />
      </>
    )
  }

  const mapStateToProps = (state) => ({
    isAuthenticated: state?.auth?.isAuthenticated,
    newUser: state?.auth?.user,
    hAddress: state?.auth?.homeAddress,
    oAddress: state?.auth?.officeAddress,
  });

  export default connect(mapStateToProps)(WhiteListRoutes)
