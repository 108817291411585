import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import { PrivateRoute, AuthedRoute } from '../../utils';
import SignIn from '../../components/User/SignIn';
import OnSuccess from '../../components/User/ResetPassword/OnSuccess';
import Email from '../../components/User/ResetPassword/Email/Email';
import Unsubscribe from '../../components/subscribtion/Unsubscribe';
import StripePaymentSuccess from '../../components/stripePaymentSuccess';
import UseWash from '../../components/User/UseWash';
import ResetPassword from '../../components/User/ResetPassword';
import PersonalDetail from '../../components/User/PersonalDetail';
import paymentSuccess from '../../components/User/ThankYou/paymentSuccess';
import ConnectPoint from '../../components/User/ConnectPoint';
import ProfilePoint from '../../components/User/ConnectPoint/ProfilePoint';
import AddColectionPoint from '../../components/User/AddColectionPoint';
import GetBages from '../../components/User/GetBages';
import ThankYou from '../../components/User/ThankYou';
import { connect } from 'react-redux';
import DeliveryDetail from '../../components/User/DeliveryDetail';
import ToppedUp from '../../components/User/ToppedUp';
import NotFound from '../../components/Error404Screen';
import NewUserPassword from '../../components/User/NewUserPassword';

const BlackListRoutes = (props) => {
    const user_uid = props?.isAuthenticated ? true : false;
  return (<>
        
        <Route
          exact
          path="/mail-check/:email"
          render={(props) => <OnSuccess {...props} />}
        />
        <Route
          exact
          path="/forgot-password"
          render={(props) => <Email {...props} />}
        />
        <Route
          exact
          path="/unsub"
          render={(props) => <Unsubscribe {...props} />}
        />
        <PrivateRoute
         authed={user_uid}
         path="/wallet-success"
         component={ToppedUp}
       />
          <Route
            path="/stripe-payment-success"
            render={(props) => <StripePaymentSuccess {...props} />}
          />
          <PrivateRoute
            authed={user_uid}
            path={"/use-wosh"}
            exact
            component={UseWash}
          />
          <Route
            path="/reset-password/"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/personal-details"
            render={(props) => <PersonalDetail {...props} />}
          />
          <Route
            path="/new-user-password"
            render={(props) => <NewUserPassword {...props} />}
          />
        <PrivateRoute
          authed={user_uid}
          path="/delivery-details"
          exact
          component={DeliveryDetail}
        />
        <PrivateRoute
          authed={user_uid}
          path="/purchase-success"
          exact
          component={paymentSuccess
        }
        />

        {/* <Route path="/connect-point" exact component={ConnectPoint} /> */}
        <PrivateRoute
          authed={user_uid}
          path="/connect-point"
          exact
          component={ConnectPoint}
        />
        <PrivateRoute
          authed={user_uid}
          path="/connectpoint"
          exact
          component={ProfilePoint}
        />
        <PrivateRoute
          authed={user_uid}
          path="/add-colection-point"
          exact
          component={AddColectionPoint}
        />
        <Route path="/get-bags" exact component={GetBages} />
        <PrivateRoute
          authed={user_uid}
          path="/thank-you"
          exact
          component={ThankYou}
        />
       {/* <Route component={NotFound} /> */}
        </>
  )
}
const mapStateToProps = (state) => ({
    isAuthenticated: state?.auth?.isAuthenticated,
    newUser: state?.auth?.user,
    hAddress: state?.auth?.homeAddress,
    oAddress: state?.auth?.officeAddress,
  });

export default connect(mapStateToProps)(BlackListRoutes)