import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SignIn from "./components/User/SignIn";
import UseWash from "./components/User/UseWash";
import DeliveryDetail from "./components/User/DeliveryDetail";
import PersonalDetail from "./components/User/PersonalDetail";
import ConnectPoint from "./components/User/ConnectPoint";
import AddColectionPoint from "./components/User/AddColectionPoint";
import GetBags from "./components/User/GetBages";
import EarnWoshCredits from "./components/User/EarnWoshCredits";
import ThankYou from "./components/User/ThankYou";
import NotFound from "./components/Error404Screen";
import Profile from "../src/components/User/Profile";
import Order from "../src/components/User/Order";
import Home from "../src/components/User/Home";
import Checkout from "../src/components/User/Checkout";
import ResetPassword from "./components/User/ResetPassword";
import Email from "../src/components/User/ResetPassword/Email/Email";
import { connect } from "react-redux";
import { fetchCartItem, getProducts } from "./store/Actions/cartActions";
import PaymentMethod from "../src/components/User/PaymentMethod"
import {
  setAddress,
  set_OfficeAddress,
  get_paymentId,
  setCurrentLocale,
} from "./store/Actions/authActions";
import OnSuccess from "./components/User/ResetPassword/OnSuccess";
import PaymentSuccess from "./components/User/ThankYou/paymentSuccess";
import ProfilePoint from "./components/User/ConnectPoint/ProfilePoint";
import cookies from "js-cookie";
import ToppedUp from "./components/User/ToppedUp";
import { store } from "./index";
import Unsubscribe from "./components/subscribtion/Unsubscribe";
import StripePaymentSuccess from "./components/stripePaymentSuccess";
import Header from "./components/User/Header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BlackListRoutes from "./routes/AuthRoutes";
import WhiteListRoutes  from "./routes";

function App({ ...props }) {
  React.useEffect(()=>{
    cookies.set("laundryOnFoldedCloseStatus",'false');
    cookies.set("laundryOnHangerCloseStatus",'false');
  },[])


  React.useMemo(() => {}, []);
  const {hAddress, oAddress} = props
  useEffect(() => {
    if (props?.isAuthenticated === true) {
      // debugger
      // props
      //   .dispatch(getProducts())
      //   .then(() => props?.dispatch(fetchCartItem()));
      props.dispatch(get_paymentId());
      props.dispatch(
        setCurrentLocale({
          ...props?.newUser,
          user_locale:
            cookies.get("i18next") === "du"
              ? "du"
              : cookies.get("i18next") === "fn"
              ? "fn"
              : "en",
        })
      );
    }
  }, [props?.isAuthenticated]);

  // const check = store?.getState()?.cart?.cartItems?.length > 0 ? true : false;
  function PrivateCheckout({ component: Component, check, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          store?.getState()?.cart?.cartItems?.length > 0 ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/home", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  const user_uid = props?.isAuthenticated ? true : false;
  function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>

          authed === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  function AuthedRoute({ component: Component, authed, ...rest }) {
    if (props?.newUser.new_user) {
      return (
        <Route
          {...rest}
          render={(props) =>
            authed === false ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/use-wosh", state: { from: props.location } }}
              />
            )
          }
        />
      );
    }
     else {
      return (
        <Route
          {...rest}
          render={(props) =>
            authed === false ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: hAddress?.length > 0 || oAddress?.length > 0 ? "/home" :"/use-wosh", state: { from: props.location } }}
              />

            )
          }
        />
      );
    }
  }

  const history = useHistory()

  const whiteList = [
    "/home",
    "/checkout",
    "/profile",
    "/paymentMethod",
    "/order",
    "/earn-wosh-credit",
    "/laundry_partner",
    "/",
  ]
  const blacklist = [
    "/personal-details",
    "/use-wosh",
    "/connect-point",
    "/delivery-details",
    "/add-colection-point",
    "/thank-you",
    "/stripe-payment-success",
    "/mail-check/:email",
    "/forgot-password",
    "/unsub",
    "/wallet-success",
    "/reset-password/",
    "/purchase-success",
    "/get-bags",
    "/new-user-password",
  ]

  return (
    <>
    <Router>
         {/* {window.location.pathname === "/home" && <Header isNav={true} />} */}
      <Switch>
      <AuthedRoute
          authed={user_uid}
          path="/sign-in"
          exact
          component={SignIn}
        />
        <Route path={blacklist} render={(props) => <BlackListRoutes {...props}/>}  />
        <Route path={whiteList} render={(props) => <WhiteListRoutes {...props}/>}  />
        {/* <Route component={NotFound} /> */}

      </Switch>
    </Router>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state?.auth?.isAuthenticated,
  newUser: state?.auth?.user,
  hAddress: state?.auth?.homeAddress,
  oAddress: state?.auth?.officeAddress,
});

export default connect(mapStateToProps)(App);
