import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Config from "./../../Config";
import cookies from "js-cookie";
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux";
import { connect } from "react-redux"
import { compose } from "redux"
import { setAllClear } from "../../../store/Actions/authActions";
import { clearCart, setClearCartState } from "../../../store/Actions/cartActions";

const lang = [
  { id: 1, title: "English", icon: "../assets/imgs/UK-flag.png" },
  { id: 2, title: "Français", icon: "../assets/imgs/FR-flag.png" },
  { id: 3, title: "Nederlands", icon: "../assets/imgs/NL-flag.png" },
];


class NewUserPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isFirstTimeToken: true,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      isOpen: false,
      termsConditions: false,
      passwordConfirmation: null,
      passwordShown: false,
      passwordConfirmShown: false,
      passwordConfirmationShown: false,
      error: "",
      errors: {
        emal: null,
        password: null,
      },
      haveText:
        cookies.get("i18next") === "fn"
          ? "Français"
          : cookies.get("i18next") === "du"
            ? "Nederlands"
            : cookies.get("i18next") === "en"
              ? "English"
              : "",
      selectedCountryIcon: cookies.get("i18next") === "fn"
        ? "../assets/imgs/FR-flag.png"
        : cookies.get("i18next") === "du"
          ? "../assets/imgs/NL-flag.png"
          : cookies.get("i18next") === "en"
            ? '../assets/imgs/UK-flag.png'
            : "../assets/imgs/UK-flag.png"
    };
  }

  async componentDidMount() {

    const url = new URL(window.location.href)
    const token = url.searchParams.get("token")
    localStorage.clear()
    this.props.dispatch(setAllClear())
    this.props.dispatch(setClearCartState())
    this.props.dispatch(clearCart())
    console.log("Token from URL:", token)
    this.setState({ ...this.state, token })
    await Axios.get(`${Config.apiURL}/customer/valid_token?token=${token}`, Config.headers ).then(()=>{

    }).catch(()=>{

      this.props.history.push("/sign-in")
    })


  }

  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = null;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };

  toggleConfirmPassword = () => {
    this.setState({ passwordConfirmShown: !this.state.passwordConfirmShown });
  }

  handleSubmit = () => {
    const header = new Headers();
    header["Access-Control-Allow-Origin"] = "*";
    Axios.put(
      `${Config.apiURL}/customer/update_password`,
      {
        password: this.state.password,
        password_confirmation: this.state.passwordConfirmation,
        token: this.state.token,
      },
      Config.headers
    )
      .then((response) => {
        this.props.history.push("/sign-in");
      })
      .catch((error) => {
        if (error?.response?.data) {
          this.setState({
            error: error.response.data.message
              ? error.response.data.message
              : "Server is not responding please try later",
            loading: false,
          });
        } else {
          this.setState({
            error: "Network error, please try again later.",
          });
        }
      });
  };

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  itemList = (props) => {
    const list = props.map((item) => (
      <div
        onClick={this.handleText}
        // className="dropdown__item"
        className="dropdown__item d-flex justify-content-between px-4"
        key={item.toString()}
      >
        {item.title}
        <img src={item.icon} style={{ width: "30px", height: "20px" }} />
        {/* {item} */}
      </div>
    ));

    return <div className="dropdown__items"> {list} </div>;
  };

  handleText = (ev) => {
    this.setState({
      // haveText: ev.currentTarget.textContent,
      haveText: ev.title,
      selectedCountryIcon: ev.icon,
    });
    cookies.set(
      "i18next",
      ev.currentTarget.textContent === "English"
        ? "en"
        : ev.currentTarget.textContent === "Français"
          ? "fn"
          : "du"
    );
    window.location.reload();
  };

  render() {
    const { t } = this.props;
    const { isOpen, haveText } = this.state;
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        <div className="w-100 logo">
          <Link to="/home">
            <img
              src="../assets/imgs/logoDark.png"
              width={150}
              className="login_logo img-fluid"
              alt=""
            />
          </Link>
        </div>
        <div className="w-100" />
        <div
          className={
            isOpen
              ? "dropdown position-absolute cursor_pointer active"
              : "dropdown position-absolute cursor_pointer"
          }
          onClick={this.handleClick}
        >
          <div className="cursor_pointer d-flex justify-content-around align-items-center py-2">
            <h5 className="mb-0 theme_color">
              {!haveText ? "English" : haveText}
            </h5>
            <img
              src={this.state.selectedCountryIcon}
              style={{ width: "30px", height: "20px" }}
            />
          </div>
          {this.itemList(lang)}
        </div>
        <div className="container">
          <div className="w_35per m-auto login_main centered">
            <div className="w-75 m-auto login_main d-flex flex-column align-items-center">
              <div className="d-flex flex-column theme_color font_style mb-5">
                <h3>Enter new Password</h3>
                <div className="heading-line w-25" />
              </div>
              {this.state.error ? (
                <p className="color-red">{this.state.error}</p>
              ) : null}
              <div id="login_form" className="pb-5 w-100 mt-5">
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 white theme_bg lable_style">
                    Choose Password
                  </label>
                  <FontAwesomeIcon icon={faLock} />
                  <input
                    type={this.state.passwordShown ? "text" : "password"}
                    className="form-control bg-transparent border-0 theme_color"
                    id="newUserPassword"
                    aria-describedby="passwordHelp"
                    placeholder="Password"
                    onChange={this.handleChange}
                    name="password"
                  />
                  <div className="hide_show_btn">
                    <img
                      id="eye_img"
                      src="/assets/imgs/eye_pass_black.png"
                      width={20}
                      alt="Show/Hide Password"
                      onClick={this.togglePassword}
                    />
                  </div>
                </div>
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 white theme_bg lable_style">
                    Confirm Password
                  </label>
                  <FontAwesomeIcon icon={faLock} />
                  <input
                    type={this.state.passwordConfirmShown ? "text" : "password"}
                    className="form-control bg-transparent border-0 theme_color"
                    id="newUserConfirmPassword"
                    aria-describedby="confirmPasswordHelp"
                    placeholder="Confirm Password"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    name="passwordConfirmation"
                  />
                  <div className="hide_show_btn">
                    <img
                      id="eye_img"
                      src="/assets/imgs/eye_pass_black.png"
                      width={20}
                      alt="Show/Hide Password"
                      onClick={this.toggleConfirmPassword}
                    />
                  </div>
                </div>
                <button
                  disabled={!this.state.password || !this.state.passwordConfirmation}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  className="w-100 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

export default compose(withTranslation(), connect(mapStateToProps))(NewUserPassword);
