import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Config from "./../../../../Config";
import { Link } from "react-router-dom";
import Axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  forceRender,
  setAddress,
} from "../../../../../store/Actions/authActions";

class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      client: null,
      accessToken: null,
      id: null,
      street: null,
      nr: null,
      success: false,
      loading: false,
      err: false,
      city: null,
      country: null,
      zipCode: null,
    };
  }
  componentDidMount() {
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    // const uid = JSON.parse(localStorage.getItem("user_uid"));
    // const client = JSON.parse(localStorage.getItem("user_client"));
    // const accessToken = JSON.parse(localStorage.getItem("user_access-token"));

    this.setState({
      id: this.props.id,
      street: this.props.street,
      nr: this.props.nr,
      city: this.props.city,
      country: this.props.country,
      zipCode: this.props.zipCode,
      uid,
      client,
      accessToken,
    });
  }

  handleKeyPressNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  handleChange = (event) => {
    this.setState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  toast = () => {
    if (this.state.err) {
      return <ToastsContainer store={ToastsStore} />;
    } else return "";
  };
  handleValidate = (msg) => {
    if (msg === "failed") {
      ToastsStore.error(
        "Error occured in updating the address, check if you have entered right zipcode"
      );
    } else {
      ToastsStore.info("Your address has been edited successfully");
    }
  };

  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/customer/delivery_addresses/${this.props.id}`,
      method: "PUT",
      data: {
        delivery_address: {
          street: this.state.street,
          nr: this.state.nr,
          city: this.state.city,
          country: this.state.country,
          zip_code: this.state.zipCode,
        },
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            err: true,
            success: true,
          };
        });
        this.handleValidate("success");
      })
      .catch((error) => {
        // console.log("edit error address,", error.response.data);
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            err: true,
            success: false,
          };
        });
        this.handleValidate("failed");
      });
  };
  render() {
    const { t } = this.props;
    // console.log("this.state.props ", this.props);
    return (
      <div id="darkautofill">
        {this.toast()}
        <div
          className="modal fade"
          id={"editaddress" + this.props.id}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal_main h-100 w-100 position-absolute"></div>
          <div className="modal-dialog flex-column modal-dialog-centered vh-90">
            <div
              className="modal-content bg-transparent mb-5 border-0 w_75 screen_center"
              id="stripe_form"
            >
              <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
                <h6 className="white">{t("Edit address")}</h6>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="white fs-2 cursor_pointer"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                />
              </div>
              <div className="modal-body rounded_25px bg-light p-4">
                <input
                  type="email"
                  className="border-bottom form-control bg-transparent border-0 my-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={`${t("city")}:  ${this.props.city}`}
                  name="city"
                  onKeyUp={(event) => {
                    this.handleChange(event);
                  }}
                  // defaultValue={this.state.city}
                />
                <input
                  type="text"
                  className="border-bottom form-control bg-transparent border-0 my-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={`${t("Street")}:  ${this.props.street}`}
                  name="street"
                  onKeyUp={(event) => {
                    this.handleChange(event);
                  }}
                  // defaultValue={this.state.street}
                />
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      onKeyPress={this.handleKeyPressNumber}
                      className="border-bottom form-control bg-transparent border-0"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder={`${t("Nr")}*:  ${this.props.nr}`}
                      name="nr"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      // defaultValue={this.state.nr}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      onKeyPress={this.handleKeyPressNumber}
                      className="border-bottom form-control bg-transparent border-0"
                      id="exampleInputEmail1"
                      placeholder={`${t("Zip code")}:  ${this.props.zipCode}`}
                      aria-describedby="emailHelp"
                      name="zipCode"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      // defaultValue={this.state.zipCode}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="border-bottom form-control bg-transparent border-0 my-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="country"
                  placeholder={`${t("country")}:  ${this.props.country}`}
                  onKeyUp={(event) => {
                    this.handleChange(event);
                  }}
                  // defaultValue={this.state.country}
                />
                {/* {!this.state.err ? (
                  <button
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                  >
                    Confirm
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      this.props?.dispatch(
                        forceRender(this.props?.renderCheck + 1)
                      )
                    }
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                  >
                    Close
                  </button>
                )} */}
                {this?.state?.success ? (
                  <button
                    onClick={() => {
                      this.props?.dispatch(setAddress());
                    }}
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                  >
                    Close
                  </button>
                ) : this.state?.loading && !this.state?.success ? (
                  <button className="w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled">
                    {t("processing")}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    className={
                      this.state.city ||
                      this.state.street ||
                      this.state.nr ||
                      this.state.zipCode ||
                      this.state.country
                        ? "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                        : "w-75 btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                    }
                  >
                    {t("Confirm")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    renderCheck: state?.auth?.render,
    authUser: state?.auth?.user,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(EditAddress);
