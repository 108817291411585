import React, { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setTime, setTimePickup } from "../../../../store/Actions/cartActions";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SlotRange from "../Slots/Delivery/SlotRange/index";
import DayDateSlot from "../Slots/index";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { setDTime } from "../../../../store/Actions/authActions";
import axios from "axios";
import Config from "../../../Config";

const DeilvaryDate = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [calender, setCalender] = useState([]);
  const [startday, setStartday] = useState(
    moment(new Date()).format("dddd").substring(0, 3)
  );
  const [firstItem, setFirtItem] = useState(null);

  const [currentCalender, setCurrentCalender] = useState([]);
  const [length, setLength] = useState(null);
  const [dayCount, setDayCount] = useState(1);
  const [index, setIndex] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isDaySelected, setIsDaySelected] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [selectedDates, setSelectedDates]= useState('')
  let selectedDate = "";

  useEffect(() => {
    setIsDaySelected(false);
    const days1 = props?.sAddress?.[0]?.collection_times?.map((item, index) => {
      return {
        ...item,
        isActive: false,
        id: index,
        next_date: null,
        third_date: null,
        date: new Date(item?.this_date),
        tdate: new Date(item?.this_date),
        ndate: null,
        ttdate: null,
      };
    });
    
    const days2 = props?.sAddress?.[0]?.collection_times?.map((item, index) => {
      return {
        ...item,
        isActive: false,
        id: Math.floor(Math.random() * 1000),
        this_date: null,
        third_date: null,
        ndate: new Date(item?.next_date),
        date: new Date(item?.next_date),
        tdate: null,
        ttdate: null,
      };
    });
    
    const days3 = props?.sAddress?.[0]?.collection_times?.map((item, index) => {
      return {
        ...item,
        isActive: false,
        id: Math.floor(Math.random() * 1000),
        this_date: null,
        next_date: null,
        ttdate: new Date(item?.third_date),
        date: new Date(item?.third_date),
        tdate: null,
        ndate: null,
      };
    });
    // days1?.sort((a, b) => a?.tdate - b?.tdate);
    // days2?.sort((a, b) => a?.ndate - b?.ndate);
    let days = days1?.concat(days2)?.concat(days3);
    let sorted = days?.sort((a, b) => a.date - b.date);
    // console.log({ sorted });
    setFirtItem(sorted?.[0]);
    
    let array = [];
    let i;
    for (i = 0; i <= 3; i++) {
      if (sorted?.[i]) {
        array.push(sorted[i]);
      }
    }
    
    setCalender(sorted);
    setLength(days?.length);
    setIndex(i);
    setCurrentCalender(array);
  }, [props?.sAddress, props?.address]);
  const handleClick = (date, day, slot, id) => {
    selectedDate = { date };
    setSelectedDates(date)
        const newCalendar = currentCalender.map((el) => {
          if (el.id === date?.id) return { ...el, isActive: true };
          else return { ...el, isActive: false };
        });
        props.dispatch(setTime(selectedDate));
        setCurrentCalender([...newCalendar]);
        setIsDaySelected(true);
        setCurrentSlot(date.slot);
    props?.dispatch(setDTime(null));
    // validateDeliverySlots(date);
    // let pickupTime = props?.pickup?.date?.date
    // if (pickupTime){
    //   debugger
    //   let formatPickupDate =new Date(pickupTime.split("-")[1]+"-"+ pickupTime.split("-")[2] + "-" + pickupTime.split("-")[0])
    //   let formatDate = new Date(date.date.split("-")[1]+"-"+ date.date.split("-")[2] + "-" + date.date.split("-")[0])
    //   let check = (formatPickupDate.setDate(formatPickupDate.getDate() + 2)) < formatDate.getTime()
    //   if(check){
    //     setError(false)
    //     selectedDate = { date };
    //     const newCalendar = currentCalender.map((el) => {
    //       if (el.id === date?.id) return { ...el, isActive: true };
    //       else return { ...el, isActive: false };
    //     });
        
    //     setCurrentCalender([...newCalendar]);
    //     setIsDaySelected(true);
    //     setCurrentSlot(date.slot);
    //     props.dispatch(setTime(selectedDate));
    //   }else{
    //     setDeliveryDateError("Please select delivery date after 2 day from pickup")
    //   }
    // }else{
    //   setDeliveryDateError("Please select pickup date first")
    // }
    
  };

  const setCalenderState = (array) => {
    let temp = array.sort((a, b) => a.date - b.date);
    setCurrentCalender(temp);
  };
  // const handleNextDay = () => {
  //   let array = [];
  //   if (index + 4 < length) {
  //     let i = index;
  //     for (i; i < index + 4; i++) {
  //       array.push(calender[i]);
  //     }
  //     setCalenderState(array);
  //     setIndex(i);
  //     setIsDaySelected(false);
  //   } else if (index < length) {
  //     let i = index;
  //     for (i; i < length; i++) {
  //       array.push(calender[i]);
  //     }
  //     setCalenderState(array);
  //     setIndex(i);
  //     setIsDaySelected(false);
  //   } else {
  //     return;
  //   }
  // };

  // const handlePrevDay = () => {
  //   if (index <= 1) {
  //     return;
  //   } else if (index - 5 >= 1) {
  //     let i = index - 1;
  //     let array = [];
  //     let temp = index - 5;
  //     for (i; i > temp; i--) {
  //       if (i !== 0) {
  //         array.push(calender[i]);
  //       }
  //     }
  //     setCalenderState(array);
  //     setIndex(i + 1);
  //     setIsDaySelected(false);
  //   } else {
  //     let i = index - 1;
  //     let array = [];
  //     let temp = 0;
  //     for (i; i >= temp; i--) {
  //       if (i >= 0) {
  //         array.push(calender[i]);
  //       }
  //     }
  //     setCalenderState(array);
  //     setIndex(4);
  //     setIsDaySelected(false);
  //   }
  // };
  const handleNextDay = () => {
    let array = [];
    if (index + 1 < length) {
      let i = calender.findIndex(
        (date) => date.date === currentCalender[1].date
      );
      for (i; i < index + 1; i++) {
        array.push(calender[i]);
      }
      setCalenderState(array);
      setIndex(i);
      setIsDaySelected(false);
    } else if (index < length) {
      let i = index;
      for (i; i < length; i++) {
        array.push(calender[i]);
      }
      setCalenderState(array);
      setIndex(i === length ? length - 1 : i);
      setIsDaySelected(false);
    } else {
      return;
    }
  };

  const handlePrevDay = () => {
    if (index <= 1) {
      return;
    } else {
      let i;
      let currDate, temp;
      if (currentCalender.length > 1) {
        i = calender.findIndex(
          (date) =>
            date.date === currentCalender[currentCalender.length - 2].date
        );
        currDate = calender.findIndex(
          (date) => date.date === currentCalender[0].date
        );
        temp = currDate - 1;
      } else {
        i = calender.findIndex((date) => date.date === currentCalender[0].date);
        i = i - 1;
        currDate = i - 3;
        temp = currDate;
      }
      const currIndex = i;
      let array = [];
      for (i; i >= temp; i--) {
        array.push(calender[i]);
      }
      setCalenderState(array);
      setIndex(currIndex + 1);
      setIsDaySelected(false);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="delivery_date"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                onclick="removeblur()"
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="modal-body rounded_25px bg-light px-4 py-5" style={{ border: '8px solid #3D4586' }}>
            <h4 className="theme_color fw-bold align-self-center text-center fw_semibold">{props.t("delivery")}</h4>
              <div className="w-100 mb-4">
                <h6 className="theme_green_color fw_semibold">{props.t("Select Date")}</h6>
                {/* <h6 className="theme_color fw-bold">{`${moment(
                  currentCalender[0]?.date
                ).format("MMMM")} ${moment(startDate).format("yyyy")}`}</h6> */}
                <ul className="p-0 m-0 pt-4 clender_date d-flex flex-row justify-content-evenly position-relative">
                  {props?.sAddress?.[0]?.collection_times?.length == undefined ||
                  props?.sAddress?.[0]?.collection_times?.length == 0
                    ? null
                    : currentCalender?.map((el, i) => {
                      let date = moment(new Date()).format("YYYY-MM-DD");
                      let coming_date = moment(el.this_date ? el.this_date : el.next_date ? el.next_date : el.third_date).format("YYYY-MM-DD");
                     if(date <= coming_date){
                        return (
                          <DayDateSlot
                            id={el?.id}
                            onClick={handleClick}
                            day={el?.day}
                            active={el?.isActive}
                            this_date={el?.this_date}
                            next_date={el?.next_date}
                            third_date={el?.third_date}
                            slot={el?.slots}
                            sAddress={props?.sAddress}
                            address={props?.address}
                          />
                        );
                     }
                      })}
                      {length > 4 && (
                        <div className="w_103per calender-div justify-content-between position-absolute top-50 start-50 translate-middle">
                          {currentCalender[0]?.date !== firstItem?.date &&
                          currentCalender[1]?.date !== firstItem?.date &&
                          currentCalender[2]?.date !== firstItem?.date &&
                          currentCalender[3]?.date !== firstItem?.date ? (
                            <button className="btn-calender" onClick={handlePrevDay}>
                              {`←`}{" "}
                            </button>
                          ) : null}
                          {currentCalender[currentCalender?.length - 1]?.date !==
                            calender[calender?.length - 1]?.date && (
                            <button className="btn-calender ml-auto" onClick={handleNextDay}>
                              {" "}
                              {`→`}{" "}
                            </button>
                          )}
                        </div>
                      )}
                </ul>
              </div>
              <div className="w-100">
              <h6 className="theme_green_color fw_semibold">{props.t("Select Timeslot")}</h6>
                {/* {error && 
                  deliveryDateError &&
                    <p className="text-danger"> Error: {deliveryDateError}</p>
                } */}
                {isDaySelected ? (
                  <SlotRange selectedDate={selectedDates} type="delivery" currentSlot={currentSlot} />
                ) : null}

                <button
                  // onClick={() => {
                  //   {
                  //     this.selected
                  //       ? this.props?.dispatch(setTime(this.selected))
                  //       : alert("You havent selected a date");
                  //   }
                  // }}
                  type="submit"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  className="w-100 btn fill_btn rounded-pill mt-4 mb-2 d-flex justify-content-center"
                >
                  {props.t("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  totalAmount: state.cart.totalAmount,
  address: state.cart.home,
  time: state?.cart?.time,
  deliveryAddress: state.auth.homeAddress,
  sAddress: state?.auth?.selectedAddress,
  pickup: state?.cart?.pickup
});
export default compose(
  connect(mapStateToProps),
  withTranslation()
)(DeilvaryDate);
