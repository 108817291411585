import React from "react"
import $ from "jquery"
import { withTranslation } from "react-i18next"

class StripePaymentSuccess extends React.Component {
  componentDidMount() {
    $("#app-container").addClass("body")
  }
  render() {
    return (
      <div className="unsub">
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        <div className="p-4 arrow_img">
          <img src={"/assets/imgs/arrow_img.png"} />
          <h4 className="mt-4">Click On this to Continue</h4>
        </div>
      </div>
    )
  }
}
export default withTranslation()(StripePaymentSuccess)
