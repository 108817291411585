import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Header from "../Header";
import { validate } from "indicative/validator";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  appleLogin,
  googleLogin,
  login,
  setCurrentLocale,
} from "../../../store/Actions/authActions";
import FacebookLogin from "react-facebook-login";
// import GoogleLogin from "react-google-login";
import "./login.css";
import Config from "../../Config";
import cookies from "js-cookie";
import { setCurrentUser } from "../../../store/Actions/authActions";
import { compose } from "redux";
import AppleLogin from "react-apple-login";

import { GoogleLogin } from '@react-oauth/google';


// const lang = ["English", "Français", "Neederlands"];
const lang = [
  { id: 1, title: "English", icon: "../assets/imgs/UK-flag.png" },
  { id: 2, title: "Français", icon: "../assets/imgs/FR-flag.png" },
  { id: 3, title: "Nederlands", icon: "../assets/imgs/NL-flag.png" },
];

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      useWash: null,
      social: "",
      loading: false,
      loggedIn: false,
      isOpen: false,
      haveText:
        cookies.get("i18next") === "fn"
          ? "Français"
          : cookies.get("i18next") === "du"
          ? "Nederlands"
          : cookies.get("i18next") === "en"
          ? "English"
          : "",
      selectedCountryIcon:
        cookies.get("i18next") === "fn"
          ? "../assets/imgs/FR-flag.png"
          : cookies.get("i18next") === "du"
          ? "../assets/imgs/NL-flag.png"
          : cookies.get("i18next") === "en"
          ? "../assets/imgs/UK-flag.png"
          : "../assets/imgs/UK-flag.png",
      passwordShown: false,
      error: [],
      errors: {
        email: null,
        password: null,
      },
    };
  }
  componentDidMount() {
    // console.log("rendering");
    $("#app-container").addClass("body");
  }

  responseFacebook = (response) => {
    var data = {
      provider: "facebook",
      uid: response.userID,
      id_token: response.accessToken,
      info: {
        email: response.email,
        name: response.name,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${response.accessToken}`,
        "Content-Type": "application/json",
        access_token: `${response.accessToken}`,
      },

      body: JSON.stringify(data),
    };
    if (response.accessToken) {
      return fetch(Config.socialLogin, requestOptions)
        .then((response) => response.json()) // make sure you jsonify the response
        .then((response) => {
          // console.log({ response });
          localStorage.setItem(
            "user_client",
            JSON.stringify(response.headers.client)
          );
          localStorage.setItem(
            "user_uid",
            JSON.stringify(response.headers.uid)
          );
          localStorage.setItem(
            "user_access-token",
            JSON.stringify(response.headers["access-token"])
          );
          localStorage.setItem("user_id", JSON.stringify(response.user.id));
          localStorage.setItem(
            "user_name",
            JSON.stringify(response.user.first_name)
          );

          // console.log(response, "I AM  RESPONSE FROM THE BACKEND");
          const user = {
            user_uid: JSON.parse(localStorage.getItem("user_uid")),
            user_access_token: JSON.parse(
              localStorage.getItem("user_access-token")
            ),
            user_client: JSON.parse(localStorage.getItem("user_client")),
            user_id: JSON.parse(localStorage.getItem("user_id")),
            user_name: JSON.parse(localStorage.getItem("user_name")),
            new_user: response?.user?.new_social_user === true ? true : false,
          };
          // console.log(user, "I AM  RESPONSE FROM THE BACKEND");
          if (response.user.new_social_user === true) {
            this.props.dispatch(setCurrentUser(user));
            this.props?.history?.push("/use-wosh");
          } else {
            this.props.dispatch(setCurrentUser(user));
            this.props.history.push("/home");
          }
        })
        .catch(); //catch any errors
    }
  };

  responseGoogle = (response) => {
    this.props.dispatch(googleLogin(response));

  };
  //  login = useGoogleLogin({
  //   onSuccess: tokenResponse => console.log(tokenResponse),
  // });

  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = null;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    var postData = {
      email: this.state.email,
      password: this.state.password,
    };
    const rules = {
      email: "required|email",
    };
    validate(postData, rules)
      .then(
        this.props.dispatch(login(postData)).then((response) => {
          if (this.props.auth) {
            this.setState({
              error: ["Please provide valid credentials"],
              loading: false,
            });
          }
          // if (localStorage.getItem("auth_error")) {
          //   this.setState({
          //     error: [JSON.parse(localStorage.getItem("auth_error"))],
          //   });
          // }
          // else if (!response) {
          //   this.setState({ error: ["Please pass valid credentials"] });
          // }
          else {
            this.setState((prev) => {
              return {
                ...prev,
                loading: false,
                loggedIn: true,
              };
            });
            // this.props.history.push("/home");
          }
        })
      )
      .catch((errors) => {
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        this.setState({
          errors: formattedErrors,
          loading: false,
        });
      });
  };

  togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };
  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  itemList = (props) => {
    const list = props.map((item) => (
      <div
        onClick={this.handleText}
        // className="dropdown__item"
        className="dropdown__item d-flex justify-content-between px-4"
        key={item.toString()}
      >
        {item.title}
        <img src={item.icon} style={{ width: "30px", height: "20px" }} />
        {/* {item} */}
      </div>
    ));

    return <div className="dropdown__items"> {list} </div>;
  };
  handleText = (ev) => {
    this.setState({
      // haveText: ev.currentTarget.textContent,
      haveText: ev.title,
      selectedCountryIcon: ev.icon,
    });
    cookies.set(
      "i18next",
      ev.currentTarget.textContent === "English"
        ? "en"
        : ev.currentTarget.textContent === "Français"
        ? "fn"
        : "du"
    );
    window.location.reload();
  };

  render() {
    const { t } = this.props;
    const { isOpen, haveText } = this.state;
    return (
      <div className="z_index_one">
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        <div className="w-100 logo">
          <Link to="/home">
            <img
              src="../assets/imgs/logoDark.png"
              width={150}
              className="login_logo img-fluid"
              alt=""
            />
          </Link>
        </div>
        <div className="w-100" />
        <div
          className={
            isOpen
              ? "dropdown position-absolute cursor_pointer active"
              : "dropdown position-absolute cursor_pointer"
          }
          onClick={this.handleClick}
        >
          <div className="cursor_pointer d-flex justify-content-around align-items-center py-2">
            <h5 className="mb-0 theme_color">
              {!haveText ? "English" : haveText}
            </h5>
            <img
              src={this.state.selectedCountryIcon}
              style={{ width: "30px", height: "20px" }}
            />
          </div>
          {/* <div className="dropdown__text">
            {!haveText ? "Select Language" : haveText}
          </div> */}
          {this.itemList(lang)}
        </div>
        <div className="container mb-5 z_index_one mt_in_mobile">
          <div className="w_35per m-auto login_main centered">
            <div className="d-flex flex-column text-center theme_color main-title mb-5">
              <h3 className="theme_color font_style">{t("Login")}</h3>
              {this.state.social && (
                <p className="color-red"> {this.state.social} </p>
              )}
              {this.props.auth && (
                <p className="color-red"> {t(this.props.auth)} </p>
              )}
            </div>
            <form
              id="login_form"
              autoComplete="new-password"
              onSubmit={this.handleSubmit}
            >
              <div
                className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center"
                id="loginAutofill"
              >
                <label className="position-absolute px-3 white theme_bg lable_style">
                  {t("Email")}
                </label>
                {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                <img
                  src="/assets/imgs/mail.png"
                  alt=""
                  style={{ maxHeight: "15px", objectFit: "cover" }}
                />
                <input
                  autoComplete="new-password"
                  type="email"
                  className="form-control bg-transparent border-0 theme_color placeholderDark"
                  id="signinEmail"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder={t("Email address")}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              {this.state.errors && this.state.errors.email && (
                <p className="color-red">{this.state.errors.email}</p>
              )}
              <div
                className="mb-3 position-relative form_input_style d-flex flex-row px-3 py-1 align-items-center"
                id="loginAutofill"
              >
                <label className="position-absolute px-3 white theme_bg lable_style">
                  {t("Password")}
                </label>
                {/* <FontAwesomeIcon icon={faLock} /> */}
                <img
                  src="/assets/imgs/lock.png"
                  alt=""
                  style={{ maxHeight: "15px", objectFit: "cover" }}
                />
                <input
                  autoComplete="new-password"
                  type={this.state.passwordShown ? "text" : "password"}
                  name="password"
                  className="form-control bg-transparent border-0 theme_color placeholderDark"
                  id="exampleInputPassword1"
                  placeholder={t("Password")}
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <div className="hide_show_btn">
                  <img
                    id="eye_img"
                    src="/assets/imgs/eye_pass_black.png"
                    width={20}
                    alt="Show/Hide Password"
                    onClick={this.togglePassword}
                    className="cursor_pointer"
                  />
                </div>
              </div>
              {this.state.errors && this.state.errors.password && (
                <p className="color-red">{this.state.errors.password}</p>
              )}
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Link
                  className="bg-transparent border-0 theme_color text-decoration-none fw-bold"
                  to="/forgot-password"
                >
                  {t("Forgot password?")}
                </Link>
                {!this.state.loading && !this.state.loggedIn ? (
                  <button
                    className="px-4 btn fill_btn_new"
                    type="submit"
                    disabled={
                      this.state.email === "" || this.state.password === ""
                    }
                  >
                    {t("Login Now")}
                  </button>
                ) : // <button className="px-4 btn fill_btn" type="submit">
                //   Login
                // </button>
                this.state.loading && !this.state.loggedIn ? (
                  <button className="px-4 btn fill_btn disabled" type="submit">
                    {t("processing")}
                  </button>
                ) : null}
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center my-4">
                <div className="heading-line" />
                <p className="m-0 mx-3 theme_color text-uppercase">or</p>
                <div className="heading-line" />
              </div>
              {/* <Link to="/personal-details" className="w-100 btn fill_btn mb-4">
                {t("Create your account")}
              </Link> */}
              <GoogleLogin
                disabled={true}
                clientId={Config.oAuthId}
                text={t("Continue with Google")}
                onSuccess={(e) => {
                  this.responseGoogle(e);
                }}  
                // onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
                className="link_with_social text-bold text-center justify-content-center w-100 bg-light small p-2 "
              />

              {/* <AppleLogin
                clientId={"com.herokuapp.woshfrontweb"}
                redirectURI="https://www.woshfrontweb.herokuapp.com/sign-in"
                usePopup={true}
                callback={(response) => {
                  appleLogin(response.authorization);
                  console.log("Apple login responce ==== ===>>>>>", response);
                }} // Catch the response
                scope="email name"
                responseType="id_token"
                // responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <div className="bg-black rounded-pill d-flex justify-content-center mt-2" onClick={renderProps.onClick}>
                    <button>
                      <img src="../assets/imgs/appleLogo.png" style={{width:20,height:20}} />
                    </button>
                    <p style={{marginTop:7,marginBottom:7}} className="text-white">Continue with Apple</p>
                  </div>
                )}
              /> */}

              <div className="row">
                {/* <div className="col-6">
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    cssClass="my-facebook-button-class link_with_social rounded border w-100 bg-light small p-2"
                    icon="fa-facebook"
                  />
                </div> */}
                <div className="col-6"></div>
              </div>
              <p className="m-0 theme_color text-center mt-5 pt-3">
                {t("Don't have an account yet?")}{" "}
                <strong>
                  <Link
                    to="/personal-details"
                    className="theme_color text-decoration-none font_style"
                  >
                    {t("Sign up")}
                  </Link>
                </strong>
              </p>
            </form>
          </div>
          <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
            <div className="mt-auto footer_btn d-flex flex-column pt-3 fw-bold">
              <a
                style={{ textDecoration: "none" }}
                href="https://www.wosh.be/"
                target="_blank"
                rel="noreferrer"
              >
                {t("How it works")}{" "}
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://www.wosh.be/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {t("Privacy Policy")}
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://www.wosh.be/legal/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                {t("Terms & Conditions")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state?.auth?.error,
});

export default compose(withTranslation(), connect(mapStateToProps))(SignIn);
// export default connect(mapStateToProps)(withTranslation()(SignIn));
