import Config from "../../components/Config";
import Axios from "axios";
import { store } from "../..";
import { logout } from "./authActions";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SET_CURRENT_CART = "SET_CURRENT_CART";
export const SET_QUANTITY = "SET_QUANTITY";
export const SET_CART_QUANTITY = "SET_CART_QUANTITY";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCTS = "PRODUCTS";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const CLEAR_CART = "CLEAR_CART";
export const SET_TIME = "SET_TIME";
export const SET_TIME_PICKUP = "SET_TIME_PICKUP";
export const SET_PROMO = "SET_PROMO";
export const SET_PAST_CART = "SET_PAST_CART";
export const SET_BAGS = "SET_BAGS";
export const SET_CLEAR_CART_STATE = "SET_CLEAR_CART_STATE";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const ERROR_PRODUCTS = "ERROR_PRODUCTS";

export function set_error(error) {
  return {
    type: ERROR_PRODUCTS,
    payload: error,
  };
}

export function get_products(products) {
  return {
    type: GET_PRODUCTS,
    payload: products,
  };
}
export function setPastCart(products) {
  return {
    type: SET_PAST_CART,
    payload: products,
  };
}

export function setCartItems(cart) {
  return {
    type: SET_CART_ITEMS,
    payload: cart,
  };
}
export function getCartItems(cart) {
  return {
    type: GET_CART_ITEMS,
    payload: cart,
  };
}
export function setClearCartState() {
  return {
    type: SET_CLEAR_CART_STATE,
  };
}
export function setBagsCart(products) {
  return async (dispatch) => {
    let temp = products.map((item, index) => {
      dispatch(setCartQuantity(item, "plus"));
      // return {
      //   ...item,
      //   quantity: 1,
      // };
    });
    // return {
    //   type: SET_BAGS,
    //   payload: products,
    // };
  };
}

export function get_promo(promo) {
  return {
    type: SET_PROMO,
    payload: promo,
  };
}

export function setTime(time) {
  return {
    type: SET_TIME,
    payload: time,
  };
}
export function setTimePickup(time) {
  return {
    type: SET_TIME_PICKUP,
    payload: time,
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export function selectAddress(temp) {
  return {
    type: SELECT_ADDRESS,
    temp: temp,
  };
}

export function setCurrentCart(updatedItems, totalAmount) {
  return {
    type: SET_CURRENT_CART,
    updatedItems,
    totalAmount,
  };
}

export function setPromo(code) {
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/promo_codes/${code}`,
        method: "GET",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      if (response.data.promo_code.available) {
        dispatch(get_promo(response.data.promo_code));
        return true;
      } else {
        return false;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout());
      }
      else if (err?.response?.status === 422) {
        dispatch(get_promo(err?.response?.data));
      }
      // console.log("promo error", err.response.data);
      return false;
    }
  };
}

export function fetchCartItem() {
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/cart/`,
        method: "GET",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });

      const cart = await response?.data?.cart;
      await dispatch(setCartItems(cart));
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout());
      }
      // console.log("promo error", err.response.data);
      return false;
    }
  };
}

export const cartEdit = (products, history) => {
  const cartProducts = products?.products?.map((p) => {
    return {
      product_id: p.product_id,
      quantity: p.quantity,
      laundry_place_id: JSON.parse(localStorage.getItem("laundry_id")),
    };
  });
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/cart`,
        method: "PUT",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
        data: {
          cart: {
            cart_products_attributes: cartProducts,
          },
        },
      });
      // console.log("user_client", store?.getState()?.auth?.user?.user_uid);
      const res = await response?.data?.cart;
      await dispatch(setCartItems(res));
      // history && history?.push("/checkout");
      // debugger;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout());
      }
    }
  };
};

export const cartApi = (cart, sign) => {
  let data;
  let temp = store
    ?.getState()
    ?.cart?.cartItems?.filter((item) => item?.id === cart?.id);
  if (sign === "plus") {
    if (temp?.length > 0) {
      data = {
        product_id: temp[0]?.id,
        laundry_place_id: localStorage.getItem("laundry_id"),
        id: temp[0]?.cart_id,
        quantity: temp[0]?.quantity + 1,
      };
    } else {
      data = {
        product_id: cart?.id,
        quantity: 1,
        laundry_place_id: localStorage.getItem("laundry_id"),
      };
    }
  } else if (sign === "destroyAllProducts") {
    data = {
      id: temp[0]?.cart_id,
      _destroy: 1,
    };
  } else {
    if (temp?.length > 0) {
      if (temp[0]?.quantity === 1)
        data = {
          id: temp[0]?.cart_id,
          _destroy: 1,
        };
      else {
        data = {
          product_id: temp[0]?.id,
          laundry_place_id: localStorage.getItem("laundry_id"),
          id: temp[0]?.cart_id,
          quantity: temp[0]?.quantity - 1,
        };
      }
    } else {
      data = {
        product_id: cart?.id,
        quantity: 1,
        laundry_place_id: localStorage.getItem("laundry_id"),
      };
    }
  }
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/cart`,
        method: "PUT",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
        data: {
          cart: {
            cart_products_attributes: [data],
          },
        },
      });
      // console.log("user_client", store?.getState()?.auth?.user?.user_uid);
      const res = await response?.data?.cart;
      await dispatch(setCartItems(res));

      // debugger;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout());
      }
    }
  };
};

export function setQuantity(item, sign) {
  cartApi(item);
  // return {
  //   type: SET_QUANTITY,
  //   payload: item,
  //   sign: sign,
  // };
}
export function setCartQuantity(item, sign) {
  return {
    type: SET_CART_QUANTITY,
    payload: item,
    sign: sign,
  };
}

// export function getProducts() {
//   return async (dispatch) => {
//     try {
//       let response = await Axios({
//         url: `${Config.apiURL}/customer/products`,
//         method: "GET",
//         headers: {
//           uid: store?.getState()?.auth?.user?.user_uid,
//           client: store?.getState()?.auth?.user?.user_client,
//           "access-token": store?.getState()?.auth?.user?.user_access_token,
//           // uid: JSON.parse(localStorage.getItem("user_uid")),
//           // client: JSON.parse(localStorage.getItem("user_client")),
//           // "access-token": JSON.parse(localStorage.getItem("user_access-token")),
//         },
//       });
//       // console.log("user_client", store?.getState()?.auth?.user?.user_uid);

      
//       const products = await response?.data?.products;
      
//       const overCat = products.filter((item) => {
//         return item?.category?.over_category?.status === 'active';
//       })

//       let updatedItems = overCat && overCat.length > 0 && overCat.filter((item) => {
//         return item.is_active === true;
//       });
      
//       dispatch(get_products(updatedItems));
//       localStorage.setItem("laundry_id", updatedItems[0]?.laundry_id)
//     } catch (err) { }
//   };
// }

export function getProducts() {
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/products`,
        method: "GET",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
          // uid: JSON.parse(localStorage.getItem("user_uid")),
          // client: JSON.parse(localStorage.getItem("user_client")),
          // "access-token": JSON.parse(localStorage.getItem("user_access-token")),
        },
      });
      // console.log("user_client", store?.getState()?.auth?.user?.user_uid);
      
      dispatch(set_error(null))
      const products = await response?.data?.products;

      if(products.length > 0)
      {
        let updatedItems = products.filter((item) => {
          return item.is_active === true;
        });
        dispatch(get_products(updatedItems));
        localStorage.setItem("laundry_id", updatedItems[0]?.laundry_id)
      }
      else
      {
        dispatch(set_error("No products found"))
      }
    } catch (err) {
      dispatch(set_error(null))
      if(err?.response?.status === 404)
      {
      dispatch(set_error(err?.response?.data?.error))
      // dispatch(get_products(null));
      }
     }
  };
}

export function incDec(item, sign) {
  return async (dispatch) => {
    try {
      dispatch(setQuantity(item, sign));
    } catch (err) {
      return err;
    }
  };
}
export function CartIncDec(item, sign) {
  return async (dispatch) => {
    try {
      dispatch(setCartQuantity(item, sign));
    } catch (err) {
      return err;
    }
  };
}

export const addItemsToCart = (item) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ITEM, item: item });
  };
};

export const removeItemsFromCart = (item) => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_ITEM, item: item });
  };
};