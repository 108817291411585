import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import NewAddress from "../NewAddress/index";
import { connect } from "react-redux";
import EditAddress from "../NewAddress/EditAddress/index";
import { selectAddress } from "../../../../store/Actions/cartActions";
import { setSelectedAddress } from "../../../../store/Actions/authActions";
import EditAddressModal from "../EditAddress";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import Config from "../../../Config";
const Address = (props) => {
  const [selected, setSelected] = useState({ type: "", val: "" });
  const [val, setVal] = useState(null);
  const [type, setType] = useState(null);
  const [default_Address, setDefault_Address] = useState(null)
  const setAddress = () => {
    props.dispatch(setSelectedAddress(selected.val, selected.type));
    props.dispatch(selectAddress(selected.type));
  };
  const handleChange = (event, type) => {
    // props.dispatch(setSelectedAddress({ address: id, atype: type }));
  };
  const { t } = useTranslation();

  useEffect(() => { }, [props?.deliveryAddress, props?.selected]);

  return (
    <>
      <div
        className="modal fade"
        id="address"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90 ">
          <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <h6 className="white">{t("Address")}</h6>
              <FontAwesomeIcon
                //   onClick={props.onPress}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4">
              <div className="d-flex flex-column">
                <h5 className="theme_color fw-bold">{t("Address")}</h5>
                <div className="heading-line_color mb-4"></div>
              </div>
              <h6>Office</h6>
              <div className="w-100 d-flex mb-2">
                <select
                  value={props?.address === true ? "Please Select" : null}
                  onChange={(event) => {
                    props.dispatch(
                      setSelectedAddress(event.target.value, "office")
                    );
                    props.dispatch(selectAddress("office"));
                  }}
                  className="btn w-100 rounded-pill theme_bg_btn theme_color font_12px"
                >
                  <option value="" hidden>
                    {props?.selected?.length > 0 && props?.address === false
                      ? props?.selected[0]?.address
                      : t("Select Office Address")}
                  </option>
                  ;
                  {props.officeAddress
                    ? props?.officeAddress?.map((item, index) => {
                      return (
                        <option value={item.id}>{`${item.address}`}</option>
                      );
                    })
                    : "No address "}
                </select>
              </div>
              <h6>Home</h6>
              <div className="w-100 d-flex mb-2">
                <select
                  value={props?.address === false ? "Please Select" : null}
                  onChange={(event) => {
                    props.dispatch(
                      setSelectedAddress(event.target.value, "home")
                    );
                    props.dispatch(selectAddress("home"));
                  }}
                  className="btn w-75 rounded-pill theme_bg_btn theme_color font_12px"
                >
                  <option value="" hidden>
                    {props?.selected?.length > 0 && props?.address === true
                      ? props?.selected[0]?.address
                      : t("Select Home Address")}
                  </option>
                  ;
                  {props?.deliveryAddress
                    ? props?.deliveryAddress?.map((item, index) => {
                      return (
                        <option value={item.id}>{`${item?.address}`}</option>
                      );
                    })
                    : "No address "}
                </select>
                {props?.address && props?.selected?.length > 0 ? (
                  <button
                    // onClick={modalblur}
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    data-bs-target={"#editaddress" + props?.selected[0]?.id}
                    className="btn w-25 rounded-pill gradian_btn px-4 py-1 d-flex ms-2 justify-content-center"
                  >
                    {t("edit")}
                  </button>
                ) : null}
              </div>
              <button
                type="submit"
                class="w-100 btn_modal btn theme_bg_btn theme_color_light_blue mt-4 mb-2 d-flex justify-content-center checkout_button"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                aria-hidden="true"
                data-bs-target="#staticBackdrop"
              >
                + {t("Add new address")}
              </button>
              <button
                // onClick={setAddress}
                type="submit"
                className="w-100 btn btn-custom-onboard mt-4 mb-2 d-flex justify-content-center"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                {t("use")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.selected && (
        <EditAddress
          city={props.selected[0]?.city}
          street={props.selected[0]?.street}
          country={props.selected[0]?.country}
          nr={props.selected[0]?.nr}
          zipCode={props.selected[0]?.zip_code}
          id={props.selected[0]?.id}
        />
      )}
      <EditAddressModal />
      {/* <NewAddress /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  totalAmount: state.cart.totalAmount,
  address: state.cart.home,
  deliveryAddress: state.auth.homeAddress,
  officeAddress: state.auth.officeAddress,
  user_id: state?.auth?.user,
  selected: state?.auth?.selectedAddress,
  renderCheck: state?.auth?.render,
});

export default connect(mapStateToProps)(Address);
