import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Config from "./../../Config";

// import { Button } from "bootstrap";

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      token: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      termsConditions: false,
      passwordConfirmation: null,
      passwordShown: false,
      passwordConfirmationShown: false,
      error: "",
      errors: {
        emal: null,
        password: null,
      },
    };
  }
  componentDidMount() {
    const url = new URL(window.location.href)
    this.setState({...this.state, token: url.searchParams.get("reset_password_token")})
  }

  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = null;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };
  handleSubmit = () => {
    const header = new Headers();
    header["Access-Control-Allow-Origin"] = "*";
    Axios.put(
      `${Config.authURL}/auth/password`,
      {
        password: this.state.password,
        password_confirmation: this.state.passwordConfirmation,
        reset_password_token: this.state.token,
      },
      Config.headers
    )
      .then((response) => {
        this.props.history.push("/sign-in");
      })
      .catch((error) => {
        let str = "";
        if (error?.response?.data) {
          Object.keys(error?.response?.data?.errors?.full_messages).forEach(
            (key) => {
              let value = error?.response?.data?.errors?.full_messages[key];
              str = str.concat(value + " ");
            }
          );
          this.setState({
            error: str,
          });
        } else {
          this.setState({
            error: "Network error, please try again later.",
          });
        }
      });
  };

  render() {
    return (
      <div>
        {/* <Header history={this.props.history} /> */}
        <div className="w-100" />
        <div className="container mb-5">
          <div className="row mt-5">
            <div className="col-md-3 col-lg-3 col-xs-12 d-flex align-items-end">
              <div className="mt-auto footer_btn d-flex flex-row align-items-center pt-3">
                <Link to="/sign-in" className="text-decoration-none small">
                  <FontAwesomeIcon icon={faArrowLeft} /> Already Have an
                  Account?{" "}
                </Link>
                <Link to="/sign-in" className="ms-2 small">
                  {" "}
                  <strong> Sign In</strong>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="w-75 m-auto login_main d-flex flex-column align-items-center">
                <div className="d-flex flex-column main-title mb-5">
                  <h3>Reset Password</h3>
                  <div className="heading-line w-25" />
                </div>
                {this.state.error ? (
                  <p className="color-red">{this.state.error}</p>
                ) : null}
                <div id="login_form" className="pb-5 w-100 mt-2">
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 bg-light theme_color lable_style">
                      Password
                    </label>
                    <FontAwesomeIcon icon={faLock} />
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      className="form-control bg-transparent border-0 theme_color"
                      id="signupPassword"
                      aria-describedby="passwordHelp"
                      placeholder="Password" 
                      onChange={this.handleChange}
                      name="password"
                    />
                    <div className="hide_show_btn">
                      <img
                        id="eye_img"
                        src="/assets/imgs/eye_pass_black.png"
                        width={20}
                        alt="Show/Hide Password"
                        onClick={this.togglePassword}
                      />
                    </div>
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 bg-light theme_color lable_style">
                      Confirm Password
                    </label>
                    <FontAwesomeIcon icon={faLock} />
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      className="form-control bg-transparent border-0 theme_color"
                      id="signupConfirmPassword"
                      aria-describedby="confirmPasswordHelp"
                      placeholder="Confirm Password"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      name="passwordConfirmation"
                    />
                    <div className="hide_show_btn">
                      <img
                        id="eye_img"
                        src="/assets/imgs/eye_pass_black.png"
                        width={20}
                        alt="Show/Hide Password"
                        onClick={this.togglePassword}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    className="w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-3 col-xs-12" />
          </div>
        </div>
      </div>
    );
  }
}
export default ResetPassword;
