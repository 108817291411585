import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { connect } from "react-redux";
import {cartApi, setBagsCart} from "../../../store/Actions/cartActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import AuthedRoute from "../../../App.js";
class GetBags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prev: localStorage.getItem("path") ? true : false,
      first: true,
      second: true,
      hanger: false,
      folded: false,
      t: this.props.t,
    };
  }
  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  componentDidMount() {}
  componentWillUnmount() {}
  onClick = () => {
    // debugger
    if (this.props?.cartItems?.length > 0) {
      if (this.state.hanger && this.state.folded) {
        let temp1 = this.props?.cartItems?.filter((item) => {
          if (item.name === "Folded Bag") {
            return item;
          }
        });
        let temp2 = this.props?.cartItems?.filter((item) => {
          if (item.name === "On Hanger Bag") {
            return item
          }
        });
        let array = temp1.concat(temp2);
        // this.props?.dispatch(setBagsCart(array));
        this.props?.dispatch(cartApi(temp1[0], "plus"));
        this.props?.dispatch(cartApi(temp2[0], "plus"));


        // console.log("bags array, ", array);
      }
      if (this.state.hanger && !this.state.folded) {
        let array = this.props?.cartItems?.filter((item) => {
          if (item.name === "On Hanger Bag") {
            return item
          }
        });
        // this.props?.dispatch(setBagsCart(array));
        this.props?.dispatch(cartApi(array[0], "plus"));

        // console.log("bags array, ", array);
      }
      if (this.state.folded && !this.state.hanger) {
        let array = this.props?.cartItems?.filter((item) => {
          if (item.name === "Folded Bag") {
            return item
          }
        });
        this.props?.dispatch(cartApi(array[0], "plus"));

        // this.props?.dispatch(setBagsCart(array));
        // console.log("bags array, ", array);
      }
    }
  };
  render() {
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        {/* {window.location.pathname !== "/profile" && (
          // <Header history={this.props.history} />
        )} */}
        <div className="container mb-5 z_index_one">
          {window.location.pathname !== "/profile" && this.props.history.location.state && this.props.location.state?.myLove === "Pakistan" ? <ProgressBar step={5} /> : null}
          <div
            className={
              window.location.pathname !== "/profile"
                ? "d-flex bag_custom_center flex-column main-title ms-auto me-auto w_360px mt-5"
                : "d-flex bag_custom_center flex-column main-title ms-auto me-auto w_360px mt-1"
            }
          >
            <h3 className="w_min_content">
              {this.state.t("Get your laundry bag")}
            </h3>
            <div className="heading-line w-25 mb-3" />
          </div>
          <div className="d-flex bag_custom_center flex-column main-title mb-5 ms-auto me-auto w-75">
            <p className="white ms-auto me-auto text-center">
              {this.state.t(
                "To ease the logistics and prevent plastic use, Wosh users are asked to use our reusable & personnalized Laundry Bags. We kindly ask you to already add your preferred bag to your cart now. Otherwise, Wosh will provide you the bag that suits your items upon delivery of your first order. For the collection of your first order, please us any disposable bag with you id number in it."
              )}
            </p>
          </div>
          <div className="row">
            {/* <button onClick={this.onClick}>on save</button> */}
            <div className="second col-md-1 col-lg-1 col-xs-12" />
            <div
              className="me-4 first col-md-5 col-lg-5 col-xs-12 pb-5 d-flex justify-content-center align-items-center minh_301px"
              onClick={() => {
                this.handleChange("hanger", !this.state.hanger);
              }}
            >
              <div
                className={
                  this.state.hanger
                    ? "w-100 h-100 m-auto position-relative use_wash_boxes px-2 py-5 text-center d-flex flex-column justify-content-center align-items-center text-decoration-none checked_box minh_301px"
                    : "w-100 h-100 m-auto position-relative use_wash_boxes px-2 py-5 text-center d-flex flex-column justify-content-center align-items-center text-decoration-none minh_301px"
                }
              >
                <div className="bg-light position-absolute use_wosh_check d-flex justify-content-center align-items-center d-none">
                  <FontAwesomeIcon icon={faCheckCircle} size="3x" />
                </div>
                <div className="row">
                  <div className="col-md-5 text-center">
                    <img
                      src="/assets/imgs/wash.PNG"
                      className="img-fluid h_189px"
                      alt=""
                    />
                  </div>
                  <div className="col-md-7">
                    <h3 className="mt-3 pick_delivery_heading">
                      {this.state.t("On Hanger Bag")}
                    </h3>
                    <p className="white">{this.state.t("Free")}</p>
                    <p className="white small">
                      {this.state.t(
                        "The on-Hanger bag, is the perfect bag for all your on-hanger items like your suits, dress, coat, etc."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ms-4 col-md-5 col-lg-5 col-xs-12 pb-5 d-flex justify-content-center align-items-center"
              onClick={() => {
                this.handleChange("folded", !this.state.folded);
              }}
            >
              <div
                className={
                  this.state.folded
                    ? "w-100 h-100 m-auto position-relative use_wash_boxes px-2 py-5 text-center d-flex flex-column justify-content-center align-items-center text-decoration-none checked_box minh_301px"
                    : "w-100 h-100 m-auto position-relative use_wash_boxes px-2 py-5 text-center d-flex flex-column justify-content-center align-items-center text-decoration-none minh_301px"
                }
              >
                <div className="bg-light position-absolute use_wosh_check d-flex justify-content-center align-items-center d-none">
                  <FontAwesomeIcon icon={faCheckCircle} size="3x" />
                </div>
                <div className="row">
                  <div className="col-md-5 justify-content-center d-flex align-items-center">
                    <img
                      src="/assets/imgs/bags.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-7">
                    <h3 className="mt-3 pick_delivery_heading">
                      {this.state.t("Folded Bag")}{" "}
                    </h3>
                    <p className="white">{this.state.t("Deposit of 12€")}</p>
                    <p className="white small">
                      {this.state.t(
                        "The folded bag is the easiest solution to manage all your folded items like your t-shirt, pant, sportwear, beding, underwear, etc. Can be use for the Per kilo or Folded per item services. The bag can contain up to 12kg of laundry."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-lg-1 col-sm-12 col-xs-12" />
          </div>
          <div className="w-100 text-center mt-5">
            <Link
              to={window.location.pathname === "/profile" ? "/home" : "/thank-you"}
              className="w_180px btn fill_btn mb-3 d-block ms-auto me-auto"
              disabled={this.state.bag ? false : true}
              onClick={this.onClick}
            >
              {this.state.t("Confirm")}
            </Link>
            {window.location.pathname !== "/profile" && (
              <Link to="/thank-you" className="w_180px btn border_btn">
                {this.state.t("Skip")}
              </Link>
            )}
          </div>
          {window.location.pathname !== "/profile" && (
            <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
              <Link
                onClick={() => localStorage.removeItem("path")}
                className="text-decoration-none small"
                to={this.state.prev ? "/connect-point" : "/use-wosh"}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> {this.state.t("Back")}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cartItems: state?.cart?.mockItems,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(GetBags);
