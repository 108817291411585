import React from "react"
import { connect } from "react-redux"
import { setRating } from "../../../../store/Actions/authActions"

class StarRating extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stars: [],
      rating: this.props.rating,
      hovered: 0,
      starHovered: 0,
      selectedIcon: "★",
      deselectedIcon: "☆",
    }

    let outOf = props.outOf ? props.outOf : 5

    for (var i = 0; i < outOf; i++) {
      this.state.stars.push(i + 1)
    }
  }

  changeRating(newRating) {
    if (!this.props.reviewed) {
      this.setState({
        rating: newRating,
      })
      this.props.dispatch(setRating(newRating))
      if (this.props.onChange) this.props.onChange(newRating)
    }
  }

  hoverRating = (rating) => {
    if (!this.props.reviewed) {
      this.setState({
        hovered: rating,
      })
    }
  }

  render() {
    const { stars, rating, hovered, deselectedIcon, selectedIcon } = this.state

    return (
      <div className="cursor_pointer">
        <i
          className={
            rating >= 1 || hovered >= 1
              ? "fa fa-star fa-2x theme_color_icon mr-2"
              : "fa fa-star-o fa-2x theme_color_icon mr-2"
          }
          onMouseOver={() => {
            this.hoverRating(1)
          }}
          onMouseOut={() => {
            this.hoverRating(0)
          }}
          onClick={() => {
            this.changeRating(1)
          }}
          aria-hidden="true"
        ></i>
        <i
          className={
            rating >= 2 || hovered >= 2
              ? "fa fa-star fa-2x theme_color_icon mr-2"
              : "fa fa-star-o fa-2x theme_color_icon mr-2"
          }
          onMouseOver={() => {
            this.hoverRating(2)
          }}
          onMouseOut={() => {
            this.hoverRating(0)
          }}
          onClick={() => {
            this.changeRating(2)
          }}
          aria-hidden="true"
        ></i>
        <i
          className={
            rating >= 3 || hovered >= 3
              ? "fa fa-star fa-2x theme_color_icon mr-2"
              : "fa fa-star-o fa-2x theme_color_icon mr-2"
          }
          onMouseOver={() => {
            this.hoverRating(3)
          }}
          onMouseOut={() => {
            this.hoverRating(0)
          }}
          onClick={() => {
            this.changeRating(3)
          }}
          aria-hidden="true"
        ></i>
        <i
          className={
            rating >= 4 || hovered >= 4
              ? "fa fa-star fa-2x theme_color_icon mr-2"
              : "fa fa-star-o fa-2x theme_color_icon mr-2"
          }
          onMouseOver={() => {
            this.hoverRating(4)
          }}
          onMouseOut={() => {
            this.hoverRating(0)
          }}
          onClick={() => {
            this.changeRating(4)
          }}
          aria-hidden="true"
        ></i>
        <i
          className={
            rating >= 5 || hovered >= 5
              ? "fa fa-star fa-2x theme_color_icon"
              : "fa fa-star-o fa-2x theme_color_icon"
          }
          onMouseOver={() => {
            this.hoverRating(5)
          }}
          onMouseOut={() => {
            this.hoverRating(0)
          }}
          onClick={() => {
            this.changeRating(5)
          }}
          aria-hidden="true"
        ></i>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ratingCount: state.auth.rating,
    current: state?.auth?.current,
  }
}
export default connect(mapStateToProps)(StarRating)
