import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const FiveStarRating = ({ toggleModal }) => {
    const { t } = useTranslation()

    // useEffect=(()=>{
    //     // window.location.reload()
    // },[toggleModal])

    return (
        <div>
            <div
                className="fade_custom"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content border_color">
                        {/* <div className="d-flex justify-content-end mb-2 close"> */}
                        <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="white fs-2 cursor_pointer"
                                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -80, position: 'absolute', zIndex: 1000, backgroundColor: '#3D4586' }}
                                onClick={() => {
                                    toggleModal()
                                    window.location.reload()
                                }}
                            />
                        </div>
                        <div className="modal-body rounded_25px p-4">
                            <div className="w-100 text-center">
                                <h5 className='theme_color fw-bold'>{t("Share your Experience ?")}</h5>
                                <img src={'/assets/star_153.png'} width={180} />
                                <p>{t("Enjoying WOSH? Help others discover us! Leave a review on Google and let your voice be heard. Your feedback is invaluable.")}</p>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between justify-content-sm-center border-0">
                            <button
                                onClick={() => {
                                    toggleModal()
                                    window.location.reload()
                                }}
                                className='bg-transparent theme_color fw-bold border_color_btn'>
                                {t("Not now")}
                            </button>
                            <button
                                className='fill_btn white fw-bold border_color_btn'
                            >
                                <a href=" https://g.page/r/CeJ0UhgWwL0fEB0/review" className='white text-decoration-none' target="_blank">{t("Share review")}</a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiveStarRating