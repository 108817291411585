import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Header/user.css";
import Config from "../../Config"
import Axios from "axios";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { useTranslation } from "react-i18next"

const LaundryPartner = (props) => {
  const { t } = useTranslation()
  const [res, setRes] = useState(null)
  const [laundryData,setLaundryData] = useState({})

    const getLaundryPartner = async () => {
      const uid = props?.authUser?.user_uid
      const client = props?.authUser?.user_client
      const accessToken = props?.authUser?.user_access_token
      try {
        const response = await Axios({
          url: `${Config.apiURL}/customer/laundry_partner`,
          method: "get",
          headers: {
            uid: `${uid}`,
            client: `${client}`,
            "access-token": `${accessToken}`,
          },
        })
        setLaundryData(response.data)
      } catch (err) {
        setRes(err.response.data.message)
      }
    }


      useEffect(() => {
        getLaundryPartner()
      }, [])

  return (
    <div class="py-5 scroll_tab_content_new_2">
      <h3 class="text-center mb-5 themeColor laundry-size">{t("Laundry Place")}</h3>
      <div class="">
        <div class="w-75 mx-auto position-relative">
          <img
            src="/assets/imgs/grey_circle.png"
            width="120"
            class="position-absolute top-15 start-0 translate-middle below_index"
          />
          <img
            src="/assets/imgs/blue_circle.png"
            width="80"
            class="position-absolute bottom-15 start-100 translate-middle below_index"
          />
          <div class="laundry_inner bg-white">
            <p class="text-center fs-5 text themeColor pt-3">
              {t("Your area is being served by")}
            </p>
            <h1 class="text-center themeColor laundry-size">
              {laundryData?.name}
            </h1>
            <div class="w-75 mx-auto d-flex flex-wrap flex-column flex-md-row justify-content-between align-items-center my-5">
              <img
                src={laundryData?.avatar}
                width="300"
                class="flex-1 me-0 me-md-4"
                alt="LO avatar"
              />
              <p class="themeColor flex-1 ms-0 ms-md-5 text-break">
                {cookie.get("i18next") === "fn"
                  ? laundryData?.description_fr
                  : cookie.get("i18next") === "du"
                  ? laundryData?.description_nl
                  : laundryData?.description_en}
              </p>
            </div>
            <div class="footer p-3">
              <p class="pb-3 text-white mb-0 text-center">
                {t("Location of Laundry")}: {laundryData?.street_number} -{" "}
                {laundryData?.zip}, {laundryData?.city}
              </p>
              {laundryData?.latitude && laundryData?.longitude ? (
                <iframe
                  className="mb-3"
                  src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5042.384095674945!2d${laundryData.latitude}!3d${laundryData.longitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c4d4a4732dcf%3A0xf9c247db376977fb!2sLa%20Petite%20Suisse%20%7C%20Nettoyage%20%C3%A0%20sec%20%26%20Blanchisserie!5e0!3m2!1sen!2s!4v1709730027593!5m2!1sen!2s`}
                  width="100%"
                  height="350"
                  style={{ border: "0" }}
                  allowFullScreen="yes"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              ) : (
                <p>Location not found</p>
              )}
              <div class="row">
                <div class="col">
                  <div class="bg-white px-5 py-4 rounded-8px d-flex flex-row align-items-center">
                    <img src="/assets/imgs/email.png" width="" height="25" />
                    <p class="mb-0 ms-2" style={{ color: "#3D4587" }}>
                      {laundryData?.email}
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="bg-white px-5 py-4 rounded-8px d-flex flex-row align-items-center">
                    <img src="/assets/imgs/telephone.png" height="25" />
                    <p class="mb-0 ms-2" style={{ color: "#3D4587" }}>
                      {laundryData?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


const mapStateToProps = (state) => ({
  authUser: state?.auth?.user,
});
export default connect(mapStateToProps)(LaundryPartner)
