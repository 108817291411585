import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../../Header/user.css";
import { useTranslation } from "react-i18next";
import { set_OfficeAddress } from "../../../../store/Actions/authActions";
import Axios from "axios";
import Config from "../../../Config/index.jsx";
import { ToastsContainer, ToastsStore } from "react-toasts";

const DebitCard = (props) => {
  const { t } = useTranslation();
  useEffect(() => { }, [props?.cards]);

  const toast = () => {
    return <ToastsContainer store={ToastsStore} />;
  };
  const handleValidate = (msg, err) => {
    if (msg === "okay") {
      ToastsStore.info("Payment method has been deleted successfully");
    } else {
      ToastsStore.error(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/payment_methods/${id}`,
        headers: {
          uid: props.newUser.user_uid,
          client: props.newUser.user_client,
          "access-token": props.newUser.user_access_token,
        },
        method: "DELETE",
      });

      const data = await response.data;
      props?.dispatch(set_OfficeAddress());
      handleValidate("okay")
    } catch (err) {
      if (err?.response) {
        handleValidate("error", "Payment method can't be deleted because it's active in an order")
      }
      else {
        handleValidate("error", "Something went wrong please try later")
      }
    }
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-between">
      {toast()}
      {props?.cards && props?.cards.length > 0 ? (
        props?.cards?.map((item) => {
          return (
            <>
              {item?.id && <div className="w-100 d-flex flex-sm-row flex-column mb-4 woshwallet_border">
                <button
                  onClick="modalblur()"
                  className="w_75 btn payment_btn theme_color d-flex justify-content-center flex-row align-items-center px-sm-5 px-md-3 px-5 shadow-none outline-0 me-2 mb-sm-0 mb-2 fw-bold regular_font mb-0"
                >
                  {/* <img
                      alt=""
                      src={
                        item?.brand === "MasterCard"
                            ? "../assets/imgs/mastercard.png"
                            : "../assets/imgs/visa.svg"
                      }
                      width={45}
                  /> */}
                  <h6 className="fw-bold mb-0 mt-1">
                    **** **** **** {item?.last4}
                  </h6>
                </button>
                <button
                  onClick={() => handleDelete(item?.id)}
                  className={
                    props?.isActive && props?.isActive?.payment_method_id && props?.isActive?.payment_method_id === item?.id
                      ? "w_25 btn border-0 woshwallet_new_2 d-flex justify-content-center align-items-center disabled py-2"
                      : "w_25 btn border-0 woshwallet_new_2 d-flex justify-content-center align-items-center py-2"
                  }
                >
                  <img src="./assets/imgs/new_delete.png" width={26} height={26} />
                </button>
              </div>}
            </>
          );
        })
      ) : (
        null
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  cards: state?.auth?.paymentId,
  newUser: state?.auth?.user,
  isActive: state?.auth?.isActive,
});
export default connect(mapStateToProps)(DebitCard);
