import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import Config from "./../../Config";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

class AddColectionPoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      client: null,
      accessToken: null,
      name: null,
      loading: false,
      success: false,
      street: null,
      error: null,
      city: null,
      country: null,
      nr: null,
      zip_code: null,
      comment: null,
    };
  }
  componentDidMount() {
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;
    // const uid = JSON.parse(localStorage.getItem("user_uid"));
    // const client = JSON.parse(localStorage.getItem("user_client"));
    // const accessToken = JSON.parse(localStorage.getItem("user_access-token"));
    this.setState({
      uid,
      client,
      accessToken,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    await Axios({
      url: `${Config.apiURL}/customer/office_requests`,
      method: "POST",
      data: {
        office: {
          name: this.state.name,
          street: this.state.street,
          city: this.state.city,
          country: this.state.country,
          nr: this.state.nr,
          zip_code: this.state.zip_code,
          comment: this.state.comment,
        },
      },
      headers: {
        uid: `${this.state.uid}`,
        client: `${this.state.client}`,
        "access-token": `${this.state.accessToken}`,
      },
    })
      .then((response) => {
        // console.log("add collection response, ", response.data);
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            success: true,
          };
        });
        this.props.history.push({
          pathname: '/thank-you',
          // search: '?query=abc',
          state: { add : true }
        })
      })
      .catch((error) => {
        // console.log("error add collection point, ", error.response.data);
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            error: "Please enter a valid zip code.",
          };
        });
      });
  };

  render() {
    const {t} = this.props;
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" alt=""/>
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" alt=""/>
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" alt=""/>
        </div>
        {/* <Header history={this.props.history} /> */}
        <div className="w-100" />
        <div className="container mb-5 z_index_one scroll_tab_content_new vh-90 d-flex flex-column justify-content-center">
          <ProgressBar step={4} />
          <div className="row mt-5">
            <div className="second col-md-3 col-lg-3 col-xs-12 d-flex align-items-end"></div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="w-75 ms-auto me-auto login_main p-4">
                <div className="d-flex flex-column main-title mb-5 ms-auto me-auto w_min_content">
                <div className="d-flex flex-column main-title mb-5">
                  <h3 className="font_style">{t('Add collection point')}</h3>
                  <div className="heading-line w-25" />
                </div>
                </div>
                {this.state.error && (
                  <p className="color-red">{this.state.error}</p>
                )}
                <div id="login_form">
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("Company name")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/company-name_blue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="name"
                      name="name"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Avenue de le Couron"
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("Street")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/locationBlue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="street"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Avenue de le Couron"
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("Nr")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/nrBlue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="number"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="nr"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder={35}
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("Zip code")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/locationBlue1.png"
                      className="height_16px"
                    />
                    <input
                      type="number"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="zip_code"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder={1000}
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("city")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/cityBlue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputEmail1"
                      name="city"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Knokke"
                    />
                  </div>
                  <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("country")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/cityBlue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputPassword1"
                      name="country"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Belgium"
                    />
                  </div>
                  <div className="position-relative mb-2 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                    <label className="position-absolute px-3 white theme_bg lable_style">
                      {t("Comment on delivery")}
                    </label>
                    <img
                      alt=""
                      src="/assets/imgs/commentBlue.PNG"
                      className="height_16px"
                    />
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 theme_color"
                      id="exampleInputPassword1"
                      name="comment"
                      onKeyUp={(event) => {
                        this.handleChange(event);
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Write your comments here..."
                    />
                  </div>
                  <p className="white small">
                    * New collection point need a validation &amp; installation
                    before use
                  </p>
                  {!this.state.loading && !this.state.success ? (
                    <button
                      onClick={() => {
                        this.handleSubmit();
                      }}
                      id="btn-try-to-login"
                      className={
                        this.state.name === null ||
                        this.state.street === null ||
                        this.state.nr === null ||
                        this.state.city === null ||
                        this.state.zip_code === null ||
                        this.state.country === null ||
                        this.state.comment === null
                          ? "w-75 ms-auto me-auto d-flex justify-content-center btn fill_btn mt-4 disabled"
                          : "w-75 ms-auto me-auto d-flex justify-content-center btn fill_btn mt-4"
                      }
                    >
                      {t("Submit new collection point")}
                    </button>
                  ) : !this.state.success && this.state.loading ? (
                    <button
                      id="btn-try-to-login"
                      className="w-75 ms-auto me-auto d-flex justify-content-center btn fill_btn mt-4 disabled"
                    >
                      {t("Submitting")}
                    </button>
                  ) : (
                    <button
                      id="btn-try-to-login"
                      className="w-75 ms-auto me-auto d-flex justify-content-center btn fill_btn mt-4"
                    >
                      {t("done")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xs-12" />
          </div>
          <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
            <Link
              to="/connect-point"
              className="text-decoration-none small fw-bold"
              href
            >
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
  };
};
export default compose(withTranslation(), connect(mapStateToProps))(AddColectionPoint);
