import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setWallet,
  get_paymentId,
} from "../../../../store/Actions/authActions";
import { useTranslation } from "react-i18next";

const AllDone = (props) => {
  const {t} = useTranslation();
  useEffect(() => {
    // props?.dispatch(setWallet("wallet"));
  }, []);
  return (
    <div>
      <div
        className="modal fade"
        id="all_done"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <h6 className="white">{t("All done!")}</h6>
              <FontAwesomeIcon
                onClick={props.onPress}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4">
              <h5 className="theme_color fw-bold">{t("All done!")}</h5>
              <div className="heading-line_color" />
              <div className="w-75 text-center mx-auto my-5">
                <img
                  src="../assets/imgs/successfully.png"
                  className="img-fluid w-75"
                  alt=""
                />
              </div>
              <button
                onClick={() => props?.dispatch(get_paymentId())}
                data-bs-dismiss="modal"
                aria-hidden="true"
                type="submit"
                className="w-100 mastercard_bg btn btn-custom-onboard rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  paymentId: state?.auth?.paymentId,
});
export default connect(mapStateToProps)(AllDone);
