import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import { store } from "..";


export function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          
          authed === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  export function AuthedRoute({ component: Component, authed, ...rest }) {
    if (store.getState().auth?.user.new_user) {
      return (
        <Route
          {...rest}
          render={(props) =>
            authed === false ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/use-wosh", state: { from: props.location } }}
              />
            )
          }
        />
      );
    }
     else {
      return (
        <Route
          {...rest}
          render={(props) =>
            authed === false ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname:  store.getState().auth?.homeAddress?.length > 0 || store.getState().auth?.officeAddress?.length > 0 ? "/home" :"/use-wosh", state: { from: props.location } }}
              />
              
            )
          }
        />
      );
    }
  }