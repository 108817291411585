import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next"

class UseWash extends React.Component {
  constructor() {
    super();
    this.state = {
      first: true,
      second: true,
      useWash: null,
    };
  }

  componentDidMount() {
    // console.log('tsjjkj',this.props.location.state?.myLove)
    // console.log({prev: this.props.history})
  }

  handleChange = (value) => {
    this.setState({
      useWash: value,
    });
    setTimeout(() => {
      // alert(this.props.history.location.pathname)

      if (value === "home") {
        this.props.history.push("/delivery-details", { myLove: this.props.location.state?.myLove });
      } else if (value === "point") {
        this.props.history.push("/connect-point", { myLove: this.props.location.state?.myLove });
      }
    }, 100);
  };

  // componentWillUnmount() {
  //   localStorage.removeItem("progress")
  // }
  render() {
    const { t } = this.props
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        {/* <Header history={this.props.history} /> */}
        {/* <Header isNav={true} /> */}
        <div className="container mb-5 z_index_one vh-90 d-flex flex-column justify-content-center">
          {
            this.props.history.location.state && this.props.history.location.state?.myLove === "Pakistan" ? <ProgressBar step={3} /> : null
          }
          {/* <ProgressBar step={3} /> */}
          <div className="d-flex flex-column main-title mb-5 ms-auto me-auto w_min_content mt-5">
            <h3 className="text-center title">{t('Where will you use Wosh?')}</h3>
            <div className="heading-line w-25" />
          </div>
          <div className="row">
            <div className="second col-md-1 col-lg-1 col-xs-12 d-flex align-items-end">
              {/* <div className="mt-auto footer_btn d-flex flex-row align-items-center pt-3">
                <Link
                  to="/personal-details"
                  className="text-decoration-none small"
                  href
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Link>
              </div> */}
            </div>
            <div className="first col-md-5 col-lg-5 col-xs-12 d-flex justify-content-center align-items-center">
              <div
                className={
                  this.state.useWash === "home"
                    ? "w-100 h-100 m-auto position-relative use_wash_boxes p-2 text-center d-flex flex-column align-items-center text-decoration-none checked_box"
                    : "w-100 h-100 m-auto position-relative use_wash_boxes p-2 text-center d-flex flex-column align-items-center text-decoration-none"
                }
                onClick={() => {
                  this.handleChange("home");
                }}
              >
                <div className="bg-light position-absolute use_wosh_check d-flex justify-content-center align-items-center d-none">
                  <img
                    src="/assets/imgs/check-mark.png" width={48}
                  />
                </div>
                <img
                  src="/assets/imgs/new_doorstep.png"
                  width={50}
                  className="ms-auto me-auto"
                  alt=""
                />
                <h3 className="text-center mt-3 pick_delivery_heading fw-bold">
                  {t('At your doorstep')}.{" "}
                </h3>
                <div className="text-start theme_color">
                  <p className="text-center" style={{ visibility: 'hidden' }}>{" - "}</p>
                  <p> {t('Pickup delivery at your doorstep')} </p>
                </div>
                {/*<ul className="text-start white">*/}
                {/*  <li>{t('minimum order of 30')}€ </li>*/}
                {/*  <li>{t('2 hours delivery window')}</li>*/}
                {/*  <li>{t('2-3 days turnover')}</li>*/}
                {/*  <li>{t('100% guarantee')}</li>*/}
                {/*  <li>{t('Delivery fee')}</li>*/}
                {/*</ul>*/}
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 d-flex justify-content-center align-items-center">
              <div
                className={
                  this.state.useWash === "point"
                    ? "w-100 h-100 m-auto position-relative use_wash_boxes p-2 text-center d-flex flex-column align-items-center text-decoration-none checked_box"
                    : "w-100 h-100 m-auto position-relative use_wash_boxes p-2 text-center d-flex flex-column align-items-center text-decoration-none"
                }
                onClick={() => {
                  this.handleChange("point");
                }}
              >
                <div className="bg-light position-absolute use_wosh_check d-flex justify-content-center align-items-center d-none">
                  <img
                    src="/assets/imgs/check-mark.png" width={48}
                  />
                </div>
                <img
                  src="/assets/imgs/new_office.png"
                  width={50}
                  className="ms-auto me-auto"
                  alt=""
                />
                <h3 className="text-center mt-3 pick_delivery_heading fw-bold">
                  {t('Collection point')}.{" "}
                </h3>
                <div className="text-center theme_color">
                  <p> {t('Pickup delivery at your office')}</p>
                  <p> {t('Pickup delivery at your office (places)')} </p>
                </div>
                {/*<ul className="text-start white">*/}
                {/*  <li>{t('no minimum order')}</li>*/}
                {/*  <li>{t('contacless & convenient')} </li>*/}
                {/*  <li>{t('2 shifts per week')} </li>*/}
                {/*  <li>{t('100% guarantee')}</li>*/}
                {/*  <li>{t('no delivery fee')}</li>*/}
                {/*</ul>*/}
              </div>
            </div>
            <div className="col-md-1 col-lg-1 col-sm-12 col-xs-12" />
          </div>
          <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
            {
              this.props.history.location.state && this.props.history.location.state?.myLove === "Pakistan" ?
                <Link
                  to={{ pathname: "/personal-details", state: { prevLocation: this.props.history.location.pathname } }}
                  className="text-decoration-none small"
                  href
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> {t('Back')}
                </Link> :
                <Link
                  to="/"
                  className="text-decoration-none small"
                  href
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> {t('Back')}
                </Link>
            }
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(UseWash);
