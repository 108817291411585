import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Config from "../../../Config";
import axios from "axios";
import ReportIssue from "../ReportIssue";


const PremiumLockerInfo = (props) => {
  const { t } = useTranslation();



  const collectionPoints = () => {
    return props.currentSliderSelected?.lockers?.map((item, index) => {
      return (
        <>
          <div className="search_option mr-3 p-3 h-auto mb-3" style={{ height: 95, marginTop: 10, cursor: "pointer" }} key={index}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
              <h3 className="text-left promotion_heading theme_color m-0">Locker NR</h3>
              <h3 className="text-left promotion_heading  m-0 " style={{ color: '#34A5AB' }}>{item.number || 0}</h3>
            </div>
            {/* <br /> */}
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
              <h3 className="text-left promotion_heading theme_color m-0">Access Code</h3>
              <h3 className="text-left promotion_heading  m-0" style={{ color: '#34A5AB' }}>{item.code || 0}</h3>
            </div>
          </div>
        </>
      );
    })

  }

  return (
    <>
      <div
        className="modal fade"
        id="stripeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex-column modal-dialog-centered modal-lg vh-90 ">
          <div className="modal-content bg-transparent mb-5 border-0 w_100 screen_center" >
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2" >
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <div className="w-100" id="stripe_form" style={{ borderColor: '#3D4586', borderWidth: 10 }}>
                <form className="Form text-center" style={{ border: '8px solid #3D4586' }}>
                  <div className="w-100 d-flex flex-column mb-3">
                    <h5 className="theme_color fw-bold align-self-center">
                      Retrieve your order
                    </h5>
                  </div>
                  <img src={'/assets/Group155.png'} alt="" className="align-self-center mb-4" style={{ width: 100, height: 130 }} />


                  <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {props.currentSliderSelected?.lockers?.length > 0 && collectionPoints()}
                  </div>

                  <div className="d-flex flex-row justify-content-between mt-4">
                  {/* <button data-bs-toggle="modal" onclick="modalblur()" */}
                {/* // data-bs-target="#report_issue" class="btn border_btn lh-sm fw-bold border-4 ms-auto me-auto d-flex justify-content-center align-items-center py-2 px-5"></button> */}
                  <div data-bs-toggle="modal" data-bs-target="#report_issue" className="btn border_btn lh-sm fw-bold border-4 ms-auto me-auto d-flex justify-content-center align-items-center py-2 px-5">
                  Report an issue
                  </div>
                  <button type="submit" class="btn fill_btn lh-sm ms-auto me-auto d-flex justify-content-center align-items-center py-2 px-5" 
                    // data-bs-dismiss="modal"
                    // aria-hidden="true" 
                    // onClick={()=>orderRetrieved(props.currentSliderSelected.id)}
                    onClick={()=>props.onPressOrderRetrieved(props.currentSliderSelected.id)}
                    >Order retrieved</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportIssue />
    </>
  );
};
const mapStateToProps = (state) => ({
  paymentId: state?.auth?.paymentId,
  authUser: state?.auth?.user,
  cartItems: state?.cart?.cartItems,
});
export default connect(mapStateToProps)(PremiumLockerInfo);
