import React, { useState, useEffect } from "react";
import ChooseMethod from "../Modals/ChooseMethod";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Header/user.css";
import MasterCard from "../Modals/MasterCard/index";
import Config from "./../../Config";
import Axios from "axios";
import { connect } from "react-redux";
import {
  get_paymentId,
  setCurrentLocale,
} from "../../../store/Actions/authActions";
import Wallet from "../Profile/TabContent/Wallet";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Header from "../Header";
import DebitCard from "../Profile/DebitCard";
import TransactionHistory from "./TransactionHistory";
// import ChangePassword from "../../Modals/EditProfile/ChangePass";

const PaymentMethod = (props) => {
  const [wallet, setWallet] = useState(null);
  const [seeMore, setSeeMore] = useState(5)
  const [walletEntries, setWalletEntries] = useState(null);
  const lng = cookies.get("i18next") ? cookies.get("i18next") : "en";

  const { t } = useTranslation();

  const getWallet = async () => {
    const uid = props?.authUser?.user_uid;
    const client = props?.authUser?.user_client;
    const accessToken = props?.authUser?.user_access_token;
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/wallet`,
        method: "get",
        headers: {
          uid: `${uid}`,
          client: `${client}`,
          "access-token": `${accessToken}`,
        },
      });
      const amount = await response?.data?.wallet?.amount;
      setWallet(amount);
      setWalletEntries(response?.data?.wallet?.wallet_entries)
    } catch (err) { }
  }

  useEffect(() => {
    getWallet();
    props.dispatch(get_paymentId());
  }, [props?.wall, props?.renderCheck]);

  return (
    <div /*className={this.state.blur ? "modal_main" : null}*/>
      <div className="top_right">
        <img src="/assets/imgs/bubble_3.png" />
      </div>
      <div className="rectangle_top_left">
        <img src="/assets/imgs/Rectangle_top.png" />
      </div>
      <div className="bottom_right">
        <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
      </div>
      <div className="middle_left_white">
        <img src="/assets/imgs/bubble_white.png" />
      </div>
      <div className="circle_left_middle_dot">
        <img src="/assets/imgs/circle_top.png" />
      </div>
      {/* <Header isNav={true} /> */}
      <div className="scroll_tab_content_new_2">
        <div
          className="w_50 mx-auto tab-pane py-4"
          id="payment_method"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <h4 className="text-center my-4 theme_color fw-bold">{t("Payment Methods")}</h4>
          <div className="border_payment rounded mt-5 w-75 mx-auto">
            <div className="d-flex align-items-center">
              <img src="../assets/imgs/new_wallet.png" width={28} height={28} />
              <h5 className="ms-3 m-0 theme_color fw-bold">{t("WOSH wallet")}</h5>
            </div>
            <p className="theme_color my-4">{t("You can add credit to your wosh wallet using bancontact, apple pay or by asking nicely to your mam or manager.")}</p>
            <Wallet wallet={wallet} />
            <div className="d-flex align-items-center mt-5">
              <img src="../assets/imgs/new_credit_card.png" width={30} height={30} />
              <h5 className="ms-3 m-0 theme_color fw-bold">{t("Credit card")}</h5>
            </div>
            <p className="theme_color my-4">{t("Your credit card information are secured. Thanks to stripe.")}</p>
            <DebitCard />
            <div className="w-100 d-flex flex-column mb-4 align-items-center justify-content-between">
              {props?.cards && props?.cards.length > 0 ? (
                <>
                  <div
                    onclick="modalblur()"
                    data-bs-toggle="modal"
                    data-bs-target="#stripeModal"
                    className="w_55 ms-auto rounded btn woshwallet_new white mt-2 shadow-none py-2 border-0"
                  >
                    <img src="../../assets/imgs/new_add.png" alt="" width={20} height={20} className='me-3' />{t("Add card")}
                  </div>
                </>
              ) : (
                <>
                <div className="w-100 d-flex flex-row mb-4">
                    <div
                      className="w-100 d-flex flex-sm-row flex-column mb-4 woshwallet_border"
                    >
                      <h6 className="w_75 btn payment_btn theme_color d-flex justify-content-center flex-row align-items-center px-sm-5 px-md-3 px-5 shadow-none outline-0 me-2 mb-sm-0 mb-2 fw-bold regular_font mb-0">
                        {t("No cards added")}
                      </h6>
                      <button
                        onclick="modalblur()"
                        data-bs-toggle="modal"
                        data-bs-target="#stripeModal"
                        className="w_50 m-auto btn woshwallet_new_2 white shadow-none py-2"
                      >
                        <img src="../../assets/imgs/new_add.png" alt="" width={20} height={20} className='me-3 m-0' />{t("Add card")}
                      </button>
                    </div>
                  </div>
                  {/* <div className="w-100 d-flex flex-sm-row flex-column mb-4">
                    <div
                      className="w_75_new woshwallet_border_new btn theme_color d-flex justify-content-center flex-row align-items-center px-lg-5 px-md-3 px-5 shadow-none outline-0 mb-sm-0 mb-2"
                    >
                      <h6 className="text_bold mb-0 mt-1">
                        {t("No cards added")}
                      </h6>
                    </div>
                    <button
                      onclick="modalblur()"
                      data-bs-toggle="modal"
                      data-bs-target="#stripeModal"
                      className="w_35_new ms-auto btn woshwallet_new_2 white shadow-none py-2 border-0"
                    >
                      <img src="../../assets/imgs/new_add.png" alt="" width={20} height={20} className='me-3' />{t("Add card")}
                    </button>
                  </div> */}
                </>
              )
              }
            </div>
            {
              walletEntries?.length ? (
                <>
                  <div className="d-flex align-items-center mt-5 mb-4">
                    <img src="../assets/imgs/history_trans.png" width={25} height={25} />
                    <h5 className="ms-3 m-0 theme_color fw-bold">{t("Transaction History")}</h5>
                  </div>
                  <TransactionHistory seeMore={seeMore} walletEntries={walletEntries} />
                  {
                    seeMore < walletEntries?.length && (
                      <button
                        onClick={() => setSeeMore(seeMore + 5)}
                        className="w-100 border_payment_color rounded btn theme_color mt-2 shadow-none py-2"
                      >{t("See More")}</button>
                    )
                  }
                </>
              ) : null
            }
          </div>
          <MasterCard />
          <ChooseMethod amount={wallet} />
        </div>
      </div>

    </div>
  );
};
const mapStateToProps = (state) => ({
  cards: state?.auth?.paymentId,
  wall: state?.auth?.wallet,
  render: state?.auth?.render,
  authUser: state?.auth?.user,
});
export default connect(mapStateToProps)(PaymentMethod);
