import React from "react";
import $ from "jquery";
import Header from "../../Header/index";
import { validate } from "indicative/validator";
import Axios from "axios";
import Config from "../../../Config";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";
const lang = [{ id: 1, title: "English", icon: '../assets/imgs/UK-flag.png' }, { id: 2, title: "Français", icon: '../assets/imgs/FR-flag.png' }, { id: 3, title: "Nederlands", icon: '../assets/imgs/NL-flag.png' }];

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: [],
      success: false,
      loading: false,
      errors: {
        emal: null,
      },
      haveText:
        cookies.get("i18next") === "fn"
          ? "Français"
          : cookies.get("i18next") === "du"
            ? "Nederlands"
            : cookies.get("i18next") === "en"
              ? "English"
              : "",
      selectedCountryIcon: cookies.get("i18next") === "fn"
        ? "../assets/imgs/FR-flag.png"
        : cookies.get("i18next") === "du"
          ? "../assets/imgs/NL-flag.png"
          : cookies.get("i18next") === "en"
            ? '../assets/imgs/UK-flag.png'
            : "../assets/imgs/UK-flag.png"
    };
  }

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  itemList = (props) => {
    const list = props.map((item) => (
      <div
        onClick={() => this.handleText(item)}
        className="dropdown__item d-flex justify-content-between px-4"
        key={item.toString()}
      >
        {item.title}
        <img src={item.icon} style={{ width: '30px', height: '20px' }} />
      </div>
    ));

    return <div className="dropdown__items"> {list} </div>;
  };
  handleText = (ev) => {
    console.log('ev', ev)
    this.setState({
      haveText: ev.title,
      selectedCountryIcon: ev.icon
    });
    cookies.set(
      "i18next",
      ev.title === "English"
        ? "en"
        : ev.title === "Français"
          ? "fn"
          : "du"
    );
    window.location.reload();
  };
  componentDidMount() {
    $("#app-container").addClass("body");
  }

  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = null;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = () => {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    // console.log("clicked");
    var postData = {
      email: this.state.email,
      redirect_url: process.env.REACT_APP_RESET_PASSWORD_REDIRECT,
    };
    const rules = {
      email: "required|email",
    };
    validate(postData, rules)
      .then(
        Axios.post(`${Config.authURL}/auth/password`, postData, Config.headers)
          .then((response) => {
            this.setState((prev) => {
              return {
                ...prev,
                loading: false,
                success: true,
              };
            });
            this.props.history.push(`/mail-check/${this.state.email}`);
          })
          .catch((err) => {
            this.setState({
              error: err?.response?.data?.errors
                ? err?.response?.data?.errors[0]
                : "Server is not responding please try later",
              loading: false,
            });
            // alert(this.error);
          })
      )
      .catch((errors) => {
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        this.setState({
          errors: formattedErrors,
        });
      });
  };

  render() {
    const { t } = this.props;
    const { isOpen, haveText } = this.state;
    return (
      <div id="darkautofill">
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        {/* <Header history={this.props.history} /> */}
        <div className="w-100" />
        <div
          className={
            isOpen
              ? "dropdown position-absolute cursor_pointer active"
              : "dropdown position-absolute cursor_pointer"
          }
          onClick={this.handleClick}
        >
          <div className="cursor_pointer d-flex justify-content-around align-items-center py-2">
            <h5 className="mb-0 theme_color">{!haveText ? "English" : haveText}</h5>
            <img src={this.state.selectedCountryIcon} style={{ width: '30px', height: '20px' }} />
          </div>
          {this.itemList(lang)}
        </div>
        {/* <div className=" rounded_25px p-4 w-50 mx-auto mt-5"> */}
        {/* <div className="w-75 mx-auto"> */}
        <div className="container mb-5 z_index_one">
          <div className="w_35per m-auto login_main centered">
            <div className="reset_pass">
              <h3 className="theme_color font_style mt-5">{t("Reset Password")}</h3>
              <div className="heading-line_color mb-3" />
            </div>
            <div className="mt-5 pt-5 ">
              {this.state.error ? (
                <p className="color-red">{this.state.error}</p>
              ) : null}
              <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 mt-5 align-items-center" id="loginAutofill">
                <label className="position-absolute px-3 white theme_bg lable_style">
                  {t("Email")}
                </label>
                {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                <img src="/assets/imgs/mail.png" width={18} alt="" />
                <input
                  type="email"
                  className="form-control bg-transparent border-0 theme_color placeholderDark input_shadow"
                  id="exampleInputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder={t("Email address")}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              {!this.state.loading && !this.state.success ? (
                <button
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  className={
                    this.state.email === ""
                      ? "w-100 mastercard_bg btn btn-custom-onboard rounded mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled"
                      : "w-100 mastercard_bg btn btn-custom-onboard rounded mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                  }
                >
                  {t("Send email")}
                </button>
              ) : this.state.loading && !this.state.success ? (
                <button className="w-100 mastercard_bg btn btn-custom-onboard rounded mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disabled">
                  {t("Sending")}
                </button>
              ) : (
                <button className="w-100 mastercard_bg btn btn-custom-onboard rounded mt-4 mb-2 ms-auto me-auto d-flex justify-content-center">
                  {t("Done")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Email);
