import React from "react";
import { connect } from "react-redux";
import ItemList from "../ItemList";
import OnHangerCat from "./CategoriesOnHanger";

export const OnHangersTab = (props) => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="hangers"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        {/* <OnHangerCat /> */}
        <ItemList data={props.data} cat={"hanger"} sub={"top"} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OnHangersTab);
